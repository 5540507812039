import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';

export const DEFAULT_PAGE_SIZE = 7;
export const BLOCKING_DURATION = 300; // sec
export async function getInvoices({
  setState,
  mmkId = 'parent',
  clinicId = 0,
  paymentStatus = 'Unpaid',
  pageIndex = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  dateFrom = null,
  maxDate = null,
  local = false,
}) {
  const body = {
    mmkId,
    clinicId,
    paymentStatus,
    dateFrom,
    maxDate,
    pageIndex,
    pageSize,
    local: !local ? undefined : local, // if api unexpected local
  };
  await httpClient
    .get(api.url('GetInvoices'), body)
    .then(async ({ data }) => {
      // console.log(
      //   '--------- request GetInvoices--------- \n',
      //   body,
      //   '--------- response GetInvoices--------- \n',
      //   data,
      // );

      setState({
        type: 'FETCH_INVOICES',
        payload: {
          data: ifEmptyArr(data),
        },
      });
    })
    .catch((err) => {
      // console.log('= axios ERROR GetInvoices =', err);
      setState({
        type: 'FETCH_INVOICES_ERROR',
        payload: getError(err),
      });
    });
}

export async function getInvoiceDetails({
  setState,
  mmkId,
  clinicId,
  invoiceId,
}) {
  setState({
    type: 'FETCH_INVOICE_DETAILS_LOADIG',
    payload: {
      invoiceId,
    },
  });

  const bodyDetails = {
    mmkId,
    clinicId,
    invoiceId,
  };
  try {
    const { data } = await httpClient.get(
      api.url('GetInvoiceById'),
      bodyDetails,
    );

    setState({
      type: 'FETCH_INVOICE_DETAILS',
      payload: {
        invoiceId,
        clinicId,
        data,
      },
    });
  } catch (error) {
    //console.log('= axios =\n', getError(error));
    setState({
      type: 'FETCH_INVOICE_DETAILS_ERROR',
      payload: {
        invoiceId,
        clinicId,
        responseDetailError: getError(error),
      },
    });
  }
}

export async function getInvoiceUnBlock({
  setState,
  mmkId,
  clinicId = 0,
  invoiceId,
  blockKey,
}) {
  const body = {
    clinicId,
    mmkId,
    fmInvoiceId: invoiceId,
    blockKey,
  };
  try {
    const { data } = await httpClient.post(
      api.url('GetInvoiceUnBlock'),
      body,
    );
    if (!data) {
      throw new Error(`Error UnBlock invoice ${invoiceId}`);
    }
  } catch (error) {
    //console.log('= axios =\n', getError(error));
    setState({
      type: 'FETCH_INVOICE_DETAILS_ERROR',
      payload: {
        invoiceId,
        clinicId,
        responseDetailError: getError(error),
      },
    });
  }
}
export async function getInvoicePayInfo({
  setState,
  mmkId,
  clinicId,
  invoiceId,
  isBlock = true,
}) {
  try {
    setState({
      type: 'FETCH_INVOICE_DETAILS_LOADIG',
      payload: {
        invoiceId,
      },
    });
    let block;
    if (isBlock) {
      block = await httpClient.post(api.url('GetInvoiceBlock'), {
        clinicId,
        mmkId,
        fmInvoiceId: invoiceId,
        blockingDuration: BLOCKING_DURATION,
      });

      if (!block.data) {
        throw new Error(`Error Block invoice ${invoiceId}`);
      }
    }

    if (!isBlock || (isBlock && block && block.data)) {
      const bodyPayInfo = isBlock
        ? {
            clinicId,
            invoiceId,
            mmkId,
            blockKey: block.data.blockKey,
          }
        : {
            clinicId,
            invoiceId,
            mmkId,
          };
      const { data } = await httpClient.get(
        api.url('GetInvoicePayInfo'),
        bodyPayInfo,
      );

      //console.log('= axios getInvoicePayInfo ==', data);

      if (!isEmpty(data)) {
        setState({
          type: 'FETCH_PAID_DATA',
          payload: {
            invoiceId,
            clinicId,
            paidData: {
              ...data,
              blockKey: block.data.blockKey,
              modalPaid: true,
            },
          },
        });
      }
    }
  } catch (error) {
    //console.log('= axios =\n', getError(error));
    setState({
      type: 'FETCH_INVOICE_DETAILS_ERROR',
      payload: {
        invoiceId,
        clinicId,
        responseDetailError: getError(error),
      },
    });
  }
}
export async function getInvoiceById({
  setState,
  type = 'FETCH_INVOICE',
  invoiceId,
  mmkId = 'parent',
  clinicId = 0,
  blockKey,
}) {
  console.log('---------  GetInvoiceById--------- \n');

  const body = blockKey
    ? {
        mmkId,
        clinicId,
        invoiceId,
        blockKey,
      }
    : {
        mmkId,
        clinicId,
        invoiceId,
      };
  await httpClient
    .get(api.url('GetInvoiceById'), body)
    .then(({ data }) => {
      console.log(
        '--------- request GetInvoiceById--------- \n',
        body,
        '--------- response GetInvoiceById--------- \n',
        data,
      );

      if (!isEmpty(data))
        setState({
          type,
          payload: {
            invoiceId,
            data,
          },
        });
      else
        setState({
          type,
          payload: {
            invoiceId,
            data: [],
          },
        });
    })
    .catch((err) => {
      //console.log('= axios ERROR GetInvoiceById =', err);
      setState({
        type: 'FETCH_INVOICES_ERROR',
        payload: getError(err),
      });
    });
}
