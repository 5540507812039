import i18next from 'i18next';
import { isValidPhone, rexFio } from '../../helpers/validators';

export default function validate(values, appInfo) {
  let errors = {};

  if (
    values.phone != null &&
    !isValidPhone(values.phone, appInfo.countryCode)
  ) {
    errors.phone = i18next.t('SIGN.UP_NOT_VALID_PHONE');
  }

  if (!values.firstName) {
    errors.firstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (!values.lastName) {
    errors.lastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }

  if (values.firstName && !rexFio.test(values.firstName.trim())) {
    errors.firstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values.lastName && !rexFio.test(values.lastName.trim())) {
    errors.lastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }
  if (values.middleName && !rexFio.test(values.middleName)) {
    errors.middleName = i18next.t('SIGN.UP_NOT_VALID_MIDDLE_NAME');
  }

  if (
    values.firstName != null &&
    values.lastName != null &&
    values.firstName.trim() === values.lastName.trim()
  ) {
    errors.firstName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
    errors.lastName = i18next.t('SIGN.UP_CANNOT_BE_SAME');
  }

  return errors;
}
