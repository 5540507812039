import React from 'react';
import Grid from '@mui/material/Grid2';

import Profile from './Profile';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import LeftMenu from '../../components/Sidebar/LeftMenu';
import { useUserStateDispatch } from '../../context/UserContext';

export default function ProfilePage() {
  const { t } = useTranslation();
  const {
    userState: { structure },
  } = useUserStateDispatch();
  const HeaderH1 = () => (
    <Typography variant="h1" style={{ marginBottom: 32 }}>
      {t('MAINMENU.PROFILE')}
    </Typography>
  );
  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, sm: 12, md: 4, lg: 3 }}>
        <LeftMenu isProfile={true} />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 8, lg: 9 }}>
        <HeaderH1 />
        <Profile />
      </Grid>
    </Grid>
  );
}
