import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormControlLabel,
  Typography,
  Switch,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import SpecOrDocName from '../../components/FormComponents/AutocompleteSpecOrDocName';
import { useTranslation } from 'react-i18next';
import { useVisitsState } from '../../context/VisitsContext';

const useStyles = makeStyles((theme) => ({
  filters: {
    padding: `${theme.spacing(0.3)} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(4),
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.bgLight.two}`,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 25px rgba(78, 72, 190, 0.08)',
    borderRadius: theme.spacing(1),
  },
}));

export default function VisitsFilter() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    state: {
      visits: { isLoaded },
      specOrName,
      futureVisits,
      includeCancelled,
      type,
    },
    setState,
  } = useVisitsState();
  // const { t } = useTranslation();
  //  const classes = useStyles();

  const handleSpecOrNameChange = (value) => {
    console.log(' value', value);
    setState({
      type: 'SET_FILTER',
      payload: {
        specOrName: value,
      },
    });
  };

  const handleFutureVisits = () => {
    setState({
      type: 'SET_FILTER',
      payload: {
        futureVisits: !futureVisits,
      },
    });
  };
  const handleIncludeCancelled = () => {
    setState({
      type: 'SET_FILTER',
      payload: {
        includeCancelled: !includeCancelled,
      },
    });
  };
  const handleType = (event) => {
    setState({
      type: 'SET_FILTER',
      payload: {
        type: event.target.value,
      },
    });
  };

  return (
    <Box className={classes.filters}>
      <SpecOrDocName
        handleSpecOrNameChange={handleSpecOrNameChange}
        specOrName={specOrName}
        disabled={!isLoaded}
      />

      <FormControl
        disabled={!isLoaded}
        variant="outlined"
        margin="normal"
        fullWidth
      >
        <InputLabel id="demo-simple-select-outlined-label">
          {t('EVENTS_VISITS.FILTER_ROWS')}
        </InputLabel>
        <Select
          value={type}
          onChange={handleType}
          label={t('EVENTS_VISITS.FILTER_ROWS')}
          disabled={!isLoaded}
        >
          <MenuItem value="All">
            {t('EVENTS_VISITS.FILTER_ROWS_ALL')}
          </MenuItem>
          <MenuItem value="Doctor">
            {t('EVENTS_VISITS.FILTER_ROWS_DOC')}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={futureVisits}
            onChange={handleFutureVisits}
            value="checkedA"
            color="primary"
          />
        }
        label={
          <Typography variant="h6">
            {t('EVENTS_VISITS.FILTER_FUTURE')}
          </Typography>
        }
        disabled={!isLoaded}
      />

      <FormControlLabel
        control={
          <Switch
            checked={includeCancelled}
            onChange={handleIncludeCancelled}
            value="checkedB"
            color="primary"
          />
        }
        label={
          <Typography variant="h6">
            {t('EVENTS_VISITS.FILTER_SHOW_CANCELED')}
          </Typography>
        }
        disabled={!isLoaded}
      />
    </Box>
  );
}
