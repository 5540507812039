import React from 'react';
import Sessions from './Sessions';
import { SessionsProvider } from '../../context/SessionsContext';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

export default function SessionsInit() {
  const { t } = useTranslation();

  return (
    <SessionsProvider>
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        {t('MAINMENU.USER_SESSIONS')}
      </Typography>

      <Sessions />
    </SessionsProvider>
  );
}
