import React, { useEffect } from 'react';

import useModalChatVisit from '../../visits/useModalChatVisit';
import VisitModalChatConf from '../../visits/VisitModalChatConf';
import Visits from '../../visits/Visits';
import { useUserStateDispatch } from '../../../context/UserContext';
import { useVisitsState } from '../../../context/VisitsContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function VisitsContainer({
  setExistVisits,
  isExistPromo,
}) {
  const {
    userState: { user, isAuthenticated },
  } = useUserStateDispatch();
  const {
    state: { visit },
  } = useVisitsState();

  const { modalChatVisit, toggleModalChatVisit } =
    useModalChatVisit();

  useEffect(() => {
    if (setExistVisits != null) setExistVisits(isAuthenticated);
  }, [isAuthenticated]);

  return (
    isAuthenticated && (
      <>
        <Visits
          setExistVisits={setExistVisits}
          futureVisits={true}
          isExistPromo={isExistPromo}
          toggleModalChatVisit={toggleModalChatVisit}
        />
        <VisitModalChatConf
          chatInfo={visit?.chatInfo}
          lang={user?.lang}
          modalChatVisit={modalChatVisit}
          toggleModalChatVisit={toggleModalChatVisit}
        />
      </>
    )
  );
}
