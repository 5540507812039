import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      position: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      width: 224,
      position: 'fixed',
    },

    [theme.breakpoints.up('lg')]: {
      width: 282,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-flexContainerVertical > .MuiTab-root': {
      textTransform: 'none',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    '& .MuiTabs-flexContainerVertical > .MuiTab-root > .MuiTab-wrapper':
      {
        alignItems: 'flex-start',
      },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.hero,
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
}));

export default function PrescribedDrugsFilter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const { filterPrescribedDrug } = useParams();

  const handlePaymentStatus = (event, filterPrescribedDrug) => {
    navigate(`/app/drugs/${filterPrescribedDrug}`);
  };
  //console.log('filterPrescribedDrug', filterPrescribedDrug);
  return (
    <Paper className={classes.conteiner}>
      <Tabs
        orientation="vertical"
        value={filterPrescribedDrug ?? ''}
        onChange={handlePaymentStatus}
        aria-label="filterPrescribedDrug-tabs"
        className={classes.tabs}
        textColor="primary"
      >
        <Tab value="Current" label={t('DRUGS.FILTER_CURRENT')} />
        <Tab value="Past" label={t('DRUGS.FILTER_PAST')} />
        <Tab value="Future" label={t('DRUGS.FILTER_FUTURE')} />
        <Tab value="" label={t('DRUGS.FILTER_ALL')} />
      </Tabs>
    </Paper>
  );
}
