import React from 'react';
import { parsePhoneNumber } from '../../helpers/validators';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { YMaps } from '@pbe/react-yandex-maps';
import YMap from './YMap';

//const API_KEY = '05f8d2ae-bd94-4329-b9f9-7351e2ec9627';

const YandexMap = () => {
  const {
    userState: { clinics, appInfo, isAuthenticated },
  } = useUserStateDispatch();

  const data = !isEmpty(clinics)
    ? clinics.map((item) => {
        if (item?.phone == null) return item;

        const phoneNumber = parsePhoneNumber()(item.phone, {
          defaultCountry: appInfo.countryCode,
          extract: false,
        });
        item.phone = phoneNumber?.formatNational();
        return item;
      })
    : [];
  const d = (clinics || []).find(
    (item) => item?.coordinates != null && item?.coordinates !== '',
  );
  const defaultMapZoom = appInfo.mapsZoomDefault ?? 10;

  const center =
    d != null
      ? d.coordinates.split(', ')
      : ['55.751244', '37.618423']; // moscow coordinates;

  return (
    <YMaps
      query={{
        load: 'package.full',
        apikey: appInfo.mapsKey,
      }}
    >
      <YMap
        options={{
          center,
          zoom: defaultMapZoom,
        }}
        data={data}
        isAuthenticated={isAuthenticated}
      />
    </YMaps>
  );
};

export default YandexMap;
