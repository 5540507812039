import React from 'react';
import Faq from './Faq';
import { FaqProvider } from '../../context/FaqContext';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const FaqInit: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FaqProvider>
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        {t('MAINMENU.FAQ')}
      </Typography>

      <Faq />
    </FaqProvider>
  );
};

export default FaqInit;
