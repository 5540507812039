import React from 'react';

import { TextField as Input } from '@mui/material';

// styles
import useStyles from './styles';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { sendLoginAsDoctorConfirmationCode } from '../../actions/user';

//components
import Button from '@mui/material/Button';
//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignInCode';

//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import Loading from '../../components/Loading';
//import { useLanguageValue } from '../../context/LanguageContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignInConfirm() {
  const classes = useStyles();
  const { t } = useTranslation();
  // const { languageState } = useLanguageValue();
  // global

  const {
    userState: {
      isLoaded,
      doctor,
      appInfo,
      user: { lang },
    },

    userDispatch,
  } = useUserStateDispatch();

  const confirm = () =>
    sendLoginAsDoctorConfirmationCode(
      userDispatch,
      values.code,
      doctor?.clinicId,
      doctor?.medDepId,
      lang,
    );
  const { values, errors, handleChange, handleSubmit } = useForm(
    confirm,
    validate,
    appInfo,
  );

  return !isLoaded ? (
    <Loading />
  ) : (
    <>
      <Input
        name="code"
        variant="outlined"
        value={values.code}
        onChange={handleChange}
        margin="normal"
        label={t('COMPONENT.CONFIRM_CODE')}
        type="code"
        fullWidth
        required
        error={errors?.code != null}
        helperText={errors?.code != null && errors?.code}
      />
      <div className={classes.formButtons}>
        <Button
          className={classes.summitButton}
          variant="contained"
          color="primary"
          disabled={isEmpty(values.code)}
          onClick={handleSubmit}
        >
          {t('SIGN.IN_BUTTON')}
        </Button>
        <Button
          variant="text"
          color="primary"
          className={classes.forgetButton}
          onClick={() =>
            userDispatch({
              type: 'SET_DOC_DATA',
              payload: { confirmationCodeInfo: null },
            })
          }
        >
          {t('COMPONENT.BACK_ONE_STEP')}
        </Button>
      </div>
    </>
  );
}

export default SignInConfirm;
