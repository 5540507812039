import i18next from 'i18next';
import isEmpty from '../../helpers';

export default function validate(values) {
  let errors = {};

  if (isEmpty(values.confirmationCode)) {
    errors.code = i18next.t('SIGN.IN_EMPTY_CONFIRM_CODE');
  }

  return errors;
}
