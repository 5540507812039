import React, { useState } from 'react';

import SpecCards from './SpecCards';
import SpecFilter from './SpecFilter';
import { VisitProvider } from '../../../context/VisitContext';

export default function Specs() {
  const [strFilter, setStrFilter] = useState('');
  const handleSearch = (e) => {
    setStrFilter(e.currentTarget.value);
  };

  return (
    <VisitProvider>
      <SpecFilter handleSearch={handleSearch} />
      <SpecCards strFilter={strFilter} />
    </VisitProvider>
  );
}
