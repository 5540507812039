import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { otpLogin, singUpConfirmationCode } from '../../actions/user';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignIn';

import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';

import { useTranslation } from 'react-i18next';
import ShowResponse from '../FormComponents/ShowResponse';
import Loading from '../Loading';
import SignInOtpForm from './SignInOtpForm';
import SignFormConfirm from './SignFormConfirm';
import { cleanPhoneValue } from '../../helpers/utils';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ valsTab, setValsTab, hasButtonGoSignUp = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // global
  const {
    userDispatch,
    userState: { appInfo, isLoaded, serverResponse },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();

  const login = () => {
    if (serverResponse?.action === 'CONFIRM_SINGUP') {
      singUpConfirmationCode(
        userDispatch,
        {
          login: values.login,
          confirmationCode: values.code || values.confirmationCode,
        },
        language,
        navigate,
        appInfo.isAnonymousChildrenEnabled,
      );
      return;
    }
    otpLogin({
      dispatch: userDispatch,
      navigate,
      lang: language,
      login: cleanPhoneValue(values.login),
      code: values.code,
      isAnonymousChildrenEnabled: appInfo.isAnonymousChildrenEnabled,
    });
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    handlePhoneChange,
  } = useForm(login, validate, appInfo);

  const setActiveTabId = (activeTabId) => {
    userDispatch({
      type: 'LOADED',
    });
    setValsTab({
      ...valsTab,
      activeTabId,
    });
  };
  useEffect(() => {
    return () => {
      userDispatch({
        type: 'LOADED',
      });
    };
  }, []);
  useEffect(() => {
    if (
      serverResponse?.action === 'WRONG_LOGIN_OR_CONFIRMATION_CODE'
    ) {
      setTimeout(() => {
        setActiveTabId(1);
      }, 2500);
    }
  }, [serverResponse?.action]);

  const reSendCode = () => {
    if (serverResponse?.action === 'CONFIRM_CODE_SENT') {
      login();
    }
  };

  const lifeTime = serverResponse?.lifeTime
    ? serverResponse.lifeTime
    : 900;

  return (
    <Grid container spacing={1} mt={3} mb={3}>
      <Grid size={12}>
        <Typography variant="h3">{t('SIGN.IN_TITLE')}</Typography>
      </Grid>
      {!isLoaded ? (
        <Grid size={12} mt={3} mb={4}>
          <Loading />
        </Grid>
      ) : (
        <>
          <Grid size={12} mt={3} mb={1}>
            <ShowResponse serverResponse={serverResponse} />
          </Grid>
          {serverResponse?.action === 'CONFIRM_CODE_SENT' ||
          serverResponse?.action === 'WRONG_CONFIRMATION_CODE' ||
          serverResponse?.action === 'CONFIRM_SINGUP' ? (
            <Grid size={12}>
              <Typography variant="h4" mb={4}>
                {t('COMPONENT.TITLE_CONFIRM_CODE')}
              </Typography>
              <SignFormConfirm
                values={values}
                handleChange={handleChange}
                errors={errors}
                handleSubmit={handleSubmit}
                submitAgain={reSendCode}
                lifeTime={lifeTime}
                isLoaded={isLoaded}
                name="code"
                takeStepBack={() => {
                  setActiveTabId(0);
                }}
              />
            </Grid>
          ) : (
            <SignInOtpForm
              values={values}
              errors={errors}
              setActiveTabId={setActiveTabId}
              appInfo={appInfo}
              handlePhoneChange={handlePhoneChange}
              handleSubmit={handleSubmit}
              hasButtonGoSignUp={hasButtonGoSignUp}
            />
          )}
        </>
      )}
    </Grid>
  );
}

export default SignIn;
