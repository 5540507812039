import React, { useState } from 'react';
import Modale from '../../../components/Modals/Modale';
import CalypsoForms from '../../calypso_forms';
import { Box } from '@mui/material';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleCalypsoFormAnketa() {
  const [modale, setModale] = useState(true);

  return (
    <Modale
      open={modale}
      toggleModal={() => {
        if (modale) localStorage.setItem('anketaClosed', true);
        setModale(!modale);
      }}
      height={910}
      maxWidth="lg"
      title="Анкета"
    >
      <Box
        sx={(theme) => ({
          minWidth: 1100,
          [theme.breakpoints.down('lg')]: {
            minWidth: 900,
          },
          [theme.breakpoints.down('md')]: {
            minWidth: 600,
          },
          [theme.breakpoints.down('sm')]: {
            minWidth: 480,
          },
          [theme.breakpoints.down('xs')]: {
            minWidth: 320,
          },
        })}
      >
        <CalypsoForms />
      </Box>
    </Modale>
  );
}
