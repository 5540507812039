import React from 'react';
import { Outlet } from 'react-router-dom';

// components
import Header from '../Header/HeaderDoctor';

import { Link, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import { useTranslation } from 'react-i18next';

// styles
import useStyles from './stylesDoctor';

import { Container } from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import { getYearNow } from '../../helpers';

function LayoutDoctor() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  return (
    <div className={classes.root}>
      <Header />

      <div className={classes.conteiner}>
        <Container className={classes.content}>
          <Outlet />
        </Container>

        <div className={classes.footer}>
          <Divider />
          <div className={classes.marginTop}>
            <Typography
              variant="body2"
              component="span"
              className={classes.marginTop}
            >
              {t('BOTTOM.COPY', { year: getYearNow() })}
            </Typography>{' '}
            <Link
              color={'primary'}
              href={'/'}
              target={'_blank'}
              className={classes.link}
            >
              {appInfo.defTitle}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutDoctor;
