import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import { Dispatch } from 'react';
import { Action, ActionType, Faq } from '../types/faq.d';

export const getFaq = async (dispatch: Dispatch<Action>) => {
  try {
    const { data } = await httpClient.get(api.url('GetFaq'));
    dispatch({
      type: ActionType.fetch,
      payload: data as Faq[],
    });
  } catch (error) {
    dispatch({
      type: ActionType.error,
      payload: getError(error),
    });

    console.log('ERROR GetFaq', getError(error));
  }
};
