import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout/LayoutDoctor';

/* pages */
import Mmk from '../pages/mmk';
import Visit from '../pages/visit';
import Chat from '../pages/chat';
import Visits from '../pages/visits_doc';
import Workspace from '../pages/workspace';

/** ======== Private Routes =========== */
export default function PrivateRoutesDoctor() {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="chat" element={<Chat />} />
        <Route
          path="mmk/:specificRecordId/:paramClinicId"
          element={<Mmk />}
        />
        <Route path="mmk" element={<Mmk />} />

        <Route
          path="visit/:specIdParam/:clinicId"
          element={<Visit />}
        />
        <Route path="visit/:specIdParam" element={<Visit />} />
        <Route path="visit" element={<Visit />} />

        <Route
          path="events_and_visits/:specificRecordId/:paramClinicId"
          element={<Visits />}
        />
        <Route path="events_and_visits" element={<Visits />} />
        <Route path="workspace" element={<Workspace />} />
      </Route>
    </Routes>
  );
}
