import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  blankCovid: {
    width: '100%',
    fontFamily: 'Roboto',
    color: '#000000',
    padding: 12,
  },
  container: {
    width: '100%',
    maxWidth: 1166,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginTop: 70,
    },
  },
  headLeft: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  logo: {
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      width: 212,
    },
  },
  headRight: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  headTitle: {
    //fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    color: '#7C7D80',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  switchLang: {
    '& .MuiToggleButton-root': {
      border: `1px solid ${theme.palette.primary.main}`,
      width: '100%',
      height: 28,
    },
    '& .MuiToggleButtonGroup-grouped': {
      padding: '8px 14px 8px 14px',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
  },
  hr: {
    height: 1,
    margin: '5px 0 20px 0',
    padding: 0,
    bboxSizing: 'border-box',
    background: '#B2B6C2',
  },
  containerFio: {
    alignSelf: 'flex-start',
  },
  textFio: {
    fontSize: 28,
    //lineHeight: 33,
    margin: '12px 12px 16px  0',
    display: 'inline',
  },
  textGender: {
    display: 'inline',
    padding: 10,
    fontSize: 14,
    border: '1px solid #C4C4C4',
    position: 'relative',
    top: '-2px',
  },
  marginTop16: { marginTop: 16 },
  text20: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 20,
    margin: '0 0 6px 0',
  },
  text16: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    margin: '0 0 6px 0',
  },
  right: {
    textAlign: 'right',
  },
  alignRight: {
    textAlign: 'right',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      textAlign: 'left',
    },
  },
  alignCenter: {
    textAlign: 'center',
    margin: ' 0 auto',
  },
  marginTopBottom6: {
    margin: 0,
  },

  containerDateGrid: {
    width: 386,
    marginLeft: 'auto',
  },
  textDateTitle: {
    fontSize: 16,
    margin: '0 6px 6px 0',
  },
  textDateValue: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '0 0 6px 0',
  },
  containerTitleBlank: {
    alignSelf: 'center',
    marginTop: 44,
  },
  textTitleBlank: {
    fontSize: 20,
    fontWeight: 'bold',

    margin: '0 0 5px 0',
    textAlign: 'center',
  },
  textTitleBlankNo: {
    fontSize: 16,
    margin: 0,
  },

  containerTable: {
    marginTop: 20,
  },

  tableContainer: {
    width: '100%',
    borderTop: '1px solid #C4C4C4',
    borderLeft: '1px solid #C4C4C4',
    borderRight: '1px solid #C4C4C4',
  },
  tableHeader: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    padding: 16,
    background: '#F7F7F7',
    borderBottom: '1px solid #C4C4C4',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      padding: 8,
    },
  },
  tableContent: {
    fontSize: 14,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      padding: 8,
    },
    borderBottom: '1px solid #C4C4C4',
  },
}));
