import React from 'react';
import { Paper } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';

function createStyled(styles, options) {
  const Styled = function (props) {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function LeftPlace({ children, ...props }) {
  const theme = useTheme();

  const Styled = createStyled({
    root: {
      borderBottom: `1px solid ${theme.palette.bgLight.two}`,
      boxSizing: 'border-box',
      boxShadow: theme.palette.shadows.card,
      '&.MuiPaper-rounded': {
        borderRadius: theme.spacing(1),
      },
    },
  });

  return (
    <Styled>
      {({ classes }) => (
        <Paper classes={{ root: classes.root }} {...props}>
          {children}
        </Paper>
      )}
    </Styled>
  );
}

export { LeftPlace };
