import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNotificationsState } from '../../context/NotificationsContext';

import { Menu, MenuItem, Box } from '@mui/material';
import Notification from '../Notification/NotificationWithPhoto';
import UsersNotificationFilter from './UsersNotificationFilter';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import isEmpty from '../../helpers';
import config from '../../config';
import { hl7ToUtc } from '../../helpers/dateFormat';
import Loading from '../Loading';
import { useModalChatVisitLocal } from '../../pages/visits/useModalChatVisit';
import VisitModalChatConf from '../../pages/visits/VisitModalChatConf';

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    marginTop: theme.spacing(3),
    width: 652,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.hero} #fff`,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.palette.primary.lighter,
      border: '5px solid #fff',
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
      {
        backgroundColor: '#fff',
      },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
      {
        backgroundColor: theme.palette.primary.lighter,
        border: '3px solid #fff',
      },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
      {
        backgroundColor: theme.palette.primary.lighter,
        border: '3px solid #fff',
      },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#fff',
    },
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: 620,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    alignItems: 'flex-start',
    '&:focus,&:active,&:hover': {
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
  gapTop: {
    width: '100%',
    height: 50,
    marginTop: theme.spacing(7),
  },
  headerMenuItem: {
    whiteSpace: 'normal',

    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  headerMenuItemNoActive: {
    whiteSpace: 'normal',
    cursor: 'auto',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function UsersNotificationMenu({
  notificationsMenu,
  setNotificationsMenu,
  lang,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { notifications, typeFilter, searchValue },
  } = useNotificationsState();

  const { chatInfo, modalChatVisit, toggleModalChatVisit } =
    useModalChatVisitLocal();

  const unreadCount = notifications.unreadCount;
  const routeByType = (type) => {
    // “Other” - Не относится ни к одной другой категории
    // “MmkRegistration” - Регистрация записи ММК
    // “NewVisit” - Новая запись на прием
    // “VisitCancelled” - Запись на прием отменена
    // “VisitUpdates” - Запись на прием изменена
    // “NewMmkRecord” - Новая запись ММК
    // “MmkRecordCancelled” - Запись ММК отменена
    // “OnlineConsultationHasBegun” - Начало онлайн-консультации
    // “NewAssignments” - Новые назначения
    // “NewDirections” - Новые направления
    // “NewRecommendations” - Новые рекомендации
    // “InvoicePaid” - Счет оплачен
    // “NewInvoice” - Новый счет
    // “Questionnaire” - Предложение заполнить анкету

    if (['NewInvoice', 'InvoicePaid'].includes(type))
      return 'invoices';
    if (
      [
        'NewVisit',
        'VisitUpdates',
        'VisitCancelled',
        'OnlineConsultation',
      ].includes(type)
    )
      return 'events_and_visits';
    if (['NewMmkRecord', 'MmkRecordCancelled'].includes(type))
      return 'mmk';

    return null;
  };
  const targetBtanch = (type, id, clinicId = 0) => {
    return (
      routeByType(type) !== null &&
      `/${config.AUTH_ROUTE_PREFIX}/${routeByType(
        type,
      )}/${id}/${clinicId}`
    );
  };

  const goToBranchRecord = (notification) => {
    // if (notification.type === 'Questionnaire') {
    //   const needParams = notification.params.reduce((acc, item) => {
    //     if (item?.Key === 'QuestId') acc['QuestId'] = item.Value;
    //     if (item?.Key === 'Guid')
    //       acc['Guid'] = item.Value.replace(/^[{]+|[}]+$/g, '');
    //     return acc;
    //   }, {});
    //    console.log("needParams", needParams);
    //   setParams(needParams);
    //   setModal(true);
    // } else {
    if (
      notification.type === 'OnlineConsultation' &&
      notification.params?.doctorUserId != null
    ) {
      toggleModalChatVisit({ ...notification.params });
    } else {
      const to = targetBtanch(
        notification.type,
        notification.recordId,
        notification.clinicId,
      );
      if (to) navigate(to);
    }
    setNotificationsMenu(null);
  };

  const classNameItem = (notification) =>
    notification.type === 'Questionnaire' ||
    targetBtanch(
      notification.type,
      notification.recordId,
      notification.clinicId,
    )
      ? classes.headerMenuItem
      : classes.headerMenuItemNoActive;

  const sortDataByDateTime = (arr) =>
    arr.sort(
      (a, b) =>
        new Date(hl7ToUtc(b.dateTime)).getTime() -
        new Date(hl7ToUtc(a.dateTime)).getTime(),
    );

  const menuItems = useMemo(
    () =>
      sortDataByDateTime(notifications.data).map((notification) => (
        <MenuItem
          key={notification.id}
          onClick={() => goToBranchRecord(notification)}
          className={classNameItem(notification)}
        >
          <Notification
            id={notification.id}
            subject={notification.subject}
            message={notification.body}
            dateTime={notification.dateTime}
            photoUrl={
              notification.doctorsPhotoUrl
                ? `${notification.doctorsPhotoUrl}&imageSize=small`
                : null
            }
            type={notification.type}
            color={
              notification.type === 'VisitUpdates' ||
              notification.type === 'InvoicePaid'
                ? 'success'
                : notification.type === 'VisitCancelled'
                ? 'warning'
                : 'secondary'
            }
            typographyVariant="inherit"
            lang={lang}
          />
        </MenuItem>
      )),
    [
      notifications.data,
      unreadCount,
      notifications.startIndex,
      notifications.count,
      typeFilter,
      searchValue,
      lang,
    ],
  );

  // console.log('chatInfo', chatInfo, 'modalChatVisit', modalChatVisit);
  return (
    <>
      <Menu
        id="notifications-menu"
        open={Boolean(notificationsMenu)}
        anchorEl={notificationsMenu}
        onClose={() => setNotificationsMenu(null)}
        className={classes.headerMenu}
      >
        <MenuItem
          className={classes.profileMenuUser}
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          disableRipple
        >
          <Typography variant="h4">{t('HEADER.MESSAGES')}</Typography>

          {unreadCount > 0 && (
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {t('HEADER.CONT_NEW_MSS', { unreadCount })}
            </Typography>
          )}

          <Box mt={5} sx={{ width: '100%' }}>
            <UsersNotificationFilter />
          </Box>
          {!notifications.isLoaded && (
            <Box mt={5} sx={{ width: '100%' }}>
              <Loading />
            </Box>
          )}

          {notifications.isLoaded && isEmpty(notifications.data) && (
            <Box mt={3}>
              <Typography variant="body2" component="p">
                {t('EMPTY_DATA.NO_NOTIFICATIONS')}
              </Typography>
            </Box>
          )}
        </MenuItem>
        {notifications.isLoaded &&
          !isEmpty(notifications.data) &&
          menuItems}
      </Menu>
      {!isEmpty(chatInfo) && (
        <VisitModalChatConf
          chatInfo={chatInfo}
          lang={lang}
          modalChatVisit={modalChatVisit}
          toggleModalChatVisit={toggleModalChatVisit}
        />
      )}
    </>
  );
}
export default UsersNotificationMenu;
