import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';

/* ui mui components */
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

/* ui local components */

import Header from '../Header/Header';
import Sidebar from '../Sidebar/SidebarSimple';
import SidebarInfo from '../Sidebar/SidebarInfo';
import LeftMenu from '../Sidebar/LeftMenu';
import Footer from '../Footer/Footer';
import ChatOper from '../ChatOper';

/* lang */
import { useTranslation } from 'react-i18next';
import { getChatOperatorInfo } from '../../actions/user';
import cn from 'classnames';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty, { getYearNow } from '../../helpers';

/* styles */
import useStyles from './styles';

import config from '../../config';
import useInterval from '../../hooks/useInterval';
import custom from '../../custom';
//import Loading from '../Loading';

// eslint-disable-next-line react/prop-types
const HeaderH1 = ({ structure, isAuthenticated }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react/prop-types
  const dataH1 = structure.find(
    (c) =>
      c.defaultH1 &&
      location.pathname.startsWith(
        config.accessLink(isAuthenticated, c.link),
      ),
  );

  //console.log('dataH1', dataH1, location.pathname);
  return (
    !isEmpty(dataH1) && (
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        {t(`MAINMENU.${dataH1.t}`)}
      </Typography>
    )
  );
};
function Layout() {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: {
      user: { lang },
      isAuthenticated,
      appInfo,
      structure,
      //isMmkLinkedListLoaded,
    },
  } = useUserStateDispatch();

  const GooglePlayIcon = custom.GooglePlayIcon;
  const AppStoreIcon = custom.AppStoreIcon;
  const RuStoreIcon = custom.RuStoreIcon;

  const withPhoto = [
    '/app/promo',
    '/app/directions',
    '/app/sessions',
    '/app/faq',
    '/app/tax_deduction',
  ];
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const location = useLocation();

  const hasPhoto =
    withPhoto.find((it) => location.pathname.startsWith(it)) !=
      null && !isMobile;

  const [modalChat, setModalChat] = useState(false);
  const [chatInfo, setChatInfo] = useState({
    unreadCount: null,
    groupId: null,
  });

  const toggleModalChat = () => {
    setModalChat(!modalChat);
  };

  useEffect(() => {
    if (
      appInfo.isChatEnabled &&
      appInfo.showChatWithOperator &&
      isAuthenticated
    ) {
      getChatOperatorInfo(setChatInfo);
    }
  }, []);

  useInterval(
    () => {
      getChatOperatorInfo(setChatInfo);
    },
    appInfo.isChatEnabled &&
      appInfo.showChatWithOperator &&
      isAuthenticated,
    15000,
  );

  return useMemo(
    () => (
      <div className={classes.root}>
        <Header />
        <Sidebar />

        {isAuthenticated && (
          <SidebarInfo
            lang={lang}
            chatInfo={chatInfo}
            modalChat={modalChat}
            toggleModalChat={toggleModalChat}
          />
        )}

        <Container
          className={cn(
            classes.conteiner,
            appInfo.showChatWithOperator &&
              chatInfo?.unreadCount !== 0
              ? classes.conteinerTopInfo
              : classes.conteinerTop,
          )}
        >
          <div className={classes.content}>
            {hasPhoto ? (
              <Grid container spacing={3}>
                <Grid size={{ sm: 3 }}>
                  <LeftMenu />
                </Grid>
                <Grid size={{ sm: 9 }}>
                  <HeaderH1
                    structure={structure}
                    isAuthenticated={isAuthenticated}
                  />
                  <Outlet />
                </Grid>
              </Grid>
            ) : (
              <>
                <HeaderH1
                  structure={structure}
                  isAuthenticated={isAuthenticated}
                />
                <Outlet />
              </>
            )}
          </div>
          {isAuthenticated &&
            appInfo.isChatEnabled &&
            appInfo.showChatWithOperator && (
              <ChatOper
                lang={lang}
                chatInfo={chatInfo}
                modalChat={modalChat}
                toggleModalChat={toggleModalChat}
              />
            )}
          <Footer>
            <Divider
              sx={{ position: 'absolute', left: 0, width: '100%' }}
            />

            <Grid container>
              {appInfo.showStoreLinks && (
                <Grid className={classes.marginGoogleButtons}>
                  {appInfo.linkGooglePlay !== '' && (
                    <Link
                      href={appInfo.linkGooglePlay}
                      target={'_blank'}
                      className={classes.appLink}
                    >
                      <GooglePlayIcon />
                    </Link>
                  )}
                  {appInfo.linkAppStore !== '' && (
                    <Link
                      href={appInfo.linkAppStore}
                      target={'_blank'}
                      className={classes.appLink}
                    >
                      <AppStoreIcon />
                    </Link>
                  )}
                  {appInfo.linkRuStore !== '' && (
                    <Link
                      href={appInfo.linkRuStore}
                      target={'_blank'}
                      className={classes.appLink}
                    >
                      <RuStoreIcon />
                    </Link>
                  )}
                </Grid>
              )}
              <Grid>
                <Typography className={classes.copyright}>
                  {t('BOTTOM.COPY', { year: getYearNow() })}{' '}
                  <Link to="/" className={classes.link}>
                    {appInfo.defTitle}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Footer>
        </Container>
        {/* <Backdrop
          open={!isMmkLinkedListLoaded}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Box>
            <Loading size={62} />
          </Box>
        </Backdrop> */}
      </div>
    ),
    [
      chatInfo?.unreadCount,
      modalChat,
      hasPhoto,
      structure.length,
      lang,
    ],
  );
}

export default Layout;
