import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout/LayoutOper';

/* pages */
import Visit from '../pages/visit';
import Chat from '../pages/chat';
import Visits from '../pages/visits_doc';

/** ======== Private Routes =========== */
export default function PrivateRoutesOper() {
  return (
    <Routes>
      <Route path="*" element={<Layout />}>
        <Route path="chat" element={<Chat />} />
        <Route
          path="visit/:specIdParam/:clinicId"
          element={<Visit />}
        />
        <Route path="visit/:specIdParam" element={<Visit />} />
        <Route path="visit" element={<Visit />} />

        <Route
          path="events_and_visits/:specificRecordId/:paramClinicId"
          element={<Visits />}
        />
        <Route path="events_and_visits" element={<Visits />} />
      </Route>
    </Routes>
  );
}
