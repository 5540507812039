import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import { Dispatch } from 'react';
import { Action, ActionType, Calypso } from '../types/calypso.d';
import isEmpty from '../helpers';

// export const calypsoForms = async (
//   dispatch: Dispatch<Action>,
//   data: Calypso[],
//   mmkId: string,
//   clinicId: number,
// ) => {
//   try {
//     if (!isEmpty(data)) {
//       console.log('data', data);
//       //  data.forEach(async (item: Calypso) => {
//       await calypsoAuth(dispatch, data[0], mmkId, clinicId);
//       // });
//     }
//   } catch (error) {
//     dispatch({
//       type: ActionType.error,
//       payload: getError(error),
//     });

//     console.log('ERROR calypsoForms', getError(error));
//   }
// };

export const calypsoAuth = async (
  dispatch: Dispatch<Action>,
  item: Calypso,
  mmkId: string,
  clinicId: number,
) => {
  try {
    const { data } = await httpClient.get(api.url('calypsoAuth'), {
      formUUID: item.formUUID,
      mmkId,
      clinicId,
    });
    if (!isEmpty(data)) {
      dispatch({
        type: ActionType.fetch,
        payload: { ...item, ...data } as Calypso,
      });
    }
  } catch (error) {
    console.log('ERROR calypsoAuth', getError(error));
    dispatch({
      type: ActionType.error,
      payload: getError(error),
    });
  }
};
