import React, { useEffect } from 'react';

// styles
import useStyles from './styles';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { loginDoctor } from '../../actions/user';

//components
import Button from '@mui/material/Button';
import Input from '@mui/material/TextField';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignIn';

//i18n
import { useTranslation } from 'react-i18next';
//import isEmpty from '../../helpers';
import { useLanguageValue } from '../../context/LanguageContext';
import Loading from '../../components/Loading';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ valsTab }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { languageState } = useLanguageValue();

  // global
  const {
    userDispatch,
    userState: { isLoaded, appInfo },
  } = useUserStateDispatch();

  const login = () =>
    loginDoctor(
      userDispatch,
      values.email,
      values.password,
      0,
      0,
      languageState.language,
    );

  const { values, errors, handleChange, handleSubmit, setValues } =
    useForm(login, validate, appInfo);

  useEffect(() => {
    setValues({
      ...values,
      email: valsTab.email,
      password: valsTab.password,
      //clinicId: clinics[0]?.id,
    });
  }, []);

  return (
    <>
      <Input
        name="email"
        variant="outlined"
        value={values.email || ''}
        onChange={handleChange}
        margin="normal"
        placeholder={t('COMPONENT.FORM_EMAIL')}
        type="email"
        fullWidth
        required
        error={errors?.email != null}
        helperText={errors?.email != null && errors?.email}
      />

      <Input
        name="password"
        variant="outlined"
        value={values.password || ''}
        onChange={handleChange}
        margin="normal"
        placeholder={t('COMPONENT.FORM_PASS')}
        type="password"
        fullWidth
        required
        error={errors?.password != null}
        helperText={errors?.password != null && errors?.password}
      />

      <div className={classes.formButtons}>
        {!isLoaded ? (
          <Loading />
        ) : (
          <Button
            className={classes.summitButton}
            variant="contained"
            color="primary"
            disabled={
              //values.clinicId == null ||
              values.email == null || values.password == null
            }
            onClick={handleSubmit}
          >
            {t('COMPONENT.BUTTON_NEXT')}
          </Button>
        )}
      </div>
    </>
  );
}
export default SignIn;
