import React, { useEffect } from 'react';
import Modale from '../../../components/Modals/Modale';
import Typography from '@mui/material/Typography';
import { useUserStateDispatch } from '../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import FeedBackForm from '../../../components/Forms/FeedBackForm';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleFeedback({
  modalFeedback,
  setModalFeedback,
}) {
  const { t } = useTranslation();
  const {
    userState: { serverResponse },
    userDispatch,
  } = useUserStateDispatch();

  useEffect(() => {
    if (serverResponse?.action && modalFeedback) {
      setTimeout(() => {
        userDispatch({
          type: 'LOADED',
        });
        setModalFeedback(false);
      }, 3500);
    }
  }, [modalFeedback]);

  return (
    <Modale
      open={modalFeedback}
      toggleModal={() => setModalFeedback(!modalFeedback)}
      height={610}
      maxWidth="md"
    >
      <Typography variant="h3" align="center" mb={4}>
        {t('FEEDBACK.FORM_TITLE')}
      </Typography>
      <Typography variant="h6" align="center" my={3}>
        {t('FEEDBACK.FORM_SUBTITLE')}
      </Typography>
      <FeedBackForm />
    </Modale>
  );
}
