import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCalypsoState } from '../../context/CalypsoContext';
import { calypsoAuth } from '../../actions/calypso';
import { Alert, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  ActionType,
  Calypso,
  defformUUID,
} from '../../types/calypso.d';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { useUserStateDispatch } from '../../context/UserContext';
import config from '../../config';

const urlForm = (item: Calypso) => {
  return `${item.address}/medialog/form/?formGuid=${item.formUUID}&refreshToken=${item.refreshToken}&theme=${config.theme}`;
};
const CalypsoForm: React.FC = () => {
  const { t } = useTranslation();
  const { formUUIDroute } = useParams();
  const {
    state: { calypso },
    setState,
  } = useCalypsoState();
  const {
    userState: { calypsoData, user },
  } = useUserStateDispatch();

  useEffect(() => {
    setState({
      type: ActionType.setFormUUID,
      payload: formUUIDroute ?? defformUUID,
    });
  }, [formUUIDroute]);

  useEffect(() => {
    const itemData = calypsoData.find(
      (item: Calypso) => item.formUUID === calypso.formUUID,
    );
    if (!isEmpty(itemData))
      calypsoAuth(setState, itemData, user.mmkId, user.clinicId);
  }, [calypso.formUUID]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (
        event.data === 'getUserInfo' &&
        event.source != null &&
        calypso.data != null
      ) {
        event.source.postMessage(
          user,
          event.origin as WindowPostMessageOptions,
        );
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [calypso.data]);

  return (
    <>
      {!calypso.isLoaded ? (
        <Loading />
      ) : calypso.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {calypso.responseError}
          </Typography>
        </Alert>
      ) : calypso.data != null ? (
        <Grid
          container
          key={calypso.data.formUUID}
          sx={{ width: '100%' }}
        >
          <Grid
            size={12}
            sx={(theme) => ({
              minHeight: 2000,
              [theme.breakpoints.down('lg')]: {
                minHeight: 2200,
              },
              [theme.breakpoints.down('md')]: {
                minHeight: 2400,
              },
              [theme.breakpoints.down('sm')]: {
                minHeight: 2800,
              },
              [theme.breakpoints.down('xs')]: {
                minHeight: 3200,
              },
            })}
          >
            <iframe
              src={urlForm(calypso.data)}
              title="Form"
              height="100%"
              width="100%"
              style={{
                width: '100%',
                height: '100%',
                border: 0,
              }}
            >
              no data
            </iframe>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default CalypsoForm;
