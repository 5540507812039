import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';

import { useUserStateDispatch } from '../../context/UserContext';
import { useVisitState } from '../../context/VisitContext';
import { useLanguageValue } from '../../context/LanguageContext';

import {
  createUser,
  singUpConfirmationCode,
  loginUser,
  requestNewAccountConfirmationCode,
  confirmLogin,
  clearLocalStorage,
  otpLogin,
} from '../../actions/user';
import { sendRequestEmailToClinic } from '../../actions/visit';

import useForm from '../../hooks/useForm';
import Loading from '../../components/Loading';
import ShowResponse from '../../components/FormComponents/ShowResponse';
import SignFormConfirm from '../../components/Forms/SignFormConfirm';
import SignUpForm from '../../components/Forms/SignUpForm';
import SignInForm from '../../components/Forms/SignInForm';
import SignInOtpForm from '../../components/Forms/SignInOtpForm';
import ChangePassword from '../../components/Forms/ChangePassword';
import FIOandPhone from './FIOandPhone';
import SignInQr from '../../components/Forms/SignInQr';

import { validate as validateSignUp } from '../../components/Forms/validationSignUp';
import validateConfirmSignUp from '../../components/Forms/validationConfirmSignUp';
import validateSignIn from '../../components/Forms/validationSignIn';
import validationFIOandPhone from './validationFIOandPhone';

import config from '../../config';
import { dateToHL7 } from '../../helpers/dateFormat';
import { cleanPhoneValue } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${theme.spacing(4)}`,
      minWidth: theme.spacing(61),
    },
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign({ visitData, toggleModal }) {
  const classes = useStyles();
  const {
    userDispatch,
    userState: { appInfo, user, serverResponse, isLoaded },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();

  const [valsTab, setValsTab] = useState({
    activeTabId: 100,
    ...config.defLoginPassword,
    authIdentifiers: appInfo.authIdentifiers,
  });

  const _appInfo = {
    ...appInfo,
    authIdentifiers: valsTab.authIdentifiers,
  };

  const setActiveTabId = (activeTabId) => {
    userDispatch({
      type: 'LOADED',
    });
    setValsTab({
      ...valsTab,
      activeTabIdPrev: valsTab.activeTabId,
      activeTabId,
    });
  };
  const lifeTime = serverResponse?.lifeTime
    ? serverResponse?.lifeTime
    : 900;

  const reSendCode = () => {
    if (serverResponse?.action === 'CONFIRM_CODE_SENT') {
      login();
    }
    if (serverResponse?.action === 'CONFIRM_SINGUP') {
      requestNewAccountConfirmationCode(userDispatch, {
        login: values.login,
        password: values.password,
      });
    }
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setState } = useVisitState();
  const { visitDate, doctor } = visitData;

  const sendToClinic = () => {
    if (!values.lastName || !values.firstName) {
      return;
    }
    sendRequestEmailToClinic({
      setState,
      lastName: values.lastName,
      firstName: values.firstName,
      middleName: values.middleName,
      phone: values.phone
        ? cleanPhoneValue(values.phone)
        : values.login
        ? cleanPhoneValue(values.login)
        : null,
      plSubjId: doctor.plSubjId,
      visitDate,
      clinicId: doctor.clinicId,
    });
    toggleModal(visitDate);
  };

  const login = () => {
    if (appInfo.authIdentifiers === 'otp') {
      if (serverResponse?.action === 'CONFIRM_SINGUP') {
        singUpConfirmationCode(
          userDispatch,
          {
            login: values.login,
            confirmationCode: values.code || values.confirmationCode,
          },
          language,
          navigate,
          appInfo.isAnonymousChildrenEnabled,
        );
        return;
      }
      otpLogin({
        dispatch: userDispatch,
        navigate,
        lang: language,
        login: cleanPhoneValue(values.login),
        code: values.code,
        isAnonymousChildrenEnabled:
          appInfo.isAnonymousChildrenEnabled,
      });
      return;
    }
    if (serverResponse?.action === 'CONFIRM_SINGUP') {
      confirmLogin(
        userDispatch,
        values.code,
        navigate,
        language,
        appInfo.isAnonymousChildrenEnabled,
      );
    }
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );
  };

  const signUp = () =>
    createUser(
      userDispatch,
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },

      appInfo.countryCode,
    );

  const confirmSignUp = () => {
    singUpConfirmationCode(
      userDispatch,
      {
        login: values.login,
        password: values.password,
        confirmationCode: values.code || values.confirmationCode,
      },
      language,
      navigate,
      appInfo.isAnonymousChildrenEnabled,
      true,
    );
  };

  const isConfirmForm =
    serverResponse &&
    (serverResponse?.action === 'CONFIRM_CODE_SENT' ||
      serverResponse?.action === 'WRONG_CONFIRMATION_CODE');

  let submit,
    validate = null;
  if (valsTab.activeTabId === 100) {
    submit = sendToClinic;
    validate = validationFIOandPhone;
  }
  if (valsTab.activeTabId === 0) {
    submit =
      serverResponse?.action === 'CONFIRM_SINGUP'
        ? confirmSignUp
        : login;
    validate = validateSignIn;
  }
  if (valsTab.activeTabId === 1) {
    submit = isConfirmForm ? confirmSignUp : signUp;
    validate = isConfirmForm ? validateConfirmSignUp : validateSignUp;
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
    handleDateChange,
  } = useForm(submit, validate, _appInfo);

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  useEffect(() => {
    return () => {
      userDispatch({
        type: 'LOADED',
      });
    };
  }, []);

  useEffect(() => {
    if (valsTab.activeTabId === 100) {
      setValues({
        ...values,
        needPhone: true,
        phone: values.phone || user.phoneParent,
      });
    }
    if (valsTab.activeTabId === 1) {
      const vals = {
        ...values,
        login: ['phone', 'both'].includes(appInfo.authIdentifiers)
          ? values?.phone
          : null,
      };
      setValues(vals);
      setErrors(validateSignUp(vals, appInfo));
    }
    // eslint-disable-next-line
  }, [valsTab.activeTabId]);

  useEffect(() => {
    if (serverResponse?.action === 'MAY_LOGIN') {
      sendToClinic();
      setTimeout(() => login(), 3000);
    }
    if (
      serverResponse?.action === 'WRONG_LOGIN_OR_CONFIRMATION_CODE'
    ) {
      setTimeout(() => {
        setActiveTabId(1);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [serverResponse?.action]);
  const buttonLabel = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_BUTTON_PHONE');
      }
      case 'phone': {
        return t('SIGN.IN_BUTTON_EMAIL');
      }
    }
  };
  const title = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_TITLE_EMAIL');
      }
      case 'phone': {
        return t('SIGN.IN_TITLE_PHONE');
      }
      default: {
        return t('SIGN.IN_TITLE');
      }
    }
  };
  const handleAuthIdentifiers = () => {
    const authIdentifiers =
      valsTab.authIdentifiers === 'email' ? 'phone' : 'email';
    userDispatch({ type: 'LOADED' });
    setValsTab({
      ...valsTab,
      authIdentifiers,
    });
    const vals = {
      ...values,
      login: null,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  return (
    <Box className={classes.conteiner}>
      {valsTab.activeTabId === 100 ? (
        <FIOandPhone
          setActiveTabId={setActiveTabId}
          visitData={visitData}
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          validate={validationFIOandPhone}
          handlePhoneChange={handlePhoneChange}
        />
      ) : valsTab.activeTabId === 0 ? (
        <Grid container spacing={1} mt={3} mb={3}>
          <Grid size={7}>
            <Typography variant="h3">{title()}</Typography>
          </Grid>

          <Grid size={5} textAlign="right">
            {appInfo.authIdentifiers !== 'both' && (
              <Button color="primary" onClick={handleAuthIdentifiers}>
                {buttonLabel()}
              </Button>
            )}
          </Grid>
          {!isLoaded ? (
            <Grid size={12} mt={3} mb={4}>
              <Loading />
            </Grid>
          ) : (
            <>
              <Grid size={12} mt={3} mb={2}>
                <ShowResponse serverResponse={serverResponse} />
              </Grid>
              {serverResponse?.action === 'CONFIRM_CODE_SENT' ||
              serverResponse?.action === 'WRONG_CONFIRMATION_CODE' ||
              serverResponse?.action === 'CONFIRM_SINGUP' ? (
                <Grid size={12} mt={3} mb={2}>
                  <Typography variant="h4" mb={4}>
                    {t('COMPONENT.TITLE_CONFIRM_CODE')}
                  </Typography>
                  <SignFormConfirm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    submitAgain={reSendCode}
                    lifeTime={lifeTime}
                    isLoaded={isLoaded}
                    name="code"
                    takeStepBack={() => {
                      clearLocalStorage();
                      setActiveTabId(0);
                    }}
                  />
                </Grid>
              ) : (
                <>
                  {appInfo.authIdentifiers !== 'otp' ? (
                    <SignInForm
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      valsTab={valsTab}
                      setActiveTabId={setActiveTabId}
                      appInfo={appInfo}
                      handlePhoneChange={handlePhoneChange}
                      hasButtonGoSignUp={true}
                    />
                  ) : (
                    <SignInOtpForm
                      values={values}
                      errors={errors}
                      setActiveTabId={setActiveTabId}
                      appInfo={appInfo}
                      handlePhoneChange={handlePhoneChange}
                      handleSubmit={handleSubmit}
                      hasButtonGoSignUp={true}
                    />
                  )}

                  <Grid size={12} mt={3} mb={2}>
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        setValues({});
                        userDispatch({ type: 'LOADED' });
                        setActiveTabId(100);
                      }}
                      style={{ width: '100%' }}
                    >
                      {t('COMPONENT.BACK_ONE_STEP')}
                    </Button>
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      ) : valsTab.activeTabId === 1 ? (
        <>
          <Typography variant="h3" mt={3} mb={4}>
            {t('SIGN.UP_BUTTON')}
          </Typography>
          <ShowResponse
            serverResponse={serverResponse}
            okKey="SIGN.UP_OK"
          />
          {isConfirmForm ? (
            <SignFormConfirm
              values={values}
              handleChange={handleChange}
              errors={errors}
              handleSubmit={handleSubmit}
              submitAgain={signUp}
              lifeTime={serverResponse?.lifeTime}
            />
          ) : (
            <SignUpForm
              values={values}
              handleChange={handleChange}
              setValues={setValues}
              errors={errors}
              handleSubmit={handleSubmit}
              handleDateChange={handleDateChange}
              handleChangeGender={handleChangeGender}
              handlePhoneChange={handlePhoneChange}
            />
          )}

          <Button
            color="primary"
            variant="text"
            onClick={() => {
              setValues({});
              userDispatch({ type: 'LOADED' });
              setActiveTabId(100);
            }}
            style={{ width: '100%' }}
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </>
      ) : valsTab.activeTabId === 2 ? (
        <ChangePassword setValsTab={setValsTab} valsTab={valsTab} />
      ) : valsTab.activeTabId === 3 ? (
        <SignInQr setActiveTabId={setActiveTabId} />
      ) : null}
    </Box>
  );
}

export default Sign;
