import React from 'react';
import { makeStyles } from '@mui/styles';
import Modale from '../../../components/Modals/Modale';
import { Link, Typography } from '@mui/material';
import { useUserStateDispatch } from '../../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contItem: {
    width: theme.spacing(52),
    margin: 'auto',
  },
  img: {
    display: 'inline-flex',
    width: theme.spacing(28),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleShowPhone({
  open,
  phoneNumber,
  setModalShowPhone,
}) {
  const classes = useStyles();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  return (
    <Modale
      open={open}
      toggleModal={() =>
        setModalShowPhone({ open: !open, phoneNumber })
      }
      height={440}
    >
      <div className={classes.conteiner}>
        <div className={classes.contItem}>
          <img
            src={appInfo.firstButtons.phone}
            alt="phone"
            className={classes.img}
          />
          {/* <Typography variant="h4">{clinic?.name}</Typography>
          <Typography
            align="center"
            variant="subtitle2"
            style={{
              marginBottom: 68,
            }}
          >
            {clinic?.address}
          </Typography> */}
          <Typography variant="h1">
            <Link
              href={`tel:${phoneNumber}`}
              style={{ textDecoration: 'none' }}
            >
              {phoneNumber}
            </Link>
          </Typography>
        </div>
      </div>
    </Modale>
  );
}
