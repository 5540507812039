import React from 'react';
import CalypsoForm from './CalypsoForm';
import { CalypsoProvider } from '../../context/CalypsoContext';

const index: React.FC = () => {
  return (
    <CalypsoProvider>
      <CalypsoForm />
    </CalypsoProvider>
  );
};

export default index;
