import React, { useState } from 'react';

import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function SearchForm({
  handleChange,
  value,
  disabled = false,
  label,
  margin = 'normal',
}) {
  const [searchVal, setSearchVal] = useState(value);

  return (
    <FormControl
      variant="outlined"
      margin={margin}
      fullWidth
      disabled={disabled}
    >
      <InputLabel htmlFor="outlined-adornment-password">
        {label}
      </InputLabel>
      <OutlinedInput
        autoComplete="off"
        variant="outlined"
        value={searchVal}
        disabled={disabled}
        onChange={(e) => {
          setSearchVal(e.currentTarget.value);
        }}
        label={label}
        type="text"
        name="filter-value"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleChange(searchVal);
          }
        }}
        endAdornment={
          <InputAdornment
            position="end"
            style={{ position: 'relative', left: 16 }}
            disableTypography={disabled}
          >
            {searchVal && (
              <IconButton
                onClick={() => {
                  setSearchVal('');
                  handleChange('');
                }}
                aria-label="clear"
              >
                <CloseIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleChange(searchVal)}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
