import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInvoicesState } from '../../context/InvoicesContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import config from '../../config';
//import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
      position: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      width: 224,
      position: 'fixed',
    },

    [theme.breakpoints.up('lg')]: {
      width: 282,
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-flexContainerVertical > .MuiTab-root': {
      textTransform: 'none',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    '& .MuiTabs-flexContainerVertical > .MuiTab-root > .MuiTab-wrapper':
      {
        alignItems: 'flex-start',
      },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.hero,
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
}));

export default function InvoicesFilter() {
  const classes = useStyles();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const {
    state: {
      paymentStatus,
      invoices: { isLoaded },
      local,
      /**dateRange */
    },
    setState,
  } = useInvoicesState();
  const { t } = useTranslation();
  const { invoiceId } = useParams();
  const navigate = useNavigate();

  const handlePaymentStatus = (event, newValue) => {
    setState({
      type: 'SET_FILTER',
      payload: {
        paymentStatus: newValue,
      },
    });
    if (invoiceId != null) navigate('/app/invoices');
  };
  const handleLocal = useCallback(
    (e, value) => {
      setState({
        type: 'SET_FILTER',
        payload: { local: value },
      });
    },
    [local],
  );

  return (
    <Paper className={classes.conteiner}>
      <Tabs
        orientation="vertical"
        value={invoiceId == null ? paymentStatus : false}
        onChange={handlePaymentStatus}
        aria-label="filter-tabs"
        className={classes.tabs}
        textColor="primary"
      >
        <Tab value="Unpaid" label={t('INVOICES.FILTER_UNPAID')} />
        <Tab value="Paid" label={t('INVOICES.FILTER_PAID')} />
        <Tab value="All" label={t('INVOICES.FILTER_ALL')} />
      </Tabs>
      {
        <Box mx={2} pb={2}>
          {config.theme === 'mobimed' && (
            <FormControlLabel
              control={
                <Switch
                  onChange={handleLocal}
                  checked={local}
                  color="primary"
                  disabled={!isLoaded}
                />
              }
              label={<Typography variant="h6">local data</Typography>}
            />
          )}
        </Box>
      }
    </Paper>
  );
}
