import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import { Rating } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  borderRadiusRight: {
    borderRadius: '0 6px 6px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  borderRadiusLeft: {
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  ifMobileThenHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  item: {
    backgroundColor: theme.palette.bgLight.one,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const RatingReder = ({ rating }) => (
  <Rating
    value={rating ?? null}
    size="small"
    //emptyIcon={<StarBorderIcon fontSize="inherit" />}
    readOnly
  />
);

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function ReviewItem({ item, lang }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      mb={2}
      mr={2}
      className={classes.item}
    >
      <Grid size={{ xs: 12, sm: 'grow' }}>
        <RatingReder rating={item.rating} />
        <Typography variant="body1">{item.text}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 3 }}>
        <Typography align="right" variant="subtitle2">
          {HL7ToDateToSrtDate(item.date, lang)}
        </Typography>
      </Grid>
    </Grid>
  );
}
