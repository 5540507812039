import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
  buttonSearch: {
    margin: 0,
  },
  buttonClear: {
    margin: 0,
  },
  iconsConteiner: {
    '&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
      paddingRight: 9,
    },
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function AutocompleteSpecOrDocName({
  specOrName,
  handleSpecOrNameChange,
  disabled = false,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: { specs },
  } = useUserStateDispatch();
  const handleChange = (e, val) => {
    const value = val != null && val?.name != null ? val?.name : val;
    //console.log('value===', value);
    handleSpecOrNameChange(value);
  };
  const options = specs || [];

  const selectedSpecName = options.find(
    (item) => item.name === specOrName,
  );

  const value =
    selectedSpecName != null
      ? selectedSpecName
      : { name: specOrName };
  //console.log('value===', value);
  return (
    !isEmpty(specs) && (
      <Autocomplete
        //id="checkboxes-tags-demo"
        //forcePopupIcon={true}

        disabled={disabled}
        freeSolo
        value={value}
        defaultValue={null}
        options={options}
        onChange={handleChange}
        getOptionLabel={(option) =>
          option?.name != null ? option.name : ''
        }
        //isOptionEqualToValue={(option) => option.name === value}
        renderOption={(props, option) => (
          <Box {...props}>
            <Typography variant="body1">{option.name}</Typography>
          </Box>
        )}
        noOptionsText={t('EMPTY_DATA.NO_OPTIONS')}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            label={t('COMPONENT.SPEC_OR_DOCNAME')}
            fullWidth
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment
                  position="end"
                  disableTypography={disabled}
                >
                  {specOrName && (
                    <IconButton
                      className={classes.buttonClear}
                      onClick={() => handleSpecOrNameChange('')}
                      aria-label="clear"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <IconButton
                    className={classes.buttonSearch}
                    onClick={(e) => {
                      console.log('params.InputProps', params);
                      if (params.inputProps?.value != null)
                        handleChange(e, params.inputProps?.value);
                    }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        className={classes.iconsConteiner}
      />
    )
  );
}
