import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField as Input,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import isEmpty from '../../helpers';
import ButtonEsiaLink from '../../pages/sign_in_up/ButtonEsiaLink';
import { isValidPhone } from '../../helpers/validators';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 54,
    textTransform: 'none',
    width: '100%',
  },

  passwordError: {
    color: theme.palette.warning.error,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function SignInForm({
  values,
  errors,

  handleChange,
  handleSubmit,

  valsTab,
  setActiveTabId,
  appInfo,
  handlePhoneChange,
  hasButtonGoSignUp = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const loginLabel = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('COMPONENT.FORM_EMAIL');
      }
      case 'phone': {
        return t('COMPONENT.FORM_PHONE');
      }
      default: {
        return t('COMPONENT.FORM_EMAIL_OR_PHONE');
      }
    }
  };

  const [visibilePass, setVisibilePass] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid size={12}>
        <Input
          name="login"
          variant="outlined"
          value={values.login || ''}
          onBlur={(e) =>
            valsTab.authIdentifiers !== 'phone' &&
            isValidPhone(e.target.value, appInfo.countryCode) &&
            handlePhoneChange(e)
          }
          onChange={(e) =>
            valsTab.authIdentifiers === 'phone'
              ? handlePhoneChange(e)
              : handleChange(e)
          }
          label={loginLabel()}
          type="text"
          fullWidth
          required
          error={values.login != null && errors?.login != null}
          helperText={
            values.login != null &&
            errors?.login != null &&
            errors?.login
          }
          slotProps={{
            htmlInput: {
              'data-testid': 'login',
              autoComplete: 'off',
            },
          }}
        />
      </Grid>
      <Grid size={12}>
        <FormControl variant="outlined" margin="normal" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">
            {t('COMPONENT.FORM_PASS')}
          </InputLabel>
          <OutlinedInput
            name="password"
            variant="outlined"
            value={values.password || ''}
            onChange={handleChange}
            label={t('COMPONENT.FORM_PASS')}
            type={visibilePass ? 'text' : 'password'}
            required
            error={errors?.password != null}
            onKeyDown={(e) => {
              if (
                errors?.password == null &&
                values.password !== '' &&
                e.key === 'Enter'
              ) {
                handleSubmit();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVisibilePass(!visibilePass)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {!visibilePass ? (
                    <VisibilityOffIcon color="primary" />
                  ) : (
                    <VisibilityIcon color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            inputProps={{
              'data-testid': 'password',
              autoComplete: 'off',
            }}
          />
          <FormHelperText className={classes.passwordError}>
            {errors?.password != null && errors?.password}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid size={12} mt={2}>
        <Button
          disabled={
            values.login == null ||
            values.password == null ||
            !isEmpty(errors)
          }
          onClick={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          variant="contained"
          color="primary"
          className={classes.submitButton}
          data-testid="but-submit"
        >
          {t('SIGN.IN_TITLE')}
        </Button>
      </Grid>

      {appInfo.showEsiaAuth && (
        <Grid size={12} mt={2}>
          <ButtonEsiaLink />
        </Grid>
      )}

      {hasButtonGoSignUp && (
        <Grid size={12} mt={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setActiveTabId(1)}
            style={{ width: '100%' }}
          >
            {t('SIGN.UP_BUTTON')}
          </Button>
        </Grid>
      )}

      <Grid size={{ xs: 12, sm: 6 }} mt={2}>
        <Button
          color="primary"
          component="a"
          onClick={() => setActiveTabId(2)}
          sx={{
            padding: 0,
          }}
        >
          {t('SIGN.CH_PASS_BUTTON')}
        </Button>
      </Grid>

      {appInfo.showQrCodeAuth && (
        <Grid
          size={{ xs: 12, sm: 6 }}
          mt={2}
          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
        >
          <Button
            color="primary"
            component="a"
            onClick={() => setActiveTabId(3)}
            sx={{
              padding: 0,
            }}
          >
            {t('SIGN.IN_BY_QR')}
          </Button>
        </Grid>
      )}
    </>
  );
}
