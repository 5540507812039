import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

export default function InvoicePaymentSuccess() {
  const { t } = useTranslation();
  return (
    <Alert severity="success">
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        {t('INVOICES.SUCCESS')}
      </Typography>
      <Typography variant="body2">
        {t('INVOICES.CLOSE_WIN')}
      </Typography>
    </Alert>
  );
}
