import React, { useMemo } from 'react';
import {
  Action,
  ActionType,
  ContextState,
  State,
} from '../types/sessions.d';

const SessionsContext = React.createContext<ContextState>(
  {} as ContextState,
);

const rootReducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.fetch:
      return {
        ...state,
        sessions: {
          isLoaded: true,
          data: action.payload,
          responseError: null,
        },
      };

    case ActionType.remove:
      return {
        ...state,
        sessions: {
          isLoaded: true,
          data: state.sessions.data.filter(
            (item) => item.jti !== action.payload,
          ),
          responseError: null,
        },
      };

    case ActionType.reset:
      return {
        sessions: {
          isLoaded: false,
          data: [],
          responseError: null,
        },
      };
    case ActionType.error:
      return {
        ...state,
        sessions: {
          ...state.sessions,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

function SessionsProvider({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  const [state, setState] = React.useReducer(rootReducer, {
    sessions: {
      isLoaded: false,
      data: [],
      responseError: null,
    },
  });

  const value = useMemo(() => ({ state, setState }), [state]);

  return (
    <SessionsContext.Provider value={value}>
      {children}
    </SessionsContext.Provider>
  );
}

const useSessionsState = () => {
  const context = React.useContext(SessionsContext);
  return context;
};

export { SessionsProvider, useSessionsState };
