/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
//import Typography from '@mui/material/Typography';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ReactComponent as MedicineIcon } from '../../images/icons/common_medicine.svg';
import { ReactComponent as DevIcon } from '../../images/icons/common_dev.svg';
import { ReactComponent as CRMIcon } from '../../images/icons/common_crm.svg';
import { ReactComponent as GalleryIcon } from '../../images/icons/common_gallery.svg';
import { ReactComponent as EctIcon } from '../../images/icons/common_ect.svg';
import { ReactComponent as DoctorIcon } from '../../images/icons/common_doctor.svg';
import { ReactComponent as PatientIcon } from '../../images/icons/common_patient.svg';
//import { ReactComponent as ClinicBranchIcon } from '../../images/icons/common_clinic_branch.svg';

import { ReactComponent as ArrowIcon } from '../../images/icons/common_arrow.svg';
import classNames from 'classnames';
//import { medicalNets } from '../../actions/user';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import isEmpty from '../../helpers';
//import { useUserStateDispatch } from '../../context/UserContext';

//import { isEmpty } from '../../components/Chat/chat/dist/utils/common';

const useStyles = makeStyles((theme) => {
  return {
    containerButtons: {
      minWidth: 520,
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
    button_cur: {
      cursor: 'pointer',
    },
    button: {
      maxWidth: 520,
      boxShadow: '0px 2px 10px 0px rgba(53, 54, 56, 0.07)',
      borderRadius: theme.spacing(1.5),
      transition: 'all .3s ease-in-out',
      '& h6': {
        transition: 'all .3s ease-in-out',
      },

      '& .MuiTypography-body1': {
        transition: 'all .3s ease-in-out',
      },
      '& .arrow': {
        transition: 'all .3s ease-in-out',
      },
      '& .arrow > path': {
        transition: 'all .3s ease-in-out',
      },

      '&:hover': {
        transition: 'all .3s ease-in-out',
        boxShadow: '0px 2px 10px 0px rgba(53, 54, 56, 0.14)',
        '& h6': {
          transition: 'all .3s ease-in-out',
          color: theme.palette.primary.main,
        },

        '& .MuiTypography-body1': {
          transition: 'all .3s ease-in-out',
          transformOrigin: 'center left',
          transform: 'scaleX(0.95)',
        },
        '& .arrow': {
          transition: 'all .3s ease-in-out',
          transform: 'translate(12px, 0)',
          fill: `${theme.palette.grey[300]}`,
        },
        '& .arrow > path': {
          transition: 'all .3s ease-in-out',
          fill: `${theme.palette.grey[300]}`,
        },
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey[200],
    },
  };
});

function searchRecursive(data, id) {
  let found = data.find((d) => d.id === id);
  if (!found) {
    let i = 0;
    while (!found && i < data.length) {
      if (data[i].sub_menu && data[i].sub_menu.length) {
        found = searchRecursive(data[i].sub_menu, id);
      }
      i++;
    }
  }
  return found;
}
const menuAllLocal = [
  {
    icon: <MedicineIcon />,
    name: 'Medicine',
    sub_title: 'Краткое описание специализации',
    href: '',
    id: '1',
    parent: null,
    sub_menu: [
      {
        icon: <DoctorIcon />,
        name: 'Doctor',
        sub_title: '',
        href: '/#docsign',
        parent: '1',
        id: '1_1',
        // sub_menu: [
        //   {
        //     icon: <ClinicBranchIcon />,
        //     name: 'Clinik Med dep 1',
        //     sub_title: '',
        //     href: '',
        //     parent: '1_1',
        //     id: '1_1_1',
        //   },
        //   {
        //     icon: <ClinicBranchIcon />,
        //     name: 'Clinik Med dep 1',
        //     sub_title: '',
        //     href: '',
        //     parent: '1_1',
        //     id: '1_1_2',
        //   },
        // ],
      },
      {
        icon: <PatientIcon />,
        name: 'Patient',
        sub_title: '',
        href: '/#sign',
        parent: '1',
        id: '1_2',
      },
    ],
  },
  {
    icon: <CRMIcon />,
    name: 'CRM',
    sub_title: 'Краткое описание специализации',
    href: 'https://tt.mediadoc.net/',
    id: '3',
    parent: null,
  },
  {
    icon: <DevIcon />,
    name: 'Develop',
    sub_title: 'Краткое описание специализации',
    href: '',
    id: '2',
    parent: null,
  },

  {
    icon: <GalleryIcon />,
    name: 'Gallery',
    sub_title: 'Краткое описание специализации',
    href: '',
    id: '4',
    parent: null,
  },
  {
    icon: <EctIcon />,
    name: 'Ect',
    sub_title: 'Краткое описание специализации',
    href: '',
    id: '5',
    parent: null,
  },
];

// eslint-disable-next-line react/prop-types
const LinkWrap = ({ href, cildCopm }) => {
  return href ? <Link href={href}>{cildCopm}</Link> : cildCopm;
};
// eslint-disable-next-line react/prop-types
const Logo = ({ svg }) => {
  return <span dangerouslySetInnerHTML={{ __html: svg }} />;
};
// eslint-disable-next-line react/prop-types
const Item = ({ item, handleSetMemu, classes }) => {
  return (
    <Paper
      className={classNames(
        classes.button,
        item.sub_menu && classes.button_cur,
        ['2', '4', '5'].includes(item.id) && classes.disabled,
      )}
      onClick={() => item.sub_menu && handleSetMemu(item.id)}
    >
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        py={2}
        my={3}
      >
        <Grid size={2} textAlign="center">
          {item.icon && item.icon}
          {item.logo && <Logo svg={item.logo} />}
        </Grid>
        <Grid size={8}>
          <Typography variant="h6">{item.name}</Typography>
          {/* <Typography variant="body1">{item.sub_title}</Typography> */}
        </Grid>
        <Grid size={2} textAlign="center">
          <ArrowIcon className="arrow" />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default function Workspace() {
  const classes = useStyles();
  const menuAll = useRef(menuAllLocal);

  const [menu, setMenu] = useState({
    menuAct: menuAll.current,
    parent: undefined,
  });

  const handleSetMemu = (idAct) => {
    const item = menu.menuAct.find((it) => it.id === idAct) || [];

    setMenu({
      menuAct: item?.sub_menu,
      parent: item?.parent,
    });
  };

  const handleSetMemuPrev = () => {
    const item = searchRecursive(menuAll.current, menu.parent);
    setMenu({
      menuAct: item?.sub_menu || menuAll.current,
      parent: item?.parent,
    });
  };

  //console.log('appInfo.startPage', appInfo.startPage);

  return (
    <Grid container mt={3} width="100%">
      <Grid size={{ xs: 12, sm: 2 }} mt={3}>
        {menu.parent !== undefined && (
          <Button
            onClick={handleSetMemuPrev}
            variant="contained"
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            dos
          </Button>
        )}
      </Grid>
      <Grid
        item
        size={{ xs: 12, sm: 8 }}
        justifyContent="center"
        display="flex"
      >
        <Box className={classes.containerButtons}>
          <Typography variant="h1" my={3}>
            Choisisse l&apos;orientation
          </Typography>
          {menu.menuAct &&
            (menu.menuAct || []).map((item) => (
              <LinkWrap
                key={item.id}
                href={item.href}
                cildCopm={
                  <Item
                    item={item}
                    handleSetMemu={handleSetMemu}
                    classes={classes}
                  />
                }
              />
            ))}
        </Box>
      </Grid>
      <Grid size={{ xs: 12, sm: 2 }}></Grid>
    </Grid>
  );
}
