import React, { useMemo } from 'react';
import { withStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';

import Badge from '@mui/material/Badge';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);
const CustomAvatarSmall = withStyles((theme) => ({
  root: {
    width: 80,
    height: 80,
    backgroundColor: '#faf9fd',
    color: theme.palette.secondary.light,
    margin: '0 auto',
  },
}))(Avatar);

const CustomAvatarMenu = withStyles((theme) => ({
  root: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: '#faf9fd',
    color: theme.palette.secondary.light,
    margin: '0 auto',
  },
}))(Avatar);
const CustomAvatarHead = withStyles((theme) => ({
  root: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    backgroundColor: '#faf9fd',
    color: theme.palette.secondary.light,
    margin: '0 auto',
  },
}))(Avatar);

const CustomAvatarMedium = withStyles((theme) => ({
  root: {
    width: theme.spacing(22),
    height: theme.spacing(22),
    backgroundColor: '#faf9fd',
    color: theme.palette.secondary.light,
    margin: '0 auto',
  },
}))(Avatar);

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const RenderSwitch = ({ specName, image, size }) => {
  switch (size) {
    case 'head':
      return <CustomAvatarHead alt={specName} src={image} />;
    case 'menu':
      return <CustomAvatarMenu alt={specName} src={image} />;
    case 'medium':
      return <CustomAvatarMedium alt={specName} src={image} />;
    default:
      return <CustomAvatarSmall alt={specName} src={image} />;
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function AvatarOnOffLine({
  specName,
  image,
  size = 'small',
  isOnline = false,
}) {
  return useMemo(
    () =>
      isOnline ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="dot"
        >
          <RenderSwitch
            size={size}
            specName={specName}
            image={image}
          />
        </StyledBadge>
      ) : (
        <RenderSwitch size={size} specName={specName} image={image} />
      ),
    [specName, image, size, isOnline],
  );
}
