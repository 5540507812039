import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLanguageValue } from '../../context/LanguageContext';
import { DatePicker } from '@mui/x-date-pickers';

interface MuiUIPickerProps {
  value: Dayjs | null;
  handleDateChange: (value: Dayjs | null, name?: string) => void;
  label: string;
  inputFormat: string;
  disablePast?: boolean;
  disabled?: boolean;
  required?: boolean;
  errorText?: string | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  preventKeyDown?: boolean;
  name: string;
  variant: 'outlined' | 'filled' | 'standard';
  views?: Array<'day' | 'month' | 'year'>;
  view?: 'day' | 'month' | 'year';
}

const MuiUIPicker: FC<MuiUIPickerProps> = ({
  value,
  handleDateChange,
  label,

  disablePast,
  disabled,
  minDate,
  maxDate,
  errorText,
  required,
  margin = 'normal',
  preventKeyDown = false,
  name = 'date-picker',
  variant = 'outlined',
  views,
  view,
}) => {
  const {
    languageState: { language },
  } = useLanguageValue();

  const onChange = (val: Dayjs | null) => handleDateChange(val, name);
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={language}
    >
      <DatePicker
        disablePast={disablePast}
        disabled={disabled}
        label={label}
        value={value}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            margin,
            variant,
            onKeyDown: (e) => {
              if (preventKeyDown) e.preventDefault();
            },
            error: errorText != null,
            helperText: errorText != null && errorText,
            required,
            fullWidth: true,
            inputProps: { testid: name },
          },
          switchViewButton:
            view === 'day' ? { sx: { display: 'none' } } : undefined,
          calendarHeader:
            view === 'day'
              ? {
                  sx: {
                    '.MuiPickersCalendarHeader-label': {
                      cursor: 'default',
                    },
                  },
                }
              : undefined,
        }}
        name={name}
        views={views}
        view={view}
      />
    </LocalizationProvider>
  );
};

export default MuiUIPicker;
