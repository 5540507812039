import { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';

export async function getPrescribedDrugs(
  dispatch,
  patientDirectionId,
  mmkId = 'parent',
  clinicId = 0,
) {
  const body = {
    patientDirectionId,
    mmkId,
    clinicId,
  };
  try {
    const { data } = await httpClient.get(
      api.url('GetPatientPrescribedDrugs'),
      body,
    );
    await dispatch({
      type: 'FETCH_PRESCRIBEDDRUGS',
      payload: {
        data: ifEmptyArr(data),
      },
    });
  } catch (err) {
    console.log(
      '--------- err GetPatientPrescribedDrugs--------- \n',
      err,
    );
    await dispatch({
      type: 'FETCH_PRESCRIBEDDRUGS_ERROR',
      payload: getError(err),
    });
  }
}

export async function changePatientPrescribedDrugState(
  dispatch,
  drugIntakeId,
  state = 'Done',
  mmkId = 'parent',
  clinicId = 0,
) {
  const body = {
    drugIntakeId,
    state,
    mmkId,
    clinicId,
  };
  await dispatch({
    type: 'CHANGE_STATE_SUBMITED',
  });

  await httpClient
    .post(api.url('ChangePatientPrescribedDrugState'), body)
    .then(async ({ data }) => {
      console.log(
        '--------- request ChangePatientPrescribedDrugState--------- \n',
        body,
        '--------- response ChangePatientPrescribedDrugState--------- \n',
        data,
      );

      await dispatch({
        type: 'SET_PRESCRIBEDDRUGS_STATE',
        payload: {
          data,
        },
      });
    })
    .catch(async (err) => {
      console.log(
        '--------- err ChangePatientPrescribedDrugState--------- \n',
        err,
      );
      await dispatch({
        type: 'FETCH_PRESCRIBEDDRUGS_ERROR',
        payload: getError(err),
      });
    });
}
