import React from 'react';
import { Button, TextField as Input } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import isEmpty from '../../helpers';
import ButtonEsiaLink from '../../pages/sign_in_up/ButtonEsiaLink';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 54,
    textTransform: 'none',
    width: '100%',
  },

  passwordError: {
    color: theme.palette.warning.error,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function SignInOtpForm({
  values,
  errors,
  setActiveTabId,
  appInfo,
  handlePhoneChange,
  handleSubmit,
  hasButtonGoSignUp = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid size={12}>
        <Input
          name="login"
          variant="outlined"
          value={values.login || ''}
          onChange={handlePhoneChange}
          margin="normal"
          label={t('COMPONENT.FORM_PHONE')}
          type="text"
          fullWidth
          error={errors?.login != null}
          helperText={errors?.login != null && errors?.login}
        />
      </Grid>

      <Grid size={12} mt={2}>
        <Button
          disabled={values.login == null || !isEmpty(errors)}
          onClick={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          variant="contained"
          color="primary"
          className={classes.submitButton}
          data-testid="but-submit"
        >
          {t('SIGN.IN_TITLE')}
        </Button>
      </Grid>

      {appInfo.showEsiaAuth && (
        <Grid size={12} mt={2}>
          <ButtonEsiaLink />
        </Grid>
      )}

      {hasButtonGoSignUp && (
        <Grid size={12} mt={2}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setActiveTabId(1)}
            style={{ width: '100%' }}
          >
            {t('SIGN.UP_BUTTON')}
          </Button>
        </Grid>
      )}

      <Grid size={{ xs: 12, sm: 6 }} mt={2}>
        <Button
          color="primary"
          component="a"
          onClick={() => setActiveTabId(2)}
          sx={{
            padding: 0,
          }}
        >
          {t('SIGN.CH_PASS_BUTTON')}
        </Button>
      </Grid>

      {appInfo.showQrCodeAuth && (
        <Grid
          size={{ xs: 12, sm: 6 }}
          mt={2}
          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
        >
          <Button
            color="primary"
            component="a"
            onClick={() => setActiveTabId(3)}
            sx={{
              padding: 0,
            }}
          >
            {t('SIGN.IN_BY_QR')}
          </Button>
        </Grid>
      )}
    </>
  );
}
