import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import logo from '../../images/logo_doctor.svg';
import UsersMenu from './UsersMenuOper';

import { useUserStateDispatch } from '../../context/UserContext';

const styles = (theme) => ({
  top: {
    width: '100%',
    height: 48,
    backgroundColor: '#FFFFFF',
    position: 'fixed',
    top: 0,
    left: 0,
    textAlign: 'center',
    zIndex: theme.zIndex.drawer + 1,
  },
  topContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '12px',
    display: 'flex',
    overflow: 'hide',
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
  },

  logotypeImage: {
    width: 154,
    marginLeft: '16px',
  },
});

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Header = ({ classes }) => {
  const { t } = useTranslation();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  return (
    <div className={classes.top}>
      <div className={classes.topContainer}>
        <div>
          <img
            src={logo}
            alt={t('SIGN.IN_HEAD', {
              defTitle: appInfo.defTitle,
            })}
            className={classes.logotypeImage}
          />
        </div>

        <UsersMenu />
      </div>
    </div>
  );
};

export default withStyles(styles)(Header);
