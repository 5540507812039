import React from 'react';
import Modale from '../../components/Modals/Modale';
import DoctorInfo from './DoctorInfo';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function ModaleDoctorInfo({
  modalDoc,
  toggleModalCreateVisit,
  setModalDoc,
}) {
  return (
    <Modale
      open={modalDoc}
      toggleModal={() => setModalDoc(!modalDoc)}
      // title={`${visitData?.doctor.lastName} ${visitData?.doctor.firstName} ${visitData?.doctor.middleName ?? ''}`}
      height={780}
    >
      <DoctorInfo
        modalDoc={modalDoc}
        toggleModalCreateVisit={toggleModalCreateVisit}
        setModalDoc={setModalDoc}
      />
    </Modale>
  );
}
