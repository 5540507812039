import React, { useState, MouseEvent } from 'react';
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  ListItemIcon,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import FilterIcon from '@mui/icons-material/FilterListOutlined';
import { styled, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import isEmpty from '../../helpers';

const useStyles = makeStyles((theme: Theme) => ({
  stack: {
    minWidth: 280,
    width: '100%',
  },

  buttonEmpty: {
    background: theme.palette.grey[200],

    boxShadow: 'none !important',
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: '#fff',
      },
    },
  },
  gap: {
    marginLeft: theme.spacing(2),
  },
}));
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 240,

    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '& .MuiSvgIcon-root': {
          color: '#fff',
        },
        '& .MuiTypography-root': {
          color: '#fff',
        },
      },

      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1.5),
      },
      '& .MuiTypography-root': {
        fontSize: 15,
      },
    },
  },
}));

type option = {
  name: string;
  description: string;
};

interface SearchFormAndGroupsProps {
  handleChange: (param: string) => void;
  value: string;
  disabled?: boolean | undefined;
  label: string;
  options: option[];
  checkedOptions: string[];
  handleChangeOptions: (param: string) => void;
}

const SearchFormAndGroups = ({
  handleChange,
  value,
  disabled = false,
  label,
  options,
  checkedOptions,
  handleChangeOptions,
}: SearchFormAndGroupsProps) => {
  const classes = useStyles();
  const [searchVal, setSearchVal] = useState(value);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseChangeOptions = (value: string) => {
    handleChangeOptions(value);
    setAnchorEl(null);
  };
  //console.log('checkedOptions', checkedOptions);
  return (
    <Stack direction="row" spacing={1} className={classes.stack}>
      <FormControl variant="outlined" fullWidth disabled={disabled}>
        <InputLabel htmlFor="outlined-adornment-password">
          {label}
        </InputLabel>
        <OutlinedInput
          autoComplete="off"
          value={searchVal}
          disabled={disabled}
          onChange={(e) => {
            setSearchVal(e.currentTarget.value);
          }}
          label={label}
          type="text"
          name="filter-value"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleChange(searchVal);
            }
          }}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ position: 'relative', left: 16 }}
              disableTypography={disabled}
            >
              {searchVal && (
                <IconButton
                  onClick={() => {
                    setSearchVal('');
                    handleChange('');
                  }}
                  aria-label="clear"
                >
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => handleChange(searchVal)}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleClick}
        disabled={disabled}
        className={isEmpty(checkedOptions) ? classes.buttonEmpty : ''}
      >
        <FilterIcon />
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((item) => (
          <MenuItem
            onClick={() => handleCloseChangeOptions(item.name)}
            key={item.name}
          >
            {!isEmpty(checkedOptions) && (
              <ListItemIcon>
                {checkedOptions.includes(item.name) && <CheckIcon />}
              </ListItemIcon>
            )}
            <Typography
              variant={
                !isEmpty(checkedOptions) &&
                checkedOptions.includes(item.name)
                  ? 'h6'
                  : 'body2'
              }
              noWrap
            >
              {item.description}
            </Typography>
          </MenuItem>
        ))}
      </StyledMenu>
    </Stack>
  );
};

export default SearchFormAndGroups;
