import React from 'react';

import VisitChat from '../../components/Chat';
import Modale from '../../components/Modals/Modale';
import { Alert as AlertBase, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function VisitModalChatConf({
  chatInfo,
  lang,
  modalChatVisit,
  toggleModalChatVisit,
}) {
  const { t } = useTranslation();

  return (
    <Modale
      open={modalChatVisit}
      toggleModal={toggleModalChatVisit}
      title={`${t('EVENTS_VISITS.TITLE_ONLINE_CONF')} `}
      mx={0}
      maxWidth="lg"
      height={700}
      backdrop={true}
      isScrollHide={true}
    >
      {chatInfo?.doctorUserId != null ? (
        <VisitChat
          lang={lang}
          activeChatUserId={chatInfo?.doctorUserId}
          inModale={true}
        />
      ) : (
        <AlertBase severity="error">
          <Typography variant="body2">
            chatInfo?.doctorUserId - undefined
          </Typography>
        </AlertBase>
      )}
    </Modale>
  );
}
export default VisitModalChatConf;
