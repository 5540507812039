import React, { useMemo, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import { LeftPlace } from '../../components/Wrappers/Wrappers';
import DoctorInfoUserAvatarCard from './DoctorInfoUserAvatarCard';
import { Button, MenuItem, MenuList } from '@mui/material';
import DoctorRatingsReviews from './DoctorRatingsReviews';
import DoctorRatingSet from './DoctorRatingSet';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  marginTop: { marginTop: theme.spacing(2) },
  content: {
    height: '100%',
    paddingBottom: theme.spacing(4),
  },
  leftConent: {
    textAlign: 'center',
    justifySelf: 'center',
    minWidth: 322,
    paddingBottom: theme.spacing(1),
    boxSizing: 'border-box',
  },
  rightConent: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',

    minWidth: 494,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },

    [theme.breakpoints.up('md')]: {
      height: 710,
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme.palette.primary.hero} #fff`,
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#fff',
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 8,
        backgroundColor: theme.palette.primary.lighter,
        border: '5px solid #fff',
      },
      '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
        {
          backgroundColor: '#fff',
        },
      '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
        {
          backgroundColor: theme.palette.primary.lighter,
          border: '3px solid #fff',
        },
      '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
        {
          backgroundColor: theme.palette.primary.lighter,
          border: '3px solid #fff',
        },
      '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#fff',
      },
    },
  },

  leftPlace: {
    alignSelf: 'center',

    '& .MuiPickerStaticWrapper-content': {
      backgroundColor: 'transparent !important',
    },
  },
  item: {
    color: `${theme.palette.sideBar.colorLeft} !important`,
    '&:hover, &:focus': {
      color: `${theme.palette.sideBar.colorHover} !important`,
      backgroundColor: 'transparent',
    },
  },
  activeItem: {
    color: `${theme.palette.sideBar.colorActive} !important`,
  },

  menuItem: {
    borderBottom: `1px solid ${theme.palette.bgLight.one}`,
    padding: 0,
    '&:last-child': {
      borderBottom: 0,
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuButton: {
    justifyContent: 'start',
    padding: `${theme.spacing(1.6)} ${theme.spacing(1)} !important`,
    width: '100%',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },

  conteiner: {
    margin: theme.spacing(1),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorRating({ setModalRaring }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const menu = [
    { id: 1, name: t('VISIT.REVIEWS_HEADER') },
    { id: 2, name: t('VISIT.REVIEW_SET') },
  ];
  const [value, setValue] = React.useState(1);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const isActiveClassName = (id) => {
    return id === value ? classes.activeItem : classes.item;
  };

  const scrollDiv = useRef(null);

  return useMemo(
    () => (
      <Grid
        container
        spacing={0}
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.content}
      >
        <Grid size={{ sm: 12, md: 4 }} className={classes.leftConent}>
          <LeftPlace className={classes.leftPlace}>
            <DoctorInfoUserAvatarCard />
            <MenuList className={classes.conteiner}>
              {menu.map((item) => (
                <MenuItem
                  key={item.id}
                  className={classes.menuItem}
                  onClick={() => handleChange(item.id)}
                >
                  <Button
                    className={classNames(
                      classes.menuButton,
                      isActiveClassName(item.id),
                    )}
                  >
                    {item.name}
                  </Button>
                </MenuItem>
              ))}
            </MenuList>
          </LeftPlace>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 'grow' }}
          className={classes.rightConent}
          ref={scrollDiv}
        >
          {value === 1 && (
            <DoctorRatingsReviews scrollDiv={scrollDiv} />
          )}
          {value === 2 && (
            <DoctorRatingSet setModalRaring={setModalRaring} />
          )}
        </Grid>
      </Grid>
    ),
    [value],
  );
}
