import { Dispatch } from 'react';

// export type CalypsoAuthIn = {
//   formUUID: string;
//   clinicId?: number;
//   mmkId?: string;
// };
export const defformUUID = 'c8e4b68b-43c4-4dde-aaf1-704ce9275d3f';

export type Calypso = {
  formUUID: string;
  title: string;
  icon: string;
  control: string;
  position: number;
  address?: string;
  accessToken?: string;
  refreshToken?: string;
};

export type State = {
  calypso: {
    isLoaded: boolean;
    data: Calypso | null;
    responseError: string | null;
    formUUID?: string;
  };
};

export enum ActionType {
  fetch = 'FETCH_CALYPSO',
  setFormUUID = 'SET_DEF_FORM_ID',
  reset = 'REST_CALYPSO',
  error = 'FETCH_CALYPSO_ERROR',
}

export type ActionStringPayload = {
  type: ActionType.error | ActionType.setFormUUID;
  payload: string;
};

export type ActionObjectPayload = {
  type: ActionType.fetch | ActionType.reset;
  payload: Calypso;
};

export type Action = ActionStringPayload | ActionObjectPayload;

export type ContextState = {
  state: State;
  setState: Dispatch<Action>;
};
