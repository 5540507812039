import React from 'react';

import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles } from '@mui/styles';

import { useTranslation } from 'react-i18next';
import { useVisitState } from '../../../context/VisitContext';

import { useUserStateDispatch } from '../../../context/UserContext';

const useStyles = makeStyles(() => ({
  switchFilter: {
    position: 'absolute',
    top: 26,
    left: 8,
  },
  wrapButtonGroup: {
    position: 'relative',
    top: -16,
    left: 0,
  },
  buttonGroupCastom: {
    position: 'absolute',
    top: 25,
    left: 8,
    '& .MuiToggleButton-root': {
      //border: `1px solid ${theme.palette.secondary.dark}`,
      width: 142,
      height: 38,

      fontSize: 14,
      '& .MuiToggleButton-label > .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  },

  inputSpecName: {
    '& .MuiInputLabel-outlined ': {
      transform: 'translate(308px, 18px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -10px) scale(0.75)',
    },
    '& .MuiInputBase-root,& .MuiInputBase-input': {
      paddingLeft: 154,
    },
  },
  inputSpecNameMob: {
    '& .MuiInputLabel-outlined ': {
      transform: 'translate(120px, 20px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputBase-root,& .MuiInputBase-input': {
      paddingLeft: 60,
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function SpecFilter({ handleSearch }) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const {
    state: { isOnline },
    setState,
  } = useVisitState();

  const {
    userState: {
      isAuthenticated,
      user: { isAnonymous },
      appInfo,
    },
  } = useUserStateDispatch();

  const { t } = useTranslation();

  const handleIsOnline = async () => {
    const newIsOnline = !isOnline;
    await setState({
      type: 'SET_IS_ONLINE',
      payload: {
        isOnline: newIsOnline,
      },
    });
    setState({
      type: 'SET_SELECTED_VISITKIND_ID',
      payload: { selectedVisitKindId: null },
    });
  };
  const onLine =
    appInfo.showFilterOnline && isAuthenticated && !isAnonymous;
  return (
    <div className={classes.wrapButtonGroup}>
      <TextField
        margin="normal"
        label={t('COMPONENT.SPECS')}
        fullWidth
        variant="outlined"
        className={
          onLine
            ? isMobile
              ? classes.inputSpecNameMob
              : classes.inputSpecName
            : null
        }
        onChange={handleSearch}
      />

      {onLine &&
        (isMobile ? (
          <FormControlLabel
            className={classes.switchFilter}
            control={
              <Switch
                checked={isOnline}
                onChange={handleIsOnline}
                value="checkedB"
                color="primary"
              />
            }
            label={
              <Typography
                color={isOnline ? 'textPrimary' : 'textSecondary'}
                variant="body2"
              >
                {t('VISIT.ONLINE')}
              </Typography>
            }
          />
        ) : (
          <ToggleButtonGroup
            value={isOnline}
            exclusive
            onChange={handleIsOnline}
            aria-label="filter-buttonGroup"
            className={classes.buttonGroupCastom}
          >
            <ToggleButton value={false} aria-label="offLine">
              <Typography>{t('VISIT.INCLINIC')}</Typography>
            </ToggleButton>
            <ToggleButton value={true} aria-label="onLine">
              <Typography>{t('VISIT.ONLINE')}</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        ))}
    </div>
  );
}
