import React, { Fragment, useEffect, useState } from 'react';
//import { makeStyles } from '@mui/styles';
import { Alert, Typography, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Loading from '../Loading';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { saveNotificationSettings } from '../../actions/user';
import isEmpty from '../../helpers';
import ShowResponse from '../FormComponents/ShowResponse';

// const useStyles = makeStyles((theme) => ({
//   buttonSubmit: {
//     margin: `${theme.spacing(2)} auto`,
//     height: theme.spacing(6),
//     maxWidth: theme.spacing(44),
//   },
//   errorMessage: {
//     //textAlign: 'center',
//     marginBottom: theme.spacing(4),
//     width: '100%',
//   },
// }));

function NotificationsSettingsForm() {
  //const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: {
      user: { mmkId, clinicId },
      notificationSettings,
      appInfo,
      serverResponse,
      isLoaded,
    },
    userDispatch,
  } = useUserStateDispatch();

  const [values, setValues] = useState({});

  useEffect(() => {
    //setValues(notificationSettings);
    const vals = {};
    notificationSettings.forEach((el) => {
      if (
        appInfo?.sendEmailEnabled &&
        el.notificationType === 'Email'
      ) {
        vals[`${el.recordType}__${el.notificationType}`] = el.enabled;
      }
      if (appInfo?.sendSMSEnabled && el.notificationType === 'Sms') {
        vals[`${el.recordType}__${el.notificationType}`] = el.enabled;
      }
      setValues(vals);
    });

    // eslint-disable-next-line
  }, [notificationSettings]);

  useEffect(() => {
    if (serverResponse != null) {
      setTimeout(() => {
        userDispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: null,
        });
      }, 3500);
    }

    // eslint-disable-next-line
  }, [serverResponse]);

  const handleChange = (event) => {
    const vals = {
      ...values,
      [event.target.name]: event.target.checked,
    };
    setValues(vals);

    const settings = [];

    Object.keys(vals).forEach((el) => {
      const [recordType, notificationType] = el.split('__');
      // console.log(
      //   'recordType, notificationType',
      //   recordType,
      //   notificationType,
      // );
      settings.push({
        recordType,
        notificationType,
        enabled: vals[el],
      });
    });

    saveNotificationSettings({
      dispatch: userDispatch,
      mmkId,
      clinicId,
      settings,
    });
  };

  //console.log('---', values, '---', values);
  let recordTypeOld = '';
  return (
    <Grid container spacing={3}>
      {!isLoaded && <Loading />}
      {isLoaded && isEmpty(notificationSettings) && (
        <Grid size={12}>
          <Alert severity="error">
            <Typography variant="h6" component="p">
              {t('NOTIFY_SETTINGS.IS_EMPTY')}
            </Typography>
          </Alert>
        </Grid>
      )}
      {isLoaded && (
        <Grid size={12}>
          <ShowResponse
            serverResponse={serverResponse}
            okKey="NOTIFY_SETTINGS.SAVE_OK"
          />
        </Grid>
      )}

      {isLoaded &&
        !isEmpty(values) &&
        Object.keys(values).map((el) => {
          const [recordType, notificationType] = el.split('__');

          const item = (
            <Fragment key={el}>
              {recordType != recordTypeOld && (
                <Grid size={12}>
                  <Typography variant="subtitle1">
                    {t(`NOTIFY_SETTINGS.${recordType}`)}
                  </Typography>
                </Grid>
              )}
              <Grid size={3}>
                <Typography variant="body2">
                  {t(`NOTIFY_SETTINGS.${notificationType}`)}
                </Typography>
              </Grid>
              <Grid size={9} textAlign="right">
                <Switch
                  name={el}
                  checked={values[el]}
                  onChange={handleChange}
                />
              </Grid>
            </Fragment>
          );
          recordTypeOld = recordType;
          return item;
        })}
    </Grid>
  );
}

export default NotificationsSettingsForm;
