import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFaqState } from '../../context/FaqContext';

import Typography from '@mui/material/Typography';
import Loading from '../../components/Loading';
import { getFaq } from '../../actions/faq';
import isEmpty, { groupBy } from '../../helpers';
import { Alert } from '@mui/material';
import config from '../../config';
import FaqCompItem from './FaqCompItem';

const FaqComp: React.FC = () => {
  const { t } = useTranslation();

  const {
    state: { faq },
    setState,
  } = useFaqState();

  useEffect(() => {
    getFaq(setState);
  }, []);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const groups = groupBy(faq.data, 'questionGroup');
  const transformImageUri = (src: string): string => {
    return `${config.baseUrl}${src}/?appCode=${config.APP_CODE}`;
  };
  const items =
    !isEmpty(faq.data) &&
    Object.keys(groups).map((groupName: string) => {
      const keyy = `panel${groupName}`;

      const group = groups[groupName];

      return (
        <FaqCompItem
          key={keyy}
          expanded={expanded}
          groupName={groupName}
          keyy={keyy}
          handleChange={handleChange}
          transformImageUri={transformImageUri}
          group={group}
        />
      );
    });

  return (
    <>
      {!faq.isLoaded ? (
        <Loading />
      ) : faq.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {faq.responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(faq.data) ? (
        items
      ) : (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('FAQ.NO_FAQ_RECORDS')}
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default FaqComp;
