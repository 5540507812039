import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

export default function useIsMobile(breakpoint, height) {
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down(breakpoint),
  );
  const hasWindow = typeof window !== 'undefined';

  function getWindowHeight() {
    return hasWindow ? window.innerHeight : null;
  }

  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }
    if (hasWindow) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);
  return isMobile || windowHeight < height;
}
