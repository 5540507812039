import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePrescribedDrugsState } from '../../context/PrescribedDrugsContext';
import { useUserStateDispatch } from '../../context/UserContext';
import Typography from '@mui/material/Typography';
import { Alert as AlterBase } from '@mui/material';
import PrescribedDrugsItem from './PrescribedDrugsItem';
import isEmpty, { isNumeric } from '../../helpers';
import { useTranslation } from 'react-i18next';
import {
  getPrescribedDrugs,
  changePatientPrescribedDrugState,
} from '../../actions/prescribedDrugs';

import Confirm from '../../components/Modals/ConfirmDialogSlide';
import Alert from '../../components/Modals/AlertDialogSlide';

//import data from './data';

import dayjs from 'dayjs';
import { hl7ToUtc } from '../../helpers/dateFormat';
import Loading from '../../components/Loading';

export default function PrescribedDrugs() {
  const { t } = useTranslation();
  const {
    state: { prescribedDrugs },
    setState,
  } = usePrescribedDrugsState();
  const { patientDirectionId, paramClinicId, filterPrescribedDrug } =
    useParams();
  const {
    userState: {
      user: { mmkId, clinicId, lang },
      clinics,
    },
  } = useUserStateDispatch();

  // const getPrescribedDrugs = async () => {
  //   await setState({
  //     type: 'FETCH_PRESCRIBEDDRUGS',
  //     payload: {
  //       isLoaded: true,
  //       data,
  //     },
  //   });
  // };

  useEffect(() => {
    setState({
      type: 'REST_PRESCRIBEDDRUGS',
    });
    getPrescribedDrugs(
      setState,
      patientDirectionId != null && isNumeric(patientDirectionId)
        ? patientDirectionId
        : 0,
      mmkId,
      paramClinicId != null ? paramClinicId : clinicId,
    );
  }, [mmkId, clinicId, patientDirectionId, paramClinicId]);

  const compareFilterByDate = (inTime, filter) => {
    const today = dayjs();
    const time = hl7ToUtc(inTime);
    if (filter === '') return true;
    if (filter === 'Current' && dayjs(time).isSame(today, 'day')) {
      return true;
    }
    if (filter === 'Past' && dayjs(time).isBefore(today, 'day')) {
      return true;
    }
    if (filter === 'Future' && dayjs(time).isAfter(today, 'day')) {
      return true;
    }
    return false;
  };

  const filtered = (data) => {
    if (
      filterPrescribedDrug === '' ||
      filterPrescribedDrug == null ||
      patientDirectionId != null
    )
      //Все
      return data;
    const currentItems = data.filter((item) =>
      item.drugIntakes.some((it) =>
        compareFilterByDate(it.dateTime, 'Current'),
      ),
    );
    if (filterPrescribedDrug === 'Current') return currentItems;
    let currentItemsIntakesIds = [];

    currentItems.forEach((item) => {
      currentItemsIntakesIds.push(
        ...item.drugIntakes.map((it) => it.id),
      );
    });

    return data.filter((item) =>
      item.drugIntakes.some(
        (it) =>
          !currentItemsIntakesIds.includes(it.id) &&
          compareFilterByDate(it.dateTime, filterPrescribedDrug),
      ),
    );
  };

  // console.log('filtered', filtered(prescribedDrugs.data));

  const items = !isEmpty(prescribedDrugs.data)
    ? filtered(prescribedDrugs.data).map((item, inx) => {
        return (
          <React.Fragment key={item.patientDirectionId}>
            <PrescribedDrugsItem
              prescribedDrug={item}
              inx={inx}
              clinics={clinics}
              lang={lang}
              filterPrescribedDrug={filterPrescribedDrug}
              setState={setState}
            />
          </React.Fragment>
        );
      })
    : null;

  //return <div />;
  return (
    <>
      {/* ---------- prescribedDrugs modals ------------ */}
      {prescribedDrugs.drugIntake.isConfirm && (
        <Confirm
          isOpen={true}
          setState={setState}
          typeReset="REST_ALERT_PRESCRIBEDDRUGS"
          contentText={
            prescribedDrugs.drugIntake.state === 'Active'
              ? t('DRUGS.CONFIRM_CHANGE_STATE_ACTIVE')
              : t('DRUGS.CONFIRM_CHANGE_STATE_DONE')
          }
          callback={() =>
            changePatientPrescribedDrugState(
              setState,
              prescribedDrugs.drugIntake.id,
              prescribedDrugs.drugIntake.state,
              mmkId,
              prescribedDrugs.drugIntake.clinicId,
            )
          }
        />
      )}
      {prescribedDrugs.drugIntake.isSubmited && (
        <Alert
          state={prescribedDrugs.drugIntake}
          setState={setState}
          typeRset="REST_ALERT_PRESCRIBEDDRUGS"
          isOpen={true}
          contentTextOk={
            prescribedDrugs.drugIntake.state === 'Active'
              ? t('DRUGS.STATE_CHANGED_ACTIVE')
              : t('DRUGS.STATE_CHANGED_DONE')
          }
          //doCallbackWhenOk={() => refreshVisits()}
        />
      )}

      {!prescribedDrugs.isLoaded ? (
        <Loading />
      ) : prescribedDrugs.responseError != null ? (
        <AlterBase severity="error">
          <Typography variant="h6" component="p">
            {prescribedDrugs.responseError}
          </Typography>
        </AlterBase>
      ) : patientDirectionId != null &&
        isNumeric(patientDirectionId) &&
        isEmpty(items) ? (
        <AlterBase severity="warning">
          <Typography variant="h6" component="p">
            {t('EMPTY_DATA.NO_PRESCRIBEDDRUG', {
              patientDirectionId,
            })}
          </Typography>
        </AlterBase>
      ) : isEmpty(items) ? (
        <AlterBase severity="warning">
          <Typography variant="h6" component="p">
            {t('EMPTY_DATA.NO_PRESCRIBEDDRUGS')}
          </Typography>
        </AlterBase>
      ) : !isEmpty(items) ? (
        items
      ) : null}
    </>
  );
}
