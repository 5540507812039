import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Collapse as Fade,
  TextField as Input,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  InputAdornment,
  Select,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

// icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modale from '../../components/Modals/Modale';

//form func

import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';

import { agreementText } from '../../actions/user';
import { useUserStateDispatch } from '../../context/UserContext';
import MuiUIPicker from '../MUIDatePicker';
import { isValidPhone, reqRegField } from '../../helpers/validators';
import dayjs from 'dayjs';
import config from '../../config';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  creatingButtonContainer: {
    marginBottom: theme.spacing(3),

    height: 46,
    display: 'flex',
  },
  submitButton: {
    height: 46,
    textTransform: 'none',
    width: '100%',
    minWidth: 240,
    marginTop: 12,
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
  },

  loginLoader: {
    marginLeft: theme.spacing(4),
  },

  passwordSuccess: {
    color: theme.palette.success.dark,
  },
  passwordError: {
    color: theme.palette.error.main,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUpForm({
  values,
  handleChange,
  setValues,
  errors,
  handleSubmit,
  handleDateChange,
  handleChangeGender,
  handlePhoneChange,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { appInfo, isLoaded },
  } = useUserStateDispatch();

  const [visibilePass, setVisibilePass] = useState(false);
  const [visibileRePass, setVisibileRePass] = useState(false);

  const [openModal, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!openModal);
  };

  // get agreement
  const [agreement, setAgreement] = useState({
    text: '',
    header: '',
    loading: false,
    error: '',
  });

  // useEffect(() => {
  //   const vals = {
  //     login: '79262223155',
  //     password: 'Rock11city!',
  //     repassword: 'Rock11city!',
  //     gender: 'M',
  //     lastName: 'Los',
  //     firstName: 'Oleguss',
  //     middleName: 'Servantes',
  //     birthDate: dayjs('20001212'),
  //     //agreement: true,
  //   };
  //   setValues(vals);
  // }, []);

  // console.log('values', values);

  const handleAgree = (e) => {
    const vals = {
      ...values,
      [e.target.name]: e.target.checked,
    };
    setValues(vals);
  };

  const loginLabel = () => {
    switch (appInfo.authIdentifiers) {
      case 'email': {
        return t('COMPONENT.FORM_EMAIL');
      }
      case 'phone': {
        return t('COMPONENT.FORM_PHONE');
      }
      default: {
        return t('COMPONENT.FORM_EMAIL_OR_PHONE');
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const anicuraTerms =
    config.theme === 'white_fang' &&
    (!values.agreement1 || !values.agreement2);
  const disabledSubmitButton =
    !values.login ||
    !values.agreement ||
    !isEmpty(errors) ||
    anicuraTerms;

  return (
    <>
      <Grid
        container
        spacing={2}
        mt={1}
        mb={3}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid size={12}>
          <Input
            name="login"
            margin="normal"
            variant="outlined"
            value={values.login || ''}
            onBlur={(e) =>
              appInfo.authIdentifiers !== 'phone' &&
              isValidPhone(e.target.value, appInfo.countryCode) &&
              handlePhoneChange(e)
            }
            onChange={(e) =>
              appInfo.authIdentifiers === 'phone'
                ? handlePhoneChange(e)
                : handleChange(e)
            }
            label={loginLabel()}
            type="text"
            fullWidth
            required
            error={values.login != null && errors?.login != null}
            helperText={
              values.login != null &&
              errors?.login != null &&
              errors?.login
            }
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel htmlFor="outlined-adornment-password">
              {t('COMPONENT.FORM_PASS')}
            </InputLabel>
            <OutlinedInput
              name="password"
              autoComplete="new-password"
              variant="outlined"
              value={values.password || ''}
              onChange={handleChange}
              label={t('COMPONENT.FORM_PASS')}
              type={visibilePass ? 'text' : 'password'}
              required
              error={errors.password != null}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVisibilePass(!visibilePass)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {!visibilePass ? (
                      <VisibilityOffIcon color="primary" />
                    ) : (
                      <VisibilityIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText className={classes.passwordError}>
              {errors.password != null && errors.password}
            </FormHelperText>
          </FormControl>
          {!isEmpty(errors.passRules) &&
            Object.keys(errors.passRules).map((key) => {
              const clsName = errors.passRules[key].valid
                ? classes.passwordSuccess
                : classes.passwordError;
              return (
                <Grid
                  key={key}
                  container
                  direction="row"
                  justifyContent="flex-start"
                >
                  <Grid size={1} style={{ paddingLeft: 8 }}>
                    {errors.passRules[key].valid ? (
                      <CheckIcon className={clsName} />
                    ) : (
                      <CloseIcon className={clsName} />
                    )}
                  </Grid>
                  <Grid size={11} style={{ paddingLeft: 14 }}>
                    <FormHelperText className={clsName}>
                      {errors.passRules[key].ruleText}
                    </FormHelperText>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel htmlFor="outlined-adornment-password">
              {t('COMPONENT.FORM_REPASS')}
            </InputLabel>
            <OutlinedInput
              name="repassword"
              autoComplete="new-password"
              variant="outlined"
              value={values.repassword || ''}
              onChange={handleChange}
              label={t('COMPONENT.FORM_REPASS')}
              type={visibileRePass ? 'text' : 'password'}
              required
              error={errors.repassword != null}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setVisibileRePass(!visibileRePass)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {!visibileRePass ? (
                      <VisibilityOffIcon color="primary" />
                    ) : (
                      <VisibilityIcon color="primary" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText className={classes.passwordError}>
              {errors.repassword != null && errors.repassword}
            </FormHelperText>
          </FormControl>
        </Grid>
        {!appInfo.isSimpleRegistrationForm && (
          <>
            <Grid size={{ xs: 12, sm: 6 }} mt={2}>
              <Input
                name="lastName"
                variant="outlined"
                autoComplete={'off'}
                value={values.lastName || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_LAST_NAME')}
                type="text"
                fullWidth
                inputProps={{ maxLength: 50 }}
                required={reqRegField(
                  appInfo.requiredRegistrationFields,
                  'lastName',
                )}
                error={errors.lastName != null}
                helperText={
                  errors.lastName != null && errors.lastName
                }
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} mt={2}>
              <Input
                name="firstName"
                variant="outlined"
                autoComplete={'off'}
                value={values.firstName || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_NAME')}
                type="text"
                fullWidth
                inputProps={{ maxLength: 50 }}
                required={reqRegField(
                  appInfo.requiredRegistrationFields,
                  'firstName',
                )}
                error={errors.firstName != null}
                helperText={
                  errors.firstName != null && errors.firstName
                }
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Input
                name="middleName"
                variant="outlined"
                autoComplete={'off'}
                value={values.middleName || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_MIDLE_NAME')}
                type="text"
                fullWidth
                inputProps={{ maxLength: 50 }}
                error={errors.middleName != null}
                helperText={
                  errors.middleName != null && errors.middleName
                }
              />
            </Grid>

            <Grid size={{ xs: 12, sm: 6 }}>
              <MuiUIPicker
                margin="none"
                label={t('COMPONENT.BIRTH_DATE')}
                value={
                  values.birthDate != null
                    ? dayjs(values.birthDate)
                    : null
                }
                handleDateChange={handleDateChange}
                defaultValue={dayjs().add(
                  appInfo.ageLimitHigh,
                  'years',
                )}
                minDate={dayjs().add(appInfo.ageLimitLow, 'years')}
                maxDate={dayjs().add(appInfo.ageLimitHigh, 'years')}
                required={reqRegField(
                  appInfo.requiredRegistrationFields,
                  'birthDate',
                )}
                errorText={errors.birthDate}
                name="birthDate"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} mt={2}>
              <FormControl
                variant="outlined"
                fullWidth
                required={reqRegField(
                  appInfo.requiredRegistrationFields,
                  'gender',
                )}
                error={errors.gender != null && errors.gender}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  {t('COMPONENT.FORM_GENDER')}
                </InputLabel>
                <Select
                  //labelId="demo-simple-select-outlined-label"
                  //id="demo-simple-select-outlined"
                  name="gender"
                  value={values.gender != null ? values.gender : ''}
                  onChange={handleChangeGender}
                  label={t('COMPONENT.FORM_GENDER')}
                >
                  <MenuItem value="M">
                    {t('COMPONENT.FORM_GENDER_M')}
                  </MenuItem>
                  <MenuItem value="F">
                    {t('COMPONENT.FORM_GENDER_F')}
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errors.gender != null && errors.gender}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreement"
                    onChange={handleAgree}
                    checked={values.agreement}
                    value={values.agreement}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1">
                    {t('SIGN.UP_AGEEMENT')}{' '}
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        agreementText({
                          agreement,
                          setAgreement,
                          isLocal: false,
                          header: t('SIGN.UP_TITLE_LIC_AGR'),
                        });

                        toggleModal();
                      }}
                      sx={{ pl: 0, pb: 1, fontSize: 16 }}
                    >
                      {t('SIGN.UP_AGEEMENT1')}
                    </Button>
                  </Typography>
                }
                sx={{ my: 1 }}
              />
            </Grid>
          </>
        )}

        {appInfo?.uiType === 'petClinic' && (
          <>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreement"
                    onChange={handleAgree}
                    checked={values.agreement}
                    value={values.agreement}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1">
                    {t('SIGN.UP_AGEEMENT')}{' '}
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        agreementText({
                          agreement,
                          setAgreement,
                          isLocal: false,
                          header: t('SIGN.UP_TITLE_LIC_AGR'),
                        });
                        toggleModal();
                      }}
                      sx={{ pl: 0, pb: 1, fontSize: 16 }}
                    >
                      {t('SIGN.UP_AGEEMENT1')}
                    </Button>
                  </Typography>
                }
                sx={{ my: 1 }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreement2"
                    onChange={handleAgree}
                    checked={values.agreement2}
                    value={values.agreement2}
                    color="primary"
                  />
                }
                label="Мне больше 18 лет"
                sx={{ my: 1 }}
              />
            </Grid>
            <Grid size={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="agreement1"
                    onChange={handleAgree}
                    checked={values.agreement1}
                    value={values.agreement1}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body1">
                    Подтверждаю{' '}
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        agreementText({
                          agreement,
                          setAgreement,
                          isLocal: true,
                          header:
                            'Согласие на обработку персональных данных',
                        });

                        toggleModal();
                      }}
                      sx={{ pl: 0, pb: 1, fontSize: 16 }}
                    >
                      согласие на обработку персональных данных
                    </Button>
                  </Typography>
                }
                sx={{ my: 1 }}
              />
            </Grid>

            {/* <Grid size={{ xs: 12, sm: 6 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement3"
                      onChange={handleAgree}
                      value={false}
                      color="primary"
                    />
                  }
                  label="Разрешаю получение информационных и рекламных сообщений от Белый клык"
                  sx={{ my: 1 }}
                />
              </Grid> */}
          </>
        )}

        <Grid size={12} textAlign="center" mb={3}>
          {!isLoaded ? (
            <Loading />
          ) : (
            <Button
              disabled={disabledSubmitButton}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submitButton}
            >
              {t('SIGN.UP_BUTTON_SUBMIT')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Modale
        open={openModal}
        toggleModal={toggleModal}
        title={agreement.header}
        my={3}
      >
        {!isLoaded ? (
          <Loading />
        ) : (
          <ReactMarkdown
            remarkPlugins={[remarkBreaks]}
            rehypePlugins={[rehypeRaw]}
          >
            {agreement.text}
          </ReactMarkdown>
        )}
      </Modale>
    </>
  );
}

export default SignUpForm;
