import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

export default function InvoicePaymentError() {
  const { t } = useTranslation();

  return (
    <Alert severity="error">
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        {t('INVOICES.ERROR')}
      </Typography>
      <Typography variant="body2">
        {t('INVOICES.CLOSE_WIN_ERR')}
      </Typography>
    </Alert>
  );
}
