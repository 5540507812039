import React from 'react';
import Modale from '../../components/Modals/Modale';
import DoctorRating from './DoctorRating';
//import DoctorSetRating from './DoctorSetRating';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleDoctorRating({
  modalRating,
  setModalRaring,
}) {
  return (
    <Modale
      open={modalRating}
      toggleModal={() => setModalRaring(!modalRating)}
      height={770}
    >
      <DoctorRating setModalRaring={setModalRaring} />
    </Modale>
  );
}
