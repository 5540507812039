import React from 'react';

import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import { Typography, Button, Alert } from '@mui/material';
import Modale from '../Modals/Modale';
import VisitChat from '../Chat';
import { useTranslation } from 'react-i18next';
import ChatIcon from '@mui/icons-material/Chat';

const useStyles = makeStyles((theme) => {
  return {
    sideBar: {
      width: '100%',
      top: 158,
      height: 72,

      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.down('sm')]: {
        top: 128,
        height: 56,
      },
      background: theme.palette.background.info,
      boxShadow: '0px 2px 35px rgba(78, 72, 190, 0.08) !important',
    },

    menuContainer: {
      color: theme.palette.text.primary,
      maxWidth: 1242,
      padding: 0,
      margin: 'auto',
      width: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
      },
    },
  };
});

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SidebarInfo({ lang, chatInfo, modalChat, toggleModalChat }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (
    chatInfo?.groupId == null ||
    chatInfo?.unreadCount == null ||
    chatInfo?.unreadCount === 0
  ) {
    return null;
  }

  return (
    <AppBar className={classes.sideBar}>
      <div className={classes.menuContainer}>
        <ChatIcon
          sx={(theme) => ({
            color: theme.palette.info.dark,
            marginTop: 4,
            marginRight: 12,
          })}
        />
        <Typography
          variant="h6"
          style={{ marginTop: 3, marginRight: 12 }}
        >
          {t('COMPONENT.HAS_MESS_OPER')}
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={toggleModalChat}
        >
          {t('COMPONENT.BUT_OPEN_CHAT')}
        </Button>
        <Modale
          open={modalChat}
          toggleModal={toggleModalChat}
          title={`${t('EVENTS_VISITS.TITLE_ONLINE_CONF')} `}
          mx={0}
          maxWidth="lg"
          height={700}
          backdrop={true}
          isScrollHide={true}
        >
          {chatInfo?.groupId != null ? (
            <VisitChat
              lang={lang}
              activeGroupId={chatInfo?.groupId}
              inModale={true}
            />
          ) : (
            <Alert severity="error">
              <Typography variant="body2">
                chatInfo?.groupId - undefined
              </Typography>
            </Alert>
          )}
        </Modale>
      </div>
    </AppBar>
  );
}

export default SidebarInfo;
