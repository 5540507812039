import React, { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';
import Loading from '../Loading';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './Sample.css';
import {
  Stack,
  Alert,
  AlertTitle,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function PdfView({ url }) {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
  const handleDocLoadError = (err) => {
    setError(
      err.name === 'MissingPDFException'
        ? t('ERROR_CODES.404')
        : t('ERROR_CODES.500'),
    );
  };

  return error != null ? (
    <Stack sx={{ width: '100%', mt: 6 }} spacing={2}>
      <Alert severity="error">
        <AlertTitle>{t('ERROR_CODES.ERROR')}</AlertTitle>
        {error}
      </Alert>
    </Stack>
  ) : (
    <div className="Example">
      <div className="Example__container">
        <div className="Example__container__document">
          <Document
            file={url}
            loading={<Loading />}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={handleDocLoadError}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={isMobile ? 1 : 2.5}
              />
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
}
