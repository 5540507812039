import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  avatarProfile: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(1),
  },

  messageNotification: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    width: '100%',
  },
  messageNotificationBodySide: {
    alignItems: 'flex-start',
    marginRight: 0,
    width: '100%',
  },
  notificationContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: theme.spacing(8),
  },
  notificationContained: {
    borderRadius: theme.spacing(6),
    height: theme.spacing(6),
  },
  notificationContainedShadowless: {
    boxShadow: 'none',
  },
  notificationIconContainer: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 24,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  notificationIconOnPhoto: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    // borderRadius: theme.spacing(6),
    position: 'absolute',
    left: theme.spacing(6),
    top: theme.spacing(5),
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  notificationIconContainerContained: {
    fontSize: 18,
    color: '#FFFFFF80',
  },
  notificationIconContainerRounded: {
    marginRight: theme.spacing(1),
  },
  containedTypography: {
    color: 'white',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  extraButton: {
    color: 'white',
    '&:hover, &:focus': {
      background: 'transparent',
    },
  },
}));
