import React from 'react';

import { makeStyles } from '@mui/styles';
import Marked from 'react-markdown';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useVisitState } from '../../context/VisitContext';

import { useTranslation } from 'react-i18next';
import { isPetKey } from '../../helpers';
import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  docContent: {
    alignSelf: 'flex-start',
    height: '100%',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.hero} #fff`,
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 8,
      backgroundColor: theme.palette.primary.lighter,
      border: '5px solid #fff',
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
      {
        backgroundColor: '#fff',
      },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
      {
        backgroundColor: theme.palette.primary.lighter,
        border: '3px solid #fff',
      },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
      {
        backgroundColor: theme.palette.primary.lighter,
        border: '3px solid #fff',
      },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: '#fff',
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorInfoAbout() {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    state: {
      visitData: { doctor },
    },
  } = useVisitState();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const forPet = appInfo?.uiType === 'petClinic';
  return (
    <Box mb={2} className={classes.docContent}>
      {doctor?.description != null && (
        <Marked>{doctor?.description}</Marked>
      )}

      {doctor?.education != null && (
        <>
          <Typography
            variant="h6"
            component="p"
            style={{ marginTop: 16 }}
          >
            {/* {t('VISIT.DR_EDUCATION')} */}
            {t(isPetKey('VISIT.DR_EDUCATION', forPet))}
          </Typography>
          <Marked>{doctor?.education}</Marked>
        </>
      )}
      {doctor?.graduate != null && (
        <>
          <Typography
            variant="h6"
            component="p"
            style={{ marginTop: 16 }}
          >
            {/* {t('VISIT.DR_GRADUATE')} */}
            {t(isPetKey('VISIT.DR_GRADUATE', forPet))}
          </Typography>

          <Marked>{doctor?.graduate}</Marked>
        </>
      )}
      {doctor?.experience != null && (
        <>
          <Typography
            variant="h6"
            component="p"
            style={{ marginTop: 16 }}
          >
            {/* {t('VISIT.DR_EXPERIENCE')} */}

            {t(isPetKey('VISIT.DR_EXPERIENCE', forPet))}
          </Typography>
          <Marked>{doctor?.experience}</Marked>
        </>
      )}

      {doctor?.traineeship != null && (
        <>
          <Typography
            variant="h6"
            component="p"
            style={{ marginTop: 16 }}
          >
            {/* {t('VISIT.DR_TRAINEESHIP')} */}
            {t(isPetKey('VISIT.DR_TRAINEESHIP', forPet))}
          </Typography>
          <Marked>{doctor?.traineeship}</Marked>
        </>
      )}

      {doctor?.conferences != null && (
        <>
          <Typography
            variant="h6"
            component="p"
            style={{ marginTop: 16 }}
          >
            {/* {t('VISIT.DR_CONFERENCES')} */}
            {t(isPetKey('VISIT.DR_CONFERENCES', forPet))}
          </Typography>
          <Marked>{doctor?.conferences}</Marked>
        </>
      )}

      {doctor?.professionalSkillImprovement != null && (
        <>
          <Typography
            variant="h6"
            component="p"
            style={{ marginTop: 16 }}
          >
            {t('VISIT.DR_PROFESSIONALSKILLIMPROVEMENT')}
          </Typography>
          <Marked>{doctor?.professionalSkillImprovement}</Marked>
        </>
      )}

      {doctor?.seminarParticipation != null && (
        <Marked>{doctor?.seminarParticipation}</Marked>
      )}
    </Box>
  );
}
