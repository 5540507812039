import React from 'react';
import { makeStyles } from '@mui/styles';

import Typography from '@mui/material/Typography';

import { Card } from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import AvatarOnOffLine from './AvatarOnOffLine';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    background: theme.palette.baseButton.bgColor,
    boxSizing: 'border-box',
    minHeight: 325,
    padding: theme.spacing(4),
    borderRadius: `${theme.spacing(1)}!important;`,
    boxShadow: 'none',
  },
  avatarFio: {
    marginTop: theme.spacing(2),
    color: '#fff',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function UserAvatarCard({ fio, src, children }) {
  const classes = useStyles();
  const {
    userState: {
      user: { lastName, firstName, middleName, photo },
    },
  } = useUserStateDispatch();
  const fioUser =
    fio == null
      ? `${lastName} ${firstName ?? ''}  ${middleName ?? ''}`
      : fio;
  const srcUser =
    src == null ? `data:image/jpeg;base64,${photo}` : src;

  return (
    <Card className={classes.root}>
      <AvatarOnOffLine image={srcUser} size="medium" />
      <Typography variant="h5" className={classes.avatarFio}>
        {fioUser}
      </Typography>
      {children}
    </Card>
  );
}
