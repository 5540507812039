import * as React from 'react';
import { useTheme } from '@mui/styles';

const DirectionsItemFoldSvg = () => {
  const theme = useTheme();
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="2" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.6834 44.971C42.7065 44.9105 44.3425 43.3014 44.448 41.2904L48 24.2323L44.5155 24.2317V23.206C44.5155 21.0597 42.7702 19.3142 40.6248 19.3142H27.9334L24.2825 16H16.8918C14.7456 16 13 17.7456 13 19.8912V41.0858C13 43.2315 14.7454 44.9771 16.8907 44.9771L38.8283 44.978L40.6248 44.9771C40.6361 44.9771 40.6469 44.9755 40.6576 44.974C40.6661 44.9727 40.6747 44.9715 40.6834 44.971ZM26.7648 22.3431H40.6257C41.1007 22.3431 41.4878 22.7308 41.4876 23.2061V24.2317H20.9902L17.5477 41.948H16.8921C16.416 41.948 16.0289 41.5609 16.0289 41.0855V19.8915C16.0289 19.416 16.416 19.0289 16.8921 19.0289H23.1139L26.7648 22.3431Z"
        fill={theme.palette.hero}
      />
    </svg>
  );
};
export default DirectionsItemFoldSvg;
