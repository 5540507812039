import React, { useEffect, useMemo } from 'react';

import { useVisitState } from '../../context/VisitContext';
import { doctorReviews } from '../../actions/visit';
import { useUserStateDispatch } from '../../context/UserContext';
import ReviewItem from './DoctorRatingsReviewsItem';
import Loading from '../../components/Loading';
import { Alert, Typography, useMediaQuery } from '@mui/material';
import isEmpty from '../../helpers';
//import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorRatingsReviews({ scrollDiv }) {
  const { t } = useTranslation();
  // const classes = useStyles();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();

  const {
    state: {
      doctorRating: {
        doctor,
        data,
        isLoaded,
        responseError,
        startIndex,
        count,
        hasMore,
      },
    },
    setState,
  } = useVisitState();

  useEffect(() => {
    setState({
      type: 'RESET_RATING_DATA',
    });

    doctorReviews({
      dispatch: setState,
      clinicId: doctor.clinicId,
      doctorId: doctor.id,
    });
  }, []);

  const items = useMemo(
    () =>
      data.reviews.map((item) => (
        <ReviewItem key={item.id} item={item} lang={lang} />
      )),
    [hasMore, lang],
  );

  return (
    <>
      {!isMobile && (
        <Typography variant="h2" mb={3}>
          {t('VISIT.REVIEWS_HEADER')}
        </Typography>
      )}

      {!isLoaded ? (
        <Loading />
      ) : isLoaded && responseError != null ? (
        <Alert severity="error">
          <Typography variant="h6" component="p">
            {responseError}
          </Typography>
        </Alert>
      ) : isLoaded && isEmpty(data?.reviews) ? (
        <Alert severity="info">
          <Typography variant="h6" component="p">
            {t('VISIT.REVIEWS_HAS_NOT')}
          </Typography>
        </Alert>
      ) : !isEmpty(data?.reviews) ? (
        <InfiniteScroll
          loadMore={() => {
            console.log(
              ' loadMore loadMore loadMore',
              startIndex,
              hasMore,
            );

            startIndex > 0 &&
              doctorReviews({
                dispatch: setState,
                clinicId: doctor.clinicId,
                doctorId: doctor.id,
                startIndex,
                count,
              });
          }}
          hasMore={hasMore}
          loader={<Loading key={0} />}
          getScrollParent={() => scrollDiv}
        >
          {items}
        </InfiniteScroll>
      ) : null}
    </>
  );
}
