import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import {
  clearLocalStorage,
  confirmLogin,
  loginUser,
  requestNewAccountConfirmationCode,
  singUpConfirmationCode,
} from '../../actions/user';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignIn';

import SignInForm from './SignInForm';
import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';

import { useTranslation } from 'react-i18next';
import ShowResponse from '../FormComponents/ShowResponse';
import Loading from '../Loading';
import SignFormConfirm from './SignFormConfirm';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ valsTab, setValsTab, hasButtonGoSignUp = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // global
  const {
    userDispatch,
    userState: { appInfo, isLoaded, serverResponse },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const _appInfo = {
    ...appInfo,
    authIdentifiers: valsTab.authIdentifiers,
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
  } = useForm(login, validate, _appInfo);

  const buttonLabel = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_BUTTON_PHONE');
      }
      case 'phone': {
        return t('SIGN.IN_BUTTON_EMAIL');
      }
    }
  };
  const title = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_TITLE_EMAIL');
      }
      case 'phone': {
        return t('SIGN.IN_TITLE_PHONE');
      }
      default: {
        return t('SIGN.IN_TITLE');
      }
    }
  };
  const handleAuthIdentifiers = () => {
    const authIdentifiers =
      valsTab.authIdentifiers === 'email' ? 'phone' : 'email';
    userDispatch({ type: 'LOADED' });
    setValsTab({
      ...valsTab,
      authIdentifiers,
    });
    const vals = {
      ...values,
      login: null,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  useEffect(() => {
    return () => {
      userDispatch({
        type: 'LOADED',
      });
    };
  }, []);

  const submitConfirm = () => {
    if (serverResponse?.action === 'CONFIRM_CODE_SENT') {
      confirmLogin(
        userDispatch,
        values.code,
        navigate,
        language,
        appInfo.isAnonymousChildrenEnabled,
      );
    }
    if (serverResponse?.action === 'CONFIRM_SINGUP') {
      singUpConfirmationCode(
        userDispatch,
        {
          login: values.login,
          password: values.password,
          confirmationCode: values.code || values.confirmationCode,
        },
        language,
        navigate,
        appInfo.isAnonymousChildrenEnabled,
      );
    }
  };

  const reSendCode = () => {
    if (serverResponse?.action === 'CONFIRM_CODE_SENT') {
      login();
    }
    if (serverResponse?.action === 'CONFIRM_SINGUP') {
      requestNewAccountConfirmationCode(userDispatch, {
        login: values.login,
        password: values.password,
      });
    }
  };

  const setActiveTabId = (activeTabId) => {
    userDispatch({
      type: 'LOADED',
    });

    setValsTab({
      ...valsTab,
      activeTabId,
    });
  };

  const lifeTime = serverResponse?.lifeTime
    ? serverResponse?.lifeTime
    : 900;

  return (
    <Grid container spacing={1} mt={3} mb={3}>
      <Grid size={7}>
        <Typography variant="h3">{title()}</Typography>
      </Grid>

      <Grid size={5} textAlign="right">
        {appInfo.authIdentifiers !== 'both' && (
          <Button color="primary" onClick={handleAuthIdentifiers}>
            {buttonLabel()}
          </Button>
        )}
      </Grid>
      {!isLoaded ? (
        <Grid size={12} mt={3} mb={4}>
          <Loading />
        </Grid>
      ) : (
        <>
          <Grid size={12} mt={3} mb={2}>
            <ShowResponse serverResponse={serverResponse} />
          </Grid>
          {serverResponse?.action === 'CONFIRM_CODE_SENT' ||
          serverResponse?.action === 'WRONG_CONFIRMATION_CODE' ||
          serverResponse?.action === 'CONFIRM_SINGUP' ? (
            <Grid size={12} mt={3}>
              <Typography variant="h4" mb={4}>
                {t('COMPONENT.TITLE_CONFIRM_CODE')}
              </Typography>
              <SignFormConfirm
                values={values}
                handleChange={handleChange}
                errors={errors}
                handleSubmit={submitConfirm}
                submitAgain={reSendCode}
                lifeTime={lifeTime}
                isLoaded={isLoaded}
                name="code"
                takeStepBack={() => {
                  clearLocalStorage();
                  setActiveTabId(0);
                }}
              />
            </Grid>
          ) : (
            <SignInForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              valsTab={valsTab}
              setActiveTabId={setActiveTabId}
              appInfo={appInfo}
              handlePhoneChange={handlePhoneChange}
              hasButtonGoSignUp={hasButtonGoSignUp}
            />
          )}
        </>
      )}
    </Grid>
  );
}

export default SignIn;
