import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import LeftMenu from '../../components/Sidebar/LeftMenu';
import { getNotificationSettings } from '../../actions/user';
import { useUserStateDispatch } from '../../context/UserContext';
import NotificationsSettingsForm from '../../components/Forms/NotificationsSettingsForm';

export default function ControleNotificationsPage() {
  const { t } = useTranslation();
  const {
    userState: {
      user: { mmkId, clinicId },
    },
    userDispatch,
  } = useUserStateDispatch();

  useEffect(() => {
    getNotificationSettings({
      dispatch: userDispatch,
      mmkId,
      clinicId,
    });
    // eslint-disable-next-line
  }, [mmkId, clinicId]);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, sm: 12, md: 4, lg: 3 }}>
        <LeftMenu isProfile={true} />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, md: 8, lg: 9 }}>
        <Typography variant="h1" mb={5}>
          {t('NOTIFY_SETTINGS.FORM_TITLE')}
        </Typography>
        <Typography variant="h6" mb={3}>
          {t('NOTIFY_SETTINGS.FORM_SUBTITLE')}
        </Typography>
        <NotificationsSettingsForm />
      </Grid>
    </Grid>
  );
}
