import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useVisitState } from '../../context/VisitContext';
import {
  getDoctors,
  getDefaultVisitKind,
  addDoctorReview,
} from '../../actions/visit';
import {
  dateToHL7,
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
} from '../../helpers/dateFormat';
import isEmpty, { isNumeric } from '../../helpers';
import { useUserStateDispatch } from '../../context/UserContext';
import AlertDialogSlide from '../../components/Modals/AlertDialogSlide';
import InfiniteScroll from 'react-infinite-scroller';
import { Trans, useTranslation } from 'react-i18next';

import Loading from '../../components/Loading';
import { Alert } from '@mui/material';
import useDoctorsParams from './useDoctorsParams';
import AlertDialog from '../../components/Modals/AlertDialog';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function Doctors({
  children,
  heroPage = false,
  toggleModalRating,
  toggleModalDoc,
  toggleModalCreateVisit,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    userState: {
      clinics,
      specs,
      appInfo,
      user: { lang },
    },
  } = useUserStateDispatch();

  const [modaleInfo, setModaleInfo] = useState(false);

  const {
    state: {
      doctors,
      //      visits,
      visit,
      selectedDate,
      selectedVisitKindId,

      sortOrder,
      filterWithTimeSlotsOnly,

      doctorRating,
      cachedTimeSlots,
    },
    setState,
  } = useVisitState();

  const { specIdParam, clinicId, isOnlineParam, fioParam, favPram } =
    useDoctorsParams();

  const specialization =
    (specs || []).find((item) => item.id === specIdParam)?.name || '';
  const specializationId =
    (specs || []).find((item) => item.id === specIdParam)?.id || null;

  const toggleFav = (doctor, review) => {
    setState({
      type: 'SET_RATING_DATA',
      payload: { doctor, review, isLoaded: false, favOnly: true },
    });

    addDoctorReview(
      setState,
      doctor.clinicId,
      doctor.id,
      !review?.isFavorite,
    );
  };

  //

  const doWhenOk = () => {
    getDoctors(
      setState,
      'REFREESH_DOCTORS',
      isOnlineParam ? 'Online' : 'Offline',
      selectedDate,
      null,
      '',
      '',
      doctorRating.doctor.clinicId,
      selectedVisitKindId,
      0,
      1,
      filterWithTimeSlotsOnly,
      false,
      doctorRating.doctor.id,
      cachedTimeSlots,
      appInfo.filterWithPlanningOnly,
    );
  };
  const getDoctorsFirst = () => {
    setState({
      type: 'START_DOCTORS',
    });
    getDoctors(
      setState,
      'FETCH_DOCTORS',
      isOnlineParam ? 'Online' : 'Offline',
      selectedDate,
      specializationId,
      specialization,
      fioParam,
      clinicId || 0,
      selectedVisitKindId,
      0,
      doctors.count,
      isOnlineParam ? true : filterWithTimeSlotsOnly, //filterWithTimeSlotsOnly
      favPram,
      null,
      cachedTimeSlots,
      appInfo.filterWithPlanningOnly,
      sortOrder,
    );
  };

  useEffect(() => {
    getDoctorsFirst();
  }, [
    isOnlineParam,
    selectedDate,
    specializationId,
    clinicId,
    filterWithTimeSlotsOnly,
    favPram,
    sortOrder,
    cachedTimeSlots,
    fioParam,
  ]);

  /** Create items */
  const items = doctors.data.map((item) => {
    const defaultVisitKind = getDefaultVisitKind(
      item.visitKinds,
      isOnlineParam,
      appInfo?.usePlExGrWebSpecializations ?? specializationId,
    );

    return React.cloneElement(children, {
      key: item.itemId,
      visitDate: dateToHL7(selectedDate.toDate()),
      doctor: item.doctor,
      itemId: item.itemId,
      plExGrWebSpecializations: item.plExGrWebSpecializations,
      clinic: {
        ...(clinics || []).find(
          (it) => it.id === item.doctor.clinicId,
        ),
      },
      timeSlotsIsLoaded: item.timeSlotsIsLoaded,
      timeSlots: !isEmpty(defaultVisitKind) ? item.timeSlots : null,

      visitKinds: item.visitKinds,
      selectedVisitKindId,
      defaultVisitKind,

      isOnline: isOnlineParam,
      toggleModalCreateVisit,
      toggleModalDoc,
      // gotoDoc,
      setState,

      review: item?.review,
      toggleModalRating,
      toggleFav,

      cachedTimeSlots,
      setModaleInfo,
    });
  });

  const redirectToVisits = () => {
    if (visit?.data) {
      navigate(
        `/app/events_and_visits/${visit.data}/${visit.clinicId}`,
      );
    }
  };

  const response = isNumeric(visit.data)
    ? 'visit_added'
    : visit.data === true
    ? 'email_sended'
    : null;

  //console.log('doctors.data', doctors.data);

  return (
    <>
      <AlertDialog
        open={modaleInfo}
        setOpen={setModaleInfo}
        severity="info"
      >
        <Trans
          i18nKey="VISIT.DATES_MARKED"
          components={{
            dot: (
              <FiberManualRecordIcon
                sx={{
                  fontSize: '1rem',
                  position: 'relative',
                  top: 4,
                }}
                color="primary"
              />
            ),
          }}
        />
      </AlertDialog>
      {doctorRating?.response === 'OK' && (
        <AlertDialogSlide
          state={doctorRating}
          setState={setState}
          typeRset="RESET_RATING_DATA"
          isOpen={true}
          contentTextOk={
            !doctorRating?.favOnly
              ? t('VISIT.REVIEW_ADDED')
              : !doctorRating?.review?.isFavorite
              ? t('VISIT.REVIEW_FAV_KEEPED')
              : t('VISIT.REVIEW_FAV_REMOVED')
          }
          doCallbackWhenOk={doWhenOk}
          doOnClose={doWhenOk}
        />
      )}
      {visit.isSubmited && (
        <AlertDialogSlide
          state={visit}
          setState={setState}
          typeRset="RESET_VISIT"
          isOpen={true}
          contentTextOk={
            response === 'visit_added'
              ? t('VISIT.ALERT_OK')
              : response === 'email_sended'
              ? t('VISIT.ALERT_REQ_EMAIL_DOCTOR_OK')
              : ''
          }
          contentTextOkDetail={`${HL7ToDateToSrtDate(
            visit.visitDate,
            lang,
          )} ${
            HL7ToDateToTime(visit.visitDate) !== '00:00'
              ? HL7ToDateToTime(visit.visitDate)
              : ''
          }`}
          doCallbackWhenOk={
            response === 'visit_added' ? redirectToVisits : null
          }
          buttonTitle={
            response === 'visit_added'
              ? t('COMPONENT.BUTTON_SEE_APPOINMENT')
              : null
          }
        />
      )}

      {!doctors.isLoaded ? (
        <Loading />
      ) : doctors.responseError != null ? (
        <Alert severity="error">
          <Typography variant="h6" component="p">
            {doctors.responseError}
          </Typography>
        </Alert>
      ) : heroPage ? (
        items
      ) : !isEmpty(items) ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={() =>
            doctors.startIndex > 0 &&
            getDoctors(
              setState,
              'FETCH_DOCTORS',
              isOnlineParam ? 'Online' : 'Offline',
              selectedDate,
              specializationId,
              specialization,
              fioParam,
              clinicId || 0,
              selectedVisitKindId,
              doctors.startIndex,
              doctors.count,
              filterWithTimeSlotsOnly,
              favPram,
              null,
              cachedTimeSlots,
              appInfo.filterWithPlanningOnly,
              sortOrder,
            )
          }
          hasMore={doctors.hasMore}
          loader={<Loading key={0} />}
        >
          {items}
        </InfiniteScroll>
      ) : (
        <Alert
          severity="warning"
          style={{ marginBottom: 24 }}
          icon={false}
        >
          <Typography variant="h6" component="p">
            {t('EMPTY_DATA.NO_DOCTORS')}
          </Typography>
        </Alert>
      )}
    </>
  );
}
export default Doctors;
