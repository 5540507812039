import i18next from 'i18next';
import { isValidEmail, isValidPhone } from '../../helpers/validators';

export default function validate(values, appInfo) {
  let errors = {};
  //console.log('=== authIdentifiers ===', appInfo.authIdentifiers);
  if (values?.login != null && !values?.login.trim()) {
    switch (appInfo.authIdentifiers) {
      case 'email': {
        errors.login = i18next.t('SIGN.UP_EMPTY_EMAIL');
        break;
      }
      case 'phone': {
        errors.login = i18next.t('SIGN.UP_EMPTY_PHONE');
        break;
      }
      default: {
        errors.login = i18next.t('SIGN.UP_EMPTY_EMAIL_OR_PHONE');
      }
    }
  }

  if (
    appInfo.authIdentifiers === 'email' &&
    values.login != null &&
    !isValidEmail(values.login)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL');
  } else if (
    appInfo.authIdentifiers === 'phone' &&
    values.login != null &&
    !isValidPhone(values.login, appInfo.countryCode)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_PHONE');
  } else if (
    values.login != null &&
    !isValidEmail(values.login) &&
    !isValidPhone(values.login, appInfo.countryCode)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL_OR_PHONE');
  }

  if (values?.password != null && !values?.password.trim()) {
    errors.password = i18next.t('SIGN.UP_EMPTY_PASS');
  }

  return errors;
}
