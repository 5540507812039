import React from 'react';
import { Navigate } from 'react-router-dom';
// pages

import PubliceRoutesPatient from './PublicRoutesPatinet';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function PublicRoutes({ isAuth, authInitRoute }) {
  return isAuth ? (
    <Navigate to={authInitRoute} replace />
  ) : (
    <PubliceRoutesPatient />
  );
}
