import { Dispatch } from 'react';

export type Session = {
  jti: string;
  cdate: string;
  issuer: string;
  ip: string;
  userAgent: {
    browser: {
      name: string;
      version: string;
      major: string;
    };
    device: {
      model: string;
      type: string;
      vendor: string;
    };
    os: { name: string; version: string };
    cpu: { architecture: string };
  };
};

export type State = {
  sessions: {
    isLoaded: boolean;
    data: Session[];
    responseError: string | null;
  };
};

export enum ActionType {
  fetch = 'FETCH_SESSIONS',
  reset = 'REST_SESSIONS',
  remove = 'DELETE_SESSION',
  error = 'FETCH_SESSIONS_ERROR',
}

export type ActionStringPayload = {
  type: ActionType.error | ActionType.remove;
  payload: string;
};

export type ActionObjectPayload = {
  type: ActionType.fetch | ActionType.reset;
  payload: Session[];
};

export type Action = ActionStringPayload | ActionObjectPayload;

export type ContextState = {
  state: State;
  setState: Dispatch<Action>;
};
