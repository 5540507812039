import React from 'react';
import { Box } from '@mui/material';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Footer = ({ children }) => {
  return (
    <Box mt={12} mb={2}>
      {children}
    </Box>
  );
};

export default Footer;
