import React from 'react';
//import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import Doctors from './Doctors';
import DoctorCard from './DoctorCard';
import DoctorsFilter from './DoctorsFilters';
import useStyles from './styles';
import ModaleDoctorRating from './ModaleDoctorRating';
import ModaleDoctorInfo from './ModaleDoctorInfo';
import useDoctorsParams from './useDoctorsParams';
import ModaleCreateVisit from './ModaleCreateVisit';
import DoctorsFilterFIO from './DoctorsFiltersFIO';
import useIsMobile from '../../hooks/useIsMobile';
import useDoctorModale from '../../hooks/useDoctorModale';
//import Doctor from './Doctor';
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const HeaderH1 = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="h1" mb={3} className={className}>
      {t('MAINMENU.APPOINTMENT')}
    </Typography>
  );
};
export default function DoctorsContainer() {
  const classes = useStyles();
  const isMobile = useIsMobile('lg', 768);

  const { isOnlineParam } = useDoctorsParams();
  const {
    modalCreateVisit,
    modalDoc,
    modalRating,
    setModalDoc,
    setModalRaring,
    toggleModalCreateVisit,
    toggleModalDoc,
    toggleModalRating,
  } = useDoctorModale(isOnlineParam);

  return (
    <>
      {!isMobile ? (
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.container}
        >
          <Grid size={12}>
            <HeaderH1 className={classes.filtersFixed} />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }} mt={6}>
            <div className={classes.filtersFixedWidth}>
              <DoctorsFilter />
            </div>
          </Grid>
          <Grid size={{ xs: 12, sm: 9 }} mt={6}>
            <DoctorsFilterFIO />
            <Doctors
              toggleModalRating={toggleModalRating}
              toggleModalDoc={toggleModalDoc}
              toggleModalCreateVisit={toggleModalCreateVisit}
            >
              <DoctorCard />
            </Doctors>
          </Grid>
        </Grid>
      ) : (
        <>
          <HeaderH1 />
          <DoctorsFilter />
          <Doctors
            toggleModalRating={toggleModalRating}
            toggleModalDoc={toggleModalDoc}
            toggleModalCreateVisit={toggleModalCreateVisit}
          >
            <DoctorCard />
          </Doctors>
        </>
      )}
      <ModaleCreateVisit
        modalCreateVisit={modalCreateVisit}
        toggleModalCreateVisit={toggleModalCreateVisit}
      />

      <ModaleDoctorInfo
        modalDoc={modalDoc}
        toggleModalCreateVisit={toggleModalCreateVisit}
        setModalDoc={setModalDoc}
      />
      <ModaleDoctorRating
        modalRating={modalRating}
        setModalRaring={setModalRaring}
      />
    </>
  );
}
