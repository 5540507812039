import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
  dateToHL7,
  hl7ToUtc,
} from '../../helpers/dateFormat';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    textAlign: 'end',
  },

  row: {
    width: '100%',
    margin: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    '& .MuiGrid-item': {
      paddingTop: `${theme.spacing(1)}`,
      paddingBottom: `${theme.spacing(0.5)}`,
    },
  },
  rowNormActive: {
    backgroundColor: '#FFDBE3',
  },
  rowNormDone: {
    backgroundColor: '#EDEDED',
  },

  rowActive: {
    backgroundColor: '#FFEDD2',
  },
  rowFuture: {
    backgroundColor: '#F1F4FC',
  },

  table: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  navButton: {
    background: theme.palette.bgLight.one,
    boxShadow: '0px 2px 35px rgb(78 72 190 / 8%)',
    marginTop: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    '&.MuiSvgIcon-root': {
      textTransform: 'none',
      background: theme.palette.bgLight.one,
      boxShadow: '0px 2px 35px rgb(78 72 190 / 8%)',

      '&:active': {
        background: theme.palette.bgLight.one,
      },
    },
  },
  stepLabel: {
    cursor: 'pointer',
  },
  checkIconButton: {
    '&.MuiIconButton-root': {
      background: theme.palette.bgLight.one,
      padding: theme.spacing(1.5),
      boxShadow: '0px 2px 35px rgb(78 72 190 / 8%)',
      marginLeft: theme.spacing(1.5),
      '&:hover': {
        backgroundColor: '#FA9907',
        '& span > svg': {
          color: '#fff',
        },
      },
    },
  },
  highlightOffIconButton: {
    '&.MuiIconButton-root': {
      background: theme.palette.bgLight.two,
      padding: theme.spacing(1.5),
      boxShadow: '0px 2px 35px rgb(78 72 190 / 8%)',
      '& span > svg': {
        color: '#fff',
      },

      marginLeft: theme.spacing(1.5),
      '&:hover': {
        backgroundColor: '#FA9907',
        '& span > svg': {
          color: '#fff',
        },
      },
    },
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex',
  },

  iconWatch: {
    fontSize: '1rem',
    height: '1.2em',
    marginRight: theme.spacing(0.5),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function PrescribedDrugIntakes({
  drugIntakesByTime,
  lang,
  setState,
  clinicId,
  filterPrescribedDrug,
}) {
  const DIFF_MIN = 15;
  const { t } = useTranslation();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const handleChangeState = (id, state, clinicId) => {
    setState({
      type: 'CHANGE_STATE_CONFIRM',
      payload: {
        state: state === 'Active' ? 'Done' : 'Active',
        id,
        clinicId,
      },
    });
  };
  const currDateUtc = new Date();

  useEffect(() => {
    const currDate = dateToHL7(currDateUtc);
    const activeStep = Object.keys(drugIntakesByTime).find(
      (item) => item === currDate,
    );

    if (activeStep != null) {
      const inx = Object.keys(drugIntakesByTime).indexOf(activeStep);
      setActiveStep(inx);
    } else {
      setActiveStep(0);
    }
  }, [filterPrescribedDrug]);

  const isFutureDate = (dateHL7) => {
    const dateTimeUtc = hl7ToUtc(dateHL7);
    const diff = dayjs(dateTimeUtc).diff(
      dayjs(currDateUtc),
      'minutes',
    );
    return diff > DIFF_MIN;
  };

  const isNearestDate = (dateTime) => {
    const dateTimeUtc = hl7ToUtc(dateTime);
    const diff = Math.abs(
      dayjs(dateTimeUtc).diff(dayjs(currDateUtc), 'minutes'),
    );
    if (diff <= DIFF_MIN) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {Object.keys(drugIntakesByTime).map((key) => {
          const item = drugIntakesByTime[key];
          return (
            <Step key={key}>
              <StepLabel
                className={classes.stepLabel}
                onClick={() =>
                  setActiveStep(
                    Object.keys(drugIntakesByTime).indexOf(key),
                  )
                }
              >
                {HL7ToDateToSrtDate(key, lang)}
              </StepLabel>

              <StepContent>
                <div className={classes.table}>
                  {item.map((row) => (
                    <Grid
                      key={row.id}
                      container
                      spacing={3}
                      className={classnames(
                        classes.row,
                        isFutureDate(row.dateTime)
                          ? classes.rowFuture
                          : isNearestDate(row.dateTime)
                          ? classes.rowActive
                          : classes[`rowNorm${row.state}`],
                      )}
                    >
                      <Grid size={{ xs: 'auto', sm: 2 }}>
                        <Typography
                          variant="body2"
                          className={classes.wrapIcon}
                        >
                          <WatchLaterIcon
                            color="primary"
                            className={classes.iconWatch}
                          />
                          {HL7ToDateToTime(row.dateTime)}
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 'auto', sm: 3 }}>
                        {row.foodLink}
                      </Grid>
                      <Grid size={{ xs: 'auto', sm: 3 }}>
                        {row.receptionType}
                      </Grid>
                      <Grid size={{ xs: 'auto', sm: 2 }}>
                        {row.dose}
                      </Grid>
                      <Grid size={{ xs: 'auto', sm: 2 }}>
                        {!isFutureDate(row.dateTime) && (
                          <Tooltip
                            title={
                              row.state === 'Active'
                                ? t('DRUGS.DO_STATE_DONE')
                                : t('DRUGS.DO_STATE_ACTIVE')
                            }
                          >
                            <IconButton
                              aria-label="acivate"
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                handleChangeState(
                                  row.id,
                                  row.state,
                                  clinicId,
                                )
                              }
                              //className={classes.checkIconButton}
                              classes={{
                                root:
                                  row.state === 'Active'
                                    ? classes.checkIconButton
                                    : classes.highlightOffIconButton,
                              }}
                            >
                              {row.state === 'Active' ? (
                                <CheckIcon />
                              ) : (
                                <HighlightOffIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </div>

                {Object.keys(drugIntakesByTime).length > 1 && (
                  <div className={classes.actionsContainer}>
                    <div>
                      {activeStep > 0 && (
                        <IconButton
                          aria-label="back"
                          color="primary"
                          variant="contained"
                          onClick={handleBack}
                          className={classes.navButton}
                        >
                          <NavigateBeforeIcon />
                        </IconButton>
                      )}

                      {activeStep !==
                        Object.keys(drugIntakesByTime).length - 1 && (
                        <IconButton
                          aria-label="next"
                          color="primary"
                          variant="contained"
                          onClick={handleNext}
                          className={classes.navButton}
                        >
                          <NavigateNextIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                )}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
