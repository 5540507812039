import React, { useState, useEffect } from 'react';
import { getQrKey, getQrState } from '../../actions/user';
import { QRCodeSVG } from 'qrcode.react';
import useInterval from '../../hooks/useInterval';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import { useUserStateDispatch } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';
import { makeStyles } from '@mui/styles';
import ShowResponse from '../FormComponents/ShowResponse';
import { hhMmSs } from '../../helpers/utils';
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const useStyles = makeStyles((theme) => ({
  refreshButton: {
    height: 46,
    textTransform: 'none',
    width: 240,
  },
  errorMessage: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

function SignInQr({ setActiveTabId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // global
  const {
    userDispatch,
    userState: { appInfo, serverResponse, isLoaded },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();
  // local
  const [data, setData] = useState({ qrLoaded: false });
  const [counter, setCounter] = React.useState(0);

  // get start data
  useEffect(() => {
    setData({ qrLoaded: false });
    getQrKey(setData);
  }, []);

  // Set Counter
  useEffect(() => {
    if (data?.lifeTime != null) setCounter(data?.lifeTime);
    //console.log(data);
  }, [data?.lifeTime]);

  // Counter
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  // Check state
  useInterval(
    () => {
      getQrState(
        data?.key,
        userDispatch,
        language,
        navigate,
        appInfo?.isAnonymousChildrenEnabled,
      );
    },
    data?.key && counter > 0,
    1000,
  );

  const value = data?.key
    ? `mobiapp://qrCodeLogin/${data?.key}`
    : null;
  //console.log('state', state);

  return (
    <>
      <Typography variant="h3" mt={2} mb={5} textAlign="center">
        {t('SIGN.IN_BY_QR')}
      </Typography>

      <ShowResponse serverResponse={serverResponse} />

      {!isLoaded ? (
        <Loading />
      ) : data.qrLoaded ? (
        <Grid container spacing={2} mb={5}>
          <Grid size={12} textAlign="center">
            {value != null && <QRCodeSVG value={value} size={300} />}
          </Grid>
          <Grid size={12} textAlign="center">
            {counter > 0 ? (
              <>
                <Typography variant="body2" mb={3}>
                  {t('SIGN.IN_BY_QR_INFO')}
                </Typography>

                <Typography variant="body1">
                  {t('SIGN.IN_BY_QR_LIFETIME', {
                    sec: hhMmSs(counter),
                  })}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2" mb={3}>
                  {t('SIGN.IN_BY_QR_INFO1', {
                    sec: hhMmSs(data?.lifeTime),
                  })}
                </Typography>
                <Button
                  onClick={() => {
                    setData({ qrLoaded: false });
                    getQrKey(setData);
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.refreshButton}
                >
                  {t('SIGN.IN_BY_QR_REFRESH')}
                </Button>
              </>
            )}
          </Grid>

          <Grid size={12} textAlign="center">
            <Button
              color="primary"
              variant="text"
              onClick={() => setActiveTabId(0)}
            >
              {t('COMPONENT.BACK_ONE_STEP')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default SignInQr;
