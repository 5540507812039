import React, { useMemo } from 'react';

const PrescribedDrugsContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_PRESCRIBEDDRUGS':
      return {
        ...state,
        prescribedDrugs: {
          ...state.prescribedDrugs,
          isLoaded: true,
          ...action.payload,
        },
      };

    case 'REST_PRESCRIBEDDRUGS':
      return {
        ...state,
        prescribedDrugs: {
          isLoaded: false,
          data: [],
          responseError: null,
          drugIntake: {
            state: null,
            id: null,
            isConfirm: false,
            isSubmited: false,
            clinicId: null,
            isLoaded: false,
          },
        },
      };
    case 'REST_ALERT_PRESCRIBEDDRUGS':
      return {
        ...state,
        prescribedDrugs: {
          ...state.prescribedDrugs,
          drugIntake: {
            state: null,
            id: null,
            isConfirm: false,
            isSubmited: false,
            clinicId: null,
            isLoaded: false,
          },
        },
      };

    case 'FETCH_PRESCRIBEDDRUGS_ERROR':
      return {
        ...state,
        prescribedDrugs: {
          ...state.prescribedDrugs,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    case 'SET_PRESCRIBEDDRUGS_STATE':
      return {
        ...state,
        prescribedDrugs: {
          ...state.prescribedDrugs,
          data: state.prescribedDrugs.data.map((item) => ({
            ...item,
            drugIntakes: item.drugIntakes.map((it) =>
              action.payload.data === true &&
              it.id === state.prescribedDrugs.drugIntake.id
                ? {
                    ...it,
                    state: state.prescribedDrugs.drugIntake.state,
                  }
                : {
                    ...it,
                  },
            ),
          })),
          drugIntake: {
            ...state.prescribedDrugs.drugIntake,
            isLoaded: true,
          },
        },
      };
    case 'CHANGE_STATE_CONFIRM':
      return {
        ...state,
        prescribedDrugs: {
          ...state.prescribedDrugs,
          drugIntake: {
            ...state.prescribedDrugs.drugIntake,
            ...action.payload,
            isConfirm: true,
          },
        },
      };
    case 'CHANGE_STATE_SUBMITED':
      return {
        ...state,
        prescribedDrugs: {
          ...state.prescribedDrugs,
          drugIntake: {
            ...state.prescribedDrugs.drugIntake,
            ...action.payload,
            isSubmited: true,
            isLoaded: false,
          },
        },
      };

    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const PrescribedDrugsProvider = ({ children }) => {
  const [state, setState] = React.useReducer(rootReducer, {
    prescribedDrugs: {
      isLoaded: false,
      data: [],
      responseError: null,
      drugIntake: {
        state: null,
        id: null,
        isConfirm: false,
        isSubmited: false,
        clinicId: null,
      },
    },
  });

  const value = useMemo(() => ({ state, setState }), [state]);

  return (
    <PrescribedDrugsContext.Provider value={value}>
      {children}
    </PrescribedDrugsContext.Provider>
  );
};

const usePrescribedDrugsState = () => {
  const context = React.useContext(PrescribedDrugsContext);
  return context;
};

export {
  PrescribedDrugsProvider,
  PrescribedDrugsContext,
  usePrescribedDrugsState,
};
