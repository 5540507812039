import i18next from 'i18next';
import { isValidEmail } from '../../helpers/validators';

export default function validate(values) {
  let errors = {};

  // if (!values?.clinicId) {
  //   errors.clinicId = i18next.t('SIGN.UP_EMPTY_CLINIC');
  // }

  if (values.email != null && !values?.email) {
    errors.email = i18next.t('SIGN.UP_EMPTY_EMAIL');
  } else if (values.email != null && !isValidEmail(values.email)) {
    errors.email = 'Неверный email';
  }

  if (!values?.password) {
    errors.password = i18next.t('SIGN.UP_EMPTY_PASS');
  }

  return errors;
}
