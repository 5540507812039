import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import { dateToHL7, hl7ToUtc } from '../helpers/dateFormat';
import { DEFAULT_COUNT_SIZE } from '../context/VisitContext';
import dayjs from 'dayjs';

export const DEFAULT_COUNT = 5;
export const NUM_DAYS_RANGE = 6;

export const itemId = (it) =>
  `${it?.doctor?.id}-${it?.doctor?.clinicId}-${it?.doctor?.plSubjId}-${it?.doctor?.specializationInfo}`;

export function getDefaultVisitKind(
  visitKinds,
  isOnline,
  specializationId,
) {
  // в первую очередь фильтруем по online/offline
  const matchedVisitKinds = visitKinds.filter(
    (it) => it.isOnline === isOnline,
  );
  if (matchedVisitKinds.length === 0) return;
  // если они все же есть, то ищем тип приема по-умолчанию
  return (
    (specializationId !== null &&
      matchedVisitKinds.find(
        (it) => it.specializationId === specializationId,
      )) || // если выбран фильтр  specializationId
    matchedVisitKinds.find((it) => it.isDefaultWeb) || // Он либо isDefaultWeb
    matchedVisitKinds.find((it) => it.isDefault) || // либо isDefault
    (specializationId !== null && matchedVisitKinds[0]) ||
    (isOnline && matchedVisitKinds[0]) ||
    null
  ); // либо первый в списке (если нет isDefaultWeb и isDefault и только для online)
}

let countErrorRequers = 0;
export async function getDoctors(
  dispatch,
  typeAction = 'FETCH_DOCTORS',
  timeSlotsConsultationType = 'Offline', //Offline, Online
  selectedDate = null,
  specializationId = null,
  specialization = '',

  selectedName = '',
  selectedClinicId = 0,
  selectedVisitKindId = 0,
  startIndex = 0,
  count = DEFAULT_COUNT,
  withTimeSlotsOnly = false,
  favoriteOnly = false,
  doctorId = null,
  cachedTimeSlots = true,
  withPlanningOnly = false,
  sortOrder = false,
) {
  const date = new Date(selectedDate);
  const timeSlotsDate = dateToHL7(date);
  const timeSlotsDateTo = dateToHL7(
    dayjs(date).add(NUM_DAYS_RANGE, 'days').toDate(),
  );

  const body = {
    specializationId,
    specialization,
    startIndex,
    searchValue: selectedName || '',
    clinicId: selectedClinicId || 0,
    visitKindId: selectedVisitKindId || 0,
    withVisitKinds: !selectedVisitKindId ?? true,
    timeSlotsDate,
    timeSlotsDateTo,

    count,
    withTimeSlotsOnly,
    favoriteOnly,
    doctorId,
    timeSlotsConsultationType,
    sortOrder: sortOrder ? 'withSlotsFirst' : 'natural',
    cachedTimeSlots,
    withPlanningOnly,
  };

  await httpClient
    .get(api.url('GetDoctors'), body)
    .then(async ({ data }) => {
      await dispatch({
        type: typeAction,
        payload: doctorId != null ? data[0] : data,
      });
    })
    .catch((err) => {
      if (
        err?.response?.data?.code === 'SERVER_ERROR' &&
        countErrorRequers < 5
      ) {
        countErrorRequers++;
        // console.log(
        //   '==GetDoctors countErrorRequers',
        //   countErrorRequers,
        // );

        getDoctors(
          dispatch,
          typeAction,
          timeSlotsConsultationType,
          selectedDate,
          specializationId,
          specialization,
          selectedName,
          selectedClinicId,
          selectedVisitKindId,
          startIndex,
          count,
          withTimeSlotsOnly,
          favoriteOnly,
          doctorId,
          cachedTimeSlots,
          withPlanningOnly,
          sortOrder,
        );
        return;
      }
      countErrorRequers = 0;
      dispatch({
        type: 'FETCH_DOCTOR_ERROR',
        payload: getError(err),
      });
      console.log(
        '--------- err GetDoctors--------- \n',
        getError(err),
      );
    });
}

export async function getDoctorsTimeSlots({
  setState,
  itemId,
  startDate,
  clinicId,
  doctorId,
  plSubjId,
  visitKindId,
  cached = false,
  isLocalState = false,
  consultationType = 'Offline', //Offline, Online,
  isDateRange = true,
}) {
  const date = startDate;

  const dateTo = dateToHL7(
    dayjs(hl7ToUtc(startDate)).add(NUM_DAYS_RANGE, 'days'),
  );
  setState(
    !isLocalState
      ? {
          type: 'FETCH_DOCTOR_TIME_SLOT',
          payload: {
            timeSlotsIsLoaded: false,
            itemId,
            timeSlots: [],
          },
        }
      : {
          timeSlotsLoaded: false,
          timeSlots: [],
          responseError: null,
        },
  );
  const body = {
    doctorId,
    plSubjId,
    clinicId,
    visitKindId,
    date,
    dateTo: isDateRange ? dateTo : undefined,
    cached,
    consultationType,
  };
  await httpClient
    .get(api.url('GetDoctorsTimeSlots'), body)
    .then(({ data }) => {
      //console.log('GetDoctorsTimeSlots data', data);

      setState(
        !isLocalState
          ? {
              type: 'FETCH_DOCTOR_TIME_SLOT',
              payload: {
                timeSlotsIsLoaded: true,
                doctorId,
                timeSlots: ifEmptyArr(data),
                itemId,
              },
            }
          : {
              timeSlotsLoaded: true,
              timeSlots: ifEmptyArr(data),
              responseError: null,
            },
      );
    })
    .catch((err) => {
      setState(
        !isLocalState
          ? {
              type: 'FETCH_DOCTOR_ERROR',
              payload: getError(err),
            }
          : {
              timeSlotsLoaded: true,
              timeSlots: [],
              responseError: getError(err),
            },
      );
    });
}

export async function addVisit({
  setState,
  doctor,
  mmkId,
  visitKindId,
  visitDate,
  startDate,
  contactPhone,
  patientDirectionId,
  isOnline = false,
  comment = '',
}) {
  setState({
    type: 'ADD_VISIT_SUBMITED',
  });

  const body = {
    plSubjId: doctor.plSubjId,
    clinicId: doctor.clinicId,
    mmkId,
    visitKindId,
    visitDate,
    contactPhone,
    patientDirectionId,
    comment,
  };

  await httpClient
    .post(api.url('AddVisit'), body)
    .then(({ data }) => {
      console.log('ADD_VISIT data', data);
      setState({
        type: 'ADD_VISIT',
        payload: {
          isLoaded: true,
          data,
          visitDate,
          plSubjId: doctor.plSubjId,
          clinicId: doctor.clinicId,
        },
      });

      getDoctorsTimeSlots({
        setState: setState,
        itemId: itemId({ doctor }),
        startDate,
        clinicId: doctor.clinicId,
        doctorId: doctor.id,
        plSubjId: doctor.plSubjId,
        visitKindId,
        cached: true,
        consultationType: isOnline ? 'Online' : 'Offline',
      });
    })
    .catch((serverError) => {
      getDoctorsTimeSlots({
        setState: setState,
        itemId: itemId({ doctor }),
        startDate,
        clinicId: doctor.clinicId,
        doctorId: doctor.id,
        plSubjId: doctor.plSubjId,
        visitKindId,
        cached: true,
        consultationType: isOnline ? 'Online' : 'Offline',
      });

      setState({
        type: 'ADD_VISIT_SERVER_ERR',
        payload: {
          isLoaded: true,
          serverError: getError(serverError),
        },
      });
    });
}

export async function addAnonymousVisit(
  setState,
  clinicId,
  plSubjId,
  visitKindId,
  visitDate,

  firstName,
  lastName,
  middleName,
  patientDirectionId = 0,
  birthDate,
  gender,
  phone,
  email,
) {
  setState({
    type: 'ADD_VISIT_SUBMITED',
  });

  const body = {
    clinicId,
    plSubjId,
    visitKindId,
    visitDate,

    firstName,
    lastName,
    middleName,
    patientDirectionId,
    birthDate,
    gender,
    phone,
    email,
  };

  await httpClient
    .post(api.url('AddAnonymousVisit'), body)
    .then(({ data }) => {
      setState({
        type: 'ADD_VISIT',
        payload: {
          isLoaded: true,
          data,
          visitDate,
          plSubjId,
          clinicId,
        },
      });
    })
    .catch((err) => {
      setState({
        type: 'ADD_VISIT_SERVER_ERR',
        payload: {
          isLoaded: true,
          serverError: getError(err),
        },
      });
    });
}

export async function sendRequestEmailToClinic({
  setState,
  lastName,
  firstName,
  middleName,
  phone,
  plSubjId,
  visitDate,
  clinicId,
  reason,
}) {
  const body = {
    lastName,
    firstName,
    middleName,
    phone,
    plSubjId,
    visitDate,
    clinicId,
    reason,
  };
  setState({
    type: 'ADD_VISIT_SUBMITED',
  });
  await httpClient
    .post(api.url('SendVisitRequestEmailToClinic'), body)
    .then(({ data }) => {
      setState({
        type: 'ADD_VISIT',
        payload: {
          isLoaded: true,
          data,
          visitDate,
          plSubjId,
          clinicId,
        },
      });
    })
    .catch((err) => {
      setState({
        type: 'ADD_VISIT_SERVER_ERR',
        payload: {
          isLoaded: true,
          serverError: getError(err),
        },
      });
    });
}

export async function doctorFreeDates(
  setDocFreeDates,
  selectedDate,
  clinicId,
  doctorId,
  visitKindId,
  dateFrom,
  dateTo,
  plSubjId,
  consultationType = 'Offline', //Offline, Online,
  cached = false,
) {
  const body = {
    clinicId,
    doctorId,
    visitKindId,
    dateFrom,
    dateTo,
    plSubjId,
    consultationType,
    cached,
  };
  setDocFreeDates({ isLoaded: false, data: [], selectedDate });
  await httpClient
    .get(api.url('DoctorFreeDates'), body)
    .then(({ data }) => {
      if (!isEmpty(data)) {
        setDocFreeDates({ isLoaded: true, data, selectedDate });
      } else
        setDocFreeDates({ isLoaded: true, data: [], selectedDate });
    })
    .catch((err) => {
      console.log(
        '---Error getting DoctorFreeDates--- \n',
        getError(err),
        setDocFreeDates({ isLoaded: true, data: [], selectedDate }),
      );
    });
}

export async function getDirections(
  dispatch,
  mmkId,
  clinicId = 0,
  specificRecordId = 0,
  allAccess = true,
) {
  const body = {
    mmkId,
    clinicId,
    allAccess,
    specificRecordId,
  };
  try {
    const { data } = await httpClient.get(
      api.url('GetDirections'),
      body,
    );
    console.log(
      '--------- request GetDirections--------- \n',
      body,
      '--------- response GetDirections--------- \n',
      data,
    );
    dispatch({
      type: 'FETCH_DIRECTIONS',
      payload: {
        isLoaded: true,
        data: ifEmptyArr(data),
      },
    });
  } catch (err) {
    console.log('--------- err GetMmkHeaders--------- \n', err);
    dispatch({
      type: 'FETCH_DIRECTIONS_ERROR',
      payload: getError(err),
    });
  }
}

export async function addDoctorReview(
  dispatch,
  clinicId,
  doctorId,
  isFavorite,
  rating,
  text,
) {
  const body =
    isFavorite != null
      ? {
          clinicId,
          doctorId,
          isFavorite,
        }
      : {
          clinicId,
          doctorId,
          rating,
          text,
        };

  try {
    const { data } = await httpClient.post(
      api.url('AddDoctorReview'),
      body,
    );
    console.log('AddDoctorReview data ', data);
    dispatch({
      type: 'SET_RATING_DATA',
      payload: { response: data },
    });
  } catch (err) {
    console.log('= axios ERROR AddDoctorReview =', getError(err));
    dispatch({
      type: 'SET_RATING_DATA',
      payload: { response: getError(err) },
    });
  }
}

export async function doctorReviews({
  dispatch,
  clinicId,
  doctorId,
  startIndex = 0,
  count = DEFAULT_COUNT_SIZE,
}) {
  const body = {
    clinicId,
    doctorId,
    startIndex,
    count,
  };
  dispatch({
    type: 'RESET_RATING_LOADING',
  });
  try {
    const { data } = await httpClient.get(
      api.url('GetDoctorReviews'),
      body,
    );
    //console.log('GetDoctorReviews data ', data);
    dispatch({
      type: 'SET_RATING_FETCH',
      payload: { data },
    });
  } catch (err) {
    console.log('= axios ERROR AddDoctorReview =', getError(err));
    dispatch({
      type: 'SET_RATING_DATA',
      payload: { responseError: getError(err) },
    });
  }
}

export async function customCheck({
  setCustomCheck,
  clinicId,
  plSubjId,
  plExamId,
  fmClinkPatientsId,
  param1,
  param2,
  param3,
  param4,
  mmkId,
}) {
  const body = {
    clinicId,
    plSubjId,
    plExamId,
    fmClinkPatientsId,
    param1,
    param2,
    param3,
    param4,
    mmkId,
  };

  try {
    const { data } = await httpClient.post(
      api.url('CustomCheck'),
      body,
    );
    // data = {
    //   status: 2,
    //   statusDescription: null,
    //   msgText:
    //     'Данная услуга может не пройти по вашему договору ДМС и требует дополнительного согласования и может быть платной.',
    // };

    setCustomCheck(data);

    //console.log('GetDoctorReviews data ', data);
  } catch (err) {
    console.log('= axios ERROR CustomCheck =', getError(err));
  }
}
