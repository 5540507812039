import mobimed from './mobimed';
import drAnna from './drAnna';
import gms from './gms';
import medincenter from './medincenter';
import medswiss from './medswiss';
import mediadoc from './mediadoc';
import minfin from './minfin';
import pimu from './pimu';
import ncn from './ncn';
import sibgmu from './sibgmu';
import mositalmed from './mositalmed';
import skyfert from './skyfert';
import white_fang from './white_fang';
import kalinchenko from './kalinchenko';
import celt from './celt';
import vetcenter from './vetcenter';
import test from './test';

function custom() {
  return process.env.REACT_APP_THEME === 'drAnna'
    ? drAnna
    : process.env.REACT_APP_THEME === 'gms'
    ? gms
    : process.env.REACT_APP_THEME === 'medincenter'
    ? medincenter
    : process.env.REACT_APP_THEME === 'medswiss'
    ? medswiss
    : process.env.REACT_APP_THEME === 'mediadoc'
    ? mediadoc
    : process.env.REACT_APP_THEME === 'minfin'
    ? minfin
    : process.env.REACT_APP_THEME === 'pimu'
    ? pimu
    : process.env.REACT_APP_THEME === 'ncn'
    ? ncn
    : process.env.REACT_APP_THEME === 'sibgmu'
    ? sibgmu
    : process.env.REACT_APP_THEME === 'mositalmed'
    ? mositalmed
    : process.env.REACT_APP_THEME === 'skyfert'
    ? skyfert
    : process.env.REACT_APP_THEME === 'white_fang'
    ? white_fang
    : process.env.REACT_APP_THEME === 'kalinchenko'
    ? kalinchenko
    : process.env.REACT_APP_THEME === 'celt'
    ? celt
    : process.env.REACT_APP_THEME === 'vetcenter'
    ? vetcenter
    : process.env.REACT_APP_THEME === 'test'
    ? test
    : mobimed;
}
export default custom();
