import React from 'react';
import { makeStyles } from '@mui/styles';
import Chat from '../../components/Chat';
import { useUserStateDispatch } from '../../context/UserContext';
const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: `calc(100vh - ${theme.spacing(25)})`,
      overflow: 'hidden',
    },
  };
});

const ChatPage = () => {
  const classes = useStyles();
  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();
  return (
    <div className={classes.container}>
      <Chat lang={lang} />
    </div>
  );
};

export default ChatPage;
