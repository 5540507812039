import i18next from 'i18next';
import { EnpValidator } from '@pmt/validation';
import {
  getReqFields,
  isPastDate,
  isValidEmail,
  isValidPhone,
  notAvalibleBirthDate,
  reqRegField,
  rexFio,
} from '../../helpers/validators';
import dayjs from 'dayjs';

export function validate(values, appInfo) {
  let errors = {};

  if (
    values.email !== null &&
    values.email !== '' &&
    !isValidEmail(values.email)
  ) {
    errors.email = i18next.t('SIGN.UP_NOT_VALID_EMAIL');
  }

  if (
    values.phone !== null &&
    values.phone !== '' &&
    !isValidPhone(values.phone, appInfo.countryCode)
  ) {
    errors.phone = i18next.t('COMPONENT.INCORECT_PHONE');
  }

  if (
    (errors.email != null && errors.phone != null) ||
    (!values.email && !values.phone)
  )
    errors.emailAndPhone = i18next.t(
      'COMPONENT.NEEDED_EMAIL_OR_PHONE',
    );

  if (
    reqRegField(appInfo.requiredRegistrationFields, 'firstName') &&
    !values.firstName
  ) {
    errors.firstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (
    reqRegField(appInfo.requiredRegistrationFields, 'lastName') &&
    !values.lastName
  ) {
    errors.lastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }

  if (values.firstName && !rexFio.test(values.firstName)) {
    errors.firstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values.lastName && !rexFio.test(values.lastName)) {
    errors.lastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }
  if (values.middleName && !rexFio.test(values.middleName)) {
    errors.middleName = i18next.t('SIGN.UP_NOT_VALID_MIDDLE_NAME');
  }

  if (
    reqRegField(appInfo.requiredRegistrationFields, 'birthDate') &&
    !values.birthDate
  ) {
    errors.birthDate = i18next.t('COMPONENT.EMPTY_BIRTHDAY');
  } else if (
    values.birthDate &&
    (!dayjs(values.birthDate).isValid() ||
      notAvalibleBirthDate(values.birthDate, [
        appInfo.ageLimitLow,
        appInfo.ageLimitHigh,
      ]))
  ) {
    errors.birthDate = i18next.t('COMPONENT.INVALID_BIRTH_DATE');
  }

  if (values.dmsName && !values.dmsName) {
    errors.dmsName = i18next.t('COMPONENT.NEED_DMS_NAME');
  }
  if (values.dmsEndDate != null && !values.dmsEndDate.isValid()) {
    console.log('values.dmsEndDate', values.dmsEndDate);
    errors.dmsEndDate = i18next.t('CALL_DOCTOR.INVALID_DMS_END_DATE');
  }
  if (values.dmsEndDate != null && isPastDate(values.dmsEndDate)) {
    errors.dmsEndDate = i18next.t('CALL_DOCTOR.INVALID_DMS_END_DATE');
  }
  if (values.dmsName && !values.dmsNumber) {
    errors.dmsNumber = i18next.t('COMPONENT.NEED_DMS_NUMBER');
  }
  if (values.omsName && !values.omsNumber) {
    errors.omsNumber = i18next.t('COMPONENT.NEED_OMS_NUMBER');
  }
  if (values.omsNumber) {
    const validationResult = EnpValidator.validate(values.omsNumber);
    if (!validationResult.valid)
      errors.omsNumber = i18next.t('COMPONENT.INVALID_OMS_NUMBER');
  }
  if (
    reqRegField(appInfo.requiredRegistrationFields, 'gender') &&
    !values.gender
  ) {
    errors.gender = i18next.t('SIGN.UP_EMPTY_GENDER');
  }

  getReqFields(appInfo.requiredRegistrationFields).forEach(
    (field) => {
      if (values[field] == null) {
        errors.reqFields = 'error requiredRegistrationFields';
      }
    },
  );
  // console.log('validate values', values);
  // console.log('validate errors', errors);
  return errors;
}
