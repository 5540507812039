import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient from '../api/httpClient';
import api from '../api/apiParams';

export const DEFAULT_PAGE_SIZE = 100;

export async function getNotifications({
  setState,
  mmkId = 'parent',
  clinicId = 0,
  typeFilter = [],
  searchValue = null,
  readState = 'All',
  startIndex = 0,
  count = DEFAULT_PAGE_SIZE,
}) {
  const req = {
    name: 'GetNotifications',
    body: {
      startIndex,
      mmkId,
      clinicId,
      count,
      readState,
      typeFilter,
      searchValue,
    },
  };
  //console.log('= typeFilter =', typeFilter);
  setState({
    type: 'NOTIFICATIONS_LOADING',
  });
  return await httpClient
    .post(api.url(req.name), req.body)
    .then(async ({ data }) => {
      //console.log('data', data);
      if (
        (!isEmpty(data) && readState === 'Unread') ||
        readState === 'All'
      )
        setState({
          type: 'FETCH_NOTIFICATIONS',
          payload: {
            data,
          },
        });
      else
        setState({
          type: 'LOADED',
        });

      return ifEmptyArr(data);
    })
    .catch((e) => {
      setState({
        type: 'FETCH_NOTIFICATIONS',
        payload: {
          data: [],
        },
      });

      console.log('= axios ERROR GetNotifications =', e);
      return [];
    });
}

export async function getCount(
  setState,
  mmkId = 'parent',
  clinicId = 0,
) {
  const req = {
    name: 'NotificationCount',
    body: {
      mmkId,
      clinicId,
      readState: 'Unread',
    },
  };

  await httpClient
    .post(api.url(req.name), req.body)
    .then(({ data }) => {
      // console.log(
      //   '--------- response NotificationCount--------- \n',
      //   data,
      // );

      setState({
        type: 'FETCH_NOTIFICATIONS',
        payload: {
          unreadCount: data,
        },
      });
    })
    .catch((e) => console.log('= axios ERROR GetNotifications =', e));
}

export async function updateNotifications({
  setState,
  mmkId,
  clinicId,
  isRead = true,
}) {
  try {
    const data = await getNotifications({
      setState,
      mmkId,
      clinicId,
      readState: 'Unread',
    });

    if (!isEmpty(data)) {
      data
        .map((item) => item.id)
        .forEach(async (notificationId) => {
          const body = {
            mmkId,
            clinicId,
            notificationId,
            isRead,
          };
          return await httpClient.post(
            api.url('UpdateNotificationState'),
            body,
          );
        });
    }
  } catch (e) {
    console.log('= axios ERROR GetNotifications =', e);
  }
}
