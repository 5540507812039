import { useState } from 'react';
import { useVisitsState } from '../../context/VisitsContext';
import isEmpty from '../../helpers';

const useModalChatVisit = () => {
  const { setState } = useVisitsState();

  const [modalChatVisit, setModalChatVisit] = useState(false);

  const toggleModalChatVisit = (visit) => {
    if (!isEmpty(visit)) {
      setState({
        type: 'SET_VISIT',
        payload: { ...visit },
      });
    }
    if (modalChatVisit) {
      setState({
        type: 'RESET_VISIT',
      });
    }
    setModalChatVisit((prev) => !prev);
  };
  return { modalChatVisit, setModalChatVisit, toggleModalChatVisit };
};

export const useModalChatVisitLocal = () => {
  const [modalChatVisit, setModalChatVisit] = useState(false);

  const [chatInfo, setChatInfo] = useState({});

  const toggleModalChatVisit = (data) => {
    if (!isEmpty(data)) {
      setChatInfo({
        ...data,
      });
    }
    setModalChatVisit((prev) => !prev);
  };

  return {
    modalChatVisit,
    setModalChatVisit,
    toggleModalChatVisit,
    chatInfo,
    setChatInfo,
  };
};
export default useModalChatVisit;
