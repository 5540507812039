import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VisitProvider } from '../../context/VisitContext';
import DoctorsContainer from './DoctorsContainer';
import { useUserStateDispatch } from '../../context/UserContext';

export default function Visit() {
  const {
    userState: { appInfo, isAuthenticated },
  } = useUserStateDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && appInfo.requireStartPageAuth)
      navigate('/sign');
  }, []);

  return (
    <VisitProvider>
      <DoctorsContainer />
    </VisitProvider>
  );
}
