import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';

import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { Box, Button, useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.grey[100]}`,
    width: 195,
    height: 32,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover, &:focus': {
      border: '1px solid #fff',
      backgroundColor: theme.palette.background.light,
    },
    [theme.breakpoints.down('md')]: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  box: {
    width: 220,
    [theme.breakpoints.down('md')]: {
      width: 210,
    },
    textAlign: 'right',
  },
}));

export default function СlinicWebsiteButtonHerro() {
  const classes = useStyles();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <Box className={classes.box} textAlign="end">
      <Button
        variant={isMobile ? 'outlined' : 'contained'}
        color="primary"
        component={Link}
        to={appInfo.clinicWebsiteUrl}
        className={classes.button}
        target="_blank"
      >
        {t('COMPONENT.REFER_BUTT')}
      </Button>
    </Box>
  );
}
