import React from 'react';
import { Alert, AlertTitle, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function AnonymousNotEnabled() {
  const { t } = useTranslation();

  return (
    <Stack sx={{ width: '100%', mt: 6 }} spacing={2}>
      <Alert severity="error">
        <AlertTitle>{t('VISIT.ANONYMOUS_NOT_ENABLED')}</AlertTitle>
        {t('VISIT.ANONYMOUS_NOT_ENABLED_NOTE')}
      </Alert>
    </Stack>
  );
}
