import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  filters: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(
      1,
    )} ${theme.spacing(2)}`,
    marginBottom: theme.spacing(4),
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.bgLight.one}`,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 25px rgba(78, 72, 190, 0.08)',
    borderRadius: theme.spacing(1),

    [theme.breakpoints.down('lg')]: {
      minWidth: 800,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  filtersFixedWidth: {
    position: 'fixed',
    maxWidth: 293,
    zIndex: 100,
  },
  filtersFixed: {
    position: 'fixed',
    zIndex: 100,
  },

  buttonGroupCastom: {
    width: '100%',
    marginBottom: 8,
  },

  container: {
    position: 'relative',
  },
}));
