import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
//components
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useUserStateDispatch } from '../../context/UserContext';
import { esiaAuthLink } from '../../actions/user';
import { CircularProgress } from '@mui/material';
import { ReactComponent as LogoGosuslugi } from '../../images/icons/logo_gosuslugi.svg';

const useStyles = makeStyles(() => ({
  button: {
    height: 54,
    textTransform: 'none',
    width: '100%',
  },
  iframeContent: {
    width: 620,
    height: '98%',
    minHeight: 600,
    border: 0,
    borderRadius: 4,
  },
}));

const ButtonEsiaLink = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userDispatch } = useUserStateDispatch();
  const [eisa, setEisa] = useState({ link: null, isLoaded: true });
  const handelEsiaAuthLink = () => {
    esiaAuthLink(setEisa, userDispatch);
  };
  //console.log('eisa.link', eisa.link);

  useEffect(() => {
    if (eisa.link && eisa.link.length > 4) {
      window.location.href = eisa.link;
      // window.winEisa = window.open(
      //   eisa.link,
      //   'Портал государственных услуг Российской Федерации',
      //   'width=800,height=760,left=240,top=180',
      // );
      // const winOpenTimer = setInterval(function () {
      //   if (window.winEisa.closed) {
      //     //  console.log('!!!!!!!!   closeModal   !!!!!!!!');
      //     setEisa({ link: 'done', isLoaded: true });
      //     window.focus();
      //     clearInterval(winOpenTimer);
      //   } else {
      //     window.winEisa.blur();
      //     window.winEisa.focus();
      //   }
      // }, 1500);
    }
    // if (eisa.link === 'done') {
    //   window.location.reload();
    // }
  }, [eisa.link]);
  return !eisa.isLoaded ? (
    <CircularProgress size={26} />
  ) : (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handelEsiaAuthLink}
        className={classes.button}
        endIcon={<LogoGosuslugi />}
      >
        {t('SIGN.IN_EISA')}
      </Button>
      {/* {eisa.link != null && eisa.link && (
        <Modale
          open={eisa.link != null ? true : false}
          toggleModal={() => {
            userDispatch({
              type: 'LOADED',
            });
          }}
          title="Портал государственных услуг Российской Федерации"
          height={720}
          isScrollHide={true}
          maxWidth="md"
        >
          <iframe
            id="iframe_id"
            src={eisa.link}
            className={classes.iframeContent}
            title="esia"
            height="100%"
            width="100%"
          >
            {t('COMPONENT.BROWSER_NOT_SUUPORT_FRAMES')}
          </iframe>
        </Modale>
      )} */}
    </>
  );
};
export default ButtonEsiaLink;
