import React from 'react';
import { Fab } from '@mui/material';
import { ReactComponent as MessChatIcon } from '../../images/message_chat.svg';
import Modale from '../Modals/Modale';
import Chat from '../Chat';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import isEmpty from '../../helpers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const ChatOper = ({ lang, chatInfo, modalChat, toggleModalChat }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isEmpty(chatInfo)) {
    return null;
  }
  return !modalChat ? (
    <div onClick={toggleModalChat} className={classes.openOperChat}>
      <Fab color="primary" aria-label="chat">
        <MessChatIcon />
      </Fab>
      <Typography variant="body2">
        {t('MAINMENU.CHAT_OPER')}
      </Typography>
      {chatInfo?.unreadCount !== 0 && (
        <Typography variant="body2">
          ({chatInfo?.unreadCount})
        </Typography>
      )}
    </div>
  ) : (
    <Modale
      open={modalChat}
      toggleModal={toggleModalChat}
      title={t('EVENTS_VISITS.TITLE_ONLINE_CONF')}
      mx={0}
      maxWidth="lg"
      height={700}
      backdrop={true}
      isScrollHide={true}
    >
      <Chat
        lang={lang}
        activeGroupId={chatInfo?.groupId}
        inModale={true}
        hideRooms={true}
      />
    </Modale>
  );
};

export default ChatOper;
