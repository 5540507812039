/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Autocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function Clinics({
  selectedClinicId,
  handleClinicChange,
  disabled = false,
}) {
  const { t } = useTranslation();
  const {
    userState: { clinics },
  } = useUserStateDispatch();

  const handleChange = (e, v) => {
    handleClinicChange(v?.id);
  };
  const value = (clinics || []).find(
    (item) => item?.id === parseInt(selectedClinicId, 10),
  );
  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option) => option.name + option.address,
  });

  return (
    !isEmpty(clinics) &&
    clinics.length > 1 && (
      <Autocomplete
        //id="checkboxes-tags-demo"
        //freeSolo

        disabled={disabled}
        value={value || null}
        options={clinics}
        onChange={handleChange}
        isOptionEqualToValue={(option) =>
          option.id === parseInt(selectedClinicId, 10)
        }
        getOptionLabel={(option) =>
          option?.name != null ? option.name : ''
        }
        filterOptions={filterOptions}
        renderOption={(props, option) => (
          <Grid container {...props}>
            <Grid>{option.name}</Grid>
            <Grid>
              <Typography variant="subtitle2">
                {option.address}
              </Typography>
            </Grid>
          </Grid>
        )}
        noOptionsText={t('EMPTY_DATA.NO_OPTIONS')}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            label={t('COMPONENT.CLINICS')}
            fullWidth
            variant="outlined"
          />
        )}
      />
    )
  );
}
