import React from 'react';
import FIOandPhoneForm from '../../components/Forms/FIOandPhoneForm';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    height: theme.spacing(6),
    marginTop: theme.spacing(4),
    minWidth: theme.spacing(30),
  },

  alignCenter: {
    textAlign: 'center',
  },
  checkbox: {
    alignItems: 'flex-start',
  },
  checkboxText: {
    marginTop: theme.spacing(1),
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function FIOandPhone({
  setActiveTabId,
  values,
  errors,
  setValues,
  setErrors,
  validate,
  handleChange,
  handleSubmit,

  handlePhoneChange,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: { appInfo, isLoaded },
  } = useUserStateDispatch();

  const disableButton =
    values?.lastName == null ||
    values?.firstName == null ||
    values?.phone == null ||
    !isEmpty(errors);
  return (
    <>
      <Typography
        variant="h4"
        style={{ textAlign: 'center', marginBottom: 24 }}
      >
        {t('VISIT.SET_USER_DATA')}
      </Typography>
      <FIOandPhoneForm
        values={values}
        errors={errors}
        handleChange={handleChange}
        setValues={setValues}
        setErrors={setErrors}
        validate={validate}
        appInfo={appInfo}
        handlePhoneChange={handlePhoneChange}
      />
      {!isLoaded ? (
        <CircularProgress size={26} className={classes.loginLoader} />
      ) : (
        <>
          <FormControlLabel
            className={classes.checkbox}
            disabled={disableButton}
            control={
              <Checkbox
                onChange={() => setActiveTabId(1)}
                color="primary"
              />
            }
            label={
              <div>
                <Typography
                  variant="h6"
                  className={classes.checkboxText}
                >
                  {t('COMPONENT.FORM_MAKE_APPOINTMENT_REG')}
                </Typography>
                <FormHelperText>
                  {t('SIGN.UP_HELPER_TEXT')}
                </FormHelperText>
              </div>
            }
          />
          <div className={classes.alignCenter}>
            <Button
              className={classes.buttonSubmit}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={disableButton}
            >
              {t('COMPONENT.BUT_SEND_EMAIL_DOCTOR')}
            </Button>
            <Typography
              style={{ textAlign: 'center', marginTop: 24 }}
            >
              {t('COMPONENT.A_U_HAVE_ACCOUN')}
              <Button
                color="primary"
                variant="text"
                onClick={() => setActiveTabId(0)}
              >
                {t('SIGN.IN_BUTTON')}
              </Button>
            </Typography>
          </div>
        </>
      )}
    </>
  );
}

export default FIOandPhone;
