import React, { useMemo } from 'react';
import { DEFAULT_PAGE_SIZE } from '../actions/notifications';

const NotificationsContext = React.createContext();

const rootReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_NOTIFICATIONS':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoaded: true,
          ...action.payload,
        },
      };
    case 'LOADED':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoaded: true,
        },
      };
    case 'NOTIFICATIONS_LOADING':
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoaded: false,
        },
      };
    case 'REST_NOTIFICATIONS':
      return {
        notifications: {
          isLoaded: false,
          data: [],
          startIndex: 0,
          count: DEFAULT_PAGE_SIZE,
          hasMore: true,
          unreadCount: 0,
        },

        notificationId: 0,
        readState: 'Unread',
        typeFilter: [],
        searchValue: null,
      };

    case 'SET_TYPE':
      return {
        ...state,
        typeFilter: action.payload,
      };
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload,
      };

    default:
      return { ...state };
  }
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const NotificationsProvider = ({ children }) => {
  const [state, setState] = React.useReducer(rootReducer, {
    notifications: {
      isLoaded: false,
      data: [],
      startIndex: 0,
      count: DEFAULT_PAGE_SIZE,
      hasMore: true,
      unreadCount: 0,
    },

    notificationId: 0,
    readState: 'Unread',
    typeFilter: [],
    searchValue: null,
  });
  const value = useMemo(() => ({ state, setState }), [state]);
  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotificationsState = () => {
  const context = React.useContext(NotificationsContext);
  return context;
};

export {
  NotificationsProvider,
  NotificationsContext,
  useNotificationsState,
};
