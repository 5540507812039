import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles, withStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as AppintmenMedswissIcon } from '../../images/appointment_medswiss.svg';
import Grid from '@mui/material/Grid2';
import config from '../../config';
import isEmpty from '../../helpers';
import { useUserStateDispatch } from '../../context/UserContext';
import СlinicWebsiteButtonHerro from '../Header/СlinicWebsiteButtonHerro';

const useStyles = makeStyles((theme) => {
  return {
    sideBar: {
      width: '100%',
      top: 86,
      height: 72,

      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.down('sm')]: {
        top: 72,
        height: 56,
      },
      background: theme.palette.sideBar.bg,
      boxShadow: '0px 2px 35px rgba(78, 72, 190, 0.08) !important',
    },

    menuContainer: {
      color: theme.palette.text.primary,
      maxWidth: 1242,
      padding: 0,
      margin: 'auto',
      width: '100%',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-end',
      },
    },

    activeItem: {
      color: theme.palette.sideBar.colorActive,
    },
    item: {
      color: theme.palette.sideBar.color,
    },

    menuMobile: {
      '& .MuiPaper-root': {
        background: theme.palette.sideBar.bg,
      },
    },
    tabsMenu: {
      width: '100%',
      '& .MuiTab-root': {
        textTransform: 'none',
        fontSize: '1rem',
        margin: '12px 18px',
        padding: '6px 2px',
        minWidth: 'auto',

        '&:first-child': {
          marginLeft: 0,
        },
      },
      '& .MuiTab-fullWidth': {
        whiteSpace: 'nowrap',
        flexGrow: 'unset',
        flexBasis: 'unset',
      },
      '& .MuiTab-textColorPrimary': {
        color: theme.palette.sideBar.color,
        '&:hover': {
          color: theme.palette.sideBar.colorHover,
        },
      },
      '& .MuiTab-textColorPrimary.Mui-selected': {
        color: theme.palette.sideBar.colorActive,
      },
    },
    indicator: {
      backgroundColor: theme.palette.warning.main,
      height: '3px',
    },
    indicatorLast: {
      display: 'none',
    },
  };
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: '#fff',
    background: theme.palette.baseButton.bgColor,
    borderRadius: 5,
    textTransform: 'none',

    fontStyle: 'normal',
    fontWeight: 400,
    boxshadow: '0px 5px 20px rgba(111, 102, 255, 0.4)',
    paddingLeft: 24,
    paddingRight: 20,
    marginLeft: 'auto',
    marginTop: 14,
    marginBottom: 14,

    [theme.breakpoints.down('sm')]: {
      margin: 12,
    },

    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      minWidth: '26px',
    },
    '& .MuiListItemIcon-root': {
      width: 16,
    },
    '& .MuiListItem-gutters': {
      paddingRight: 0,
    },
    '&:hover,&:focus': {
      color: '#fff',
      background: theme.palette.baseButton.bgColor,
      opacity: 0.8,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        fill: '#fff',
        color: '#fff',
      },
    },
  },
}))(MenuItem);

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SidebarSimple() {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const {
    userState: { isAuthenticated, appInfo, structure },
  } = useUserStateDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  const getLink = (to) => config.accessLink(isAuthenticated, to);

  const match = useCallback(
    (to) => location.pathname.startsWith(getLink(to)),
    [location.pathname],
  );
  const activeTab = structure.find((item) => match(item.link))?.id;

  const { t } = useTranslation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Item = ({ to, name /* children */ }) => {
    const isActiveClassName = () =>
      match(to) ? classes.activeItem : classes.item;

    return (
      <MenuItem
        onClick={handleClose}
        component={Link}
        to={getLink(to)}
        className={isActiveClassName()}
      >
        {name}
      </MenuItem>
    );
  };

  const LastItem = () => (
    <StyledMenuItem
      component={Link}
      to="visit"
      onClick={(e) => {
        handleClick(e);
        if (isMobile) handleClose();
      }}
      data-testid="visit"
    >
      {t('MAINMENU.APPOINTMENT')}
      <AppintmenMedswissIcon />
    </StyledMenuItem>
  );

  const accessMenu = (item) => {
    const part = item.link.split('/');
    //console.log('part', part[0]);
    if (isEmpty(appInfo.authMenuItems)) {
      return false;
    }
    return isAuthenticated
      ? appInfo.authMenuItems.includes(part[0]) ||
          appInfo.authMenuItems.includes(`${part[0]}/${part[1]}`)
      : appInfo.unAuthMenuItems.includes(part[0]);
  };

  const activeTabVal = !isEmpty(
    structure
      .filter(accessMenu)
      .find((item) => item.id === activeTab),
  )
    ? structure
        .filter(accessMenu)
        .find((item) => item.id === activeTab).link
    : false;

  return (
    <AppBar className={classes.sideBar} component="div">
      <div className={classes.menuContainer}>
        {isMobile ? (
          <Grid container spacing={3}>
            <Grid xs mt={0.2}>
              {appInfo.clinicWebsiteUrl && (
                <СlinicWebsiteButtonHerro />
              )}
            </Grid>
            <Grid xs textAlign="end" mr={1}>
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                className={classes.menuMobile}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {structure
                  .filter((item) => item.hasMainMenu === true)
                  .filter(accessMenu)
                  .map((item) => (
                    <Item
                      to={item.link}
                      data-testid={item.link}
                      name={item.title ?? t(`MAINMENU.${item.t}`)}
                      key={item.id}
                    />
                  ))}

                <LastItem />
              </Menu>
            </Grid>
          </Grid>
        ) : (
          <Tabs
            className={classes.tabsMenu}
            variant="fullWidth"
            value={activeTabVal}
            aria-label="nav tabs "
            TabIndicatorProps={{
              className: match('visit')
                ? classes.indicatorLast
                : classes.indicator,
            }}
            textColor="primary"
          >
            {structure
              .filter((it) => it.hasMainMenu)
              .filter(accessMenu)
              .map((item) => (
                <Tab
                  component={Link}
                  to={item.link}
                  label={item.title ?? t(`MAINMENU.${item.t}`)}
                  key={item.link}
                  value={item.link}
                  index={item.link}
                  data-testid={item.link}
                />
              ))}
            <LastItem />
          </Tabs>
        )}
      </div>
    </AppBar>
  );
}

export default SidebarSimple;
