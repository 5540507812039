import { useParams } from 'react-router-dom';
import { useUserStateDispatch } from '../../context/UserContext';

export default function useDoctorsParams() {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  /** ---------------- GET ------------------ */
  let { specIdParam, clinicId, isOnlineParam, fioParam, favPram } =
    useParams();
  if (
    specIdParam == null ||
    specIdParam === 'null' ||
    specIdParam === 'undefined' ||
    specIdParam === ''
  )
    specIdParam = null;
  if (specIdParam === null && appInfo.defaultSpecialization) {
    specIdParam = parseInt(appInfo.defaultSpecialization, 10);
  }
  if (specIdParam) {
    specIdParam = parseInt(specIdParam, 10);
  }
  if (
    fioParam == null ||
    fioParam === 'null' ||
    fioParam === 'undefined'
  )
    fioParam = null;
  if (
    clinicId == null ||
    clinicId === 'null' ||
    clinicId === 'undefined'
  )
    clinicId = 0;

  if (
    isOnlineParam == null ||
    isOnlineParam === 'null' ||
    isOnlineParam === 'undefined' ||
    isOnlineParam === 'false'
  )
    isOnlineParam = false;
  else isOnlineParam = true;

  if (
    favPram == null ||
    favPram === 'null' ||
    favPram === 'undefined' ||
    favPram === 'false'
  )
    favPram = false;
  else favPram = true;
  return { specIdParam, clinicId, isOnlineParam, fioParam, favPram };

  /** ---------------- END GET ------------------ */
}
