import React, { useState, useEffect } from 'react';
import { useUserStateDispatch } from '../../context/UserContext';
//import { useTranslation } from 'react-i18next';
import useInterval from '../../hooks/useInterval';
import { useNotificationsState } from '../../context/NotificationsContext';
import { IconButton } from '@mui/material';
import { NotificationsNone as NotificationsIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Badge } from '@mui/material';
import {
  updateNotifications,
  getNotifications,
  getCount,
} from '../../actions/notifications';
//import Modale from '../Modals/Modale';
//import InitAnketa from '../../pages/blank';
import UsersNotificationMenu from './UsersNotificationMenu';

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    fontSize: theme.spacing(4),
    color: 'rgba(255, 255, 255, 0.9) !important',
  },
}));

function UsersNotifications() {
  //const { t } = useTranslation();
  const classes = useStyles();
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  const {
    state: { notifications, typeFilter, searchValue },
    setState,
  } = useNotificationsState();
  // const [modal, setModal] = useState(false);
  // const [params, setParams] = useState({});
  // const toggleModal = () => {
  //   setModal(!modal);
  // };

  const {
    userState: {
      user: { mmkId, clinicId, lang },
    },
  } = useUserStateDispatch();

  const getData = () => {
    getNotifications({
      setState,
      mmkId,
      clinicId,
      typeFilter,
      searchValue,
    });
  };

  useEffect(() => {
    //console.log('searchValue', searchValue);
    getData();
    getCount(setState, mmkId, clinicId);
  }, [mmkId, clinicId, typeFilter, searchValue, lang]);

  useInterval(
    () => {
      getCount(setState, mmkId, clinicId);
    },
    mmkId != null && clinicId != null && notificationsMenu === null,
    60000,
  );

  const handleOpenMenu = (e) => {
    setNotificationsMenu(e.currentTarget);
    getData();
    if (notifications.unreadCount > 0)
      updateNotifications({
        setState,
        mmkId,
        clinicId,
      });
  };
  //
  return (
    <>
      {/* <Modale
        open={modal}
        toggleModal={toggleModal}
        title={t('MAINMENU.BLANK')}
        mx={0}
      >
        {params.guid != null && params.questId != null && (
          <InitAnketa
            formGuid={params.guid}
            objId={params.questId}
            closeModal={() => {
              setModal(false);
            }}
          />
        )}
      </Modale> */}
      <IconButton
        color="inherit"
        aria-haspopup="true"
        aria-controls="mail-menu"
        onClick={handleOpenMenu}
      >
        <Badge
          badgeContent={
            notifications.unreadCount !== 0
              ? notifications.unreadCount
              : null
          }
          color="warning"
        >
          <NotificationsIcon classes={{ root: classes.headerIcon }} />
        </Badge>
      </IconButton>
      <UsersNotificationMenu
        notificationsMenu={notificationsMenu}
        setNotificationsMenu={setNotificationsMenu}
        getData={getData}
        lang={lang}
      />
    </>
  );
}
export default UsersNotifications;
