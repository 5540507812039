import React, { useState } from 'react';

import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ModaleSign from './ModaleSign';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1.3),
  },
}));
export default function UsersSign() {
  const classes = useStyles();
  const [modalSign, setModalSign] = useState(false);
  const { t } = useTranslation();
  const toggleModalSign = () => {
    setModalSign(!modalSign);
  };
  return (
    <>
      <Button
        aria-haspopup="true"
        color="inherit"
        aria-controls="profile-menu"
        endIcon={<LoginOutlinedIcon />}
        className={classes.button}
        size="large"
        onClick={toggleModalSign}
      >
        <Typography>{t('SIGN.IN_BUTTON')}</Typography>
      </Button>
      <ModaleSign
        modalSign={modalSign}
        toggleModalSign={toggleModalSign}
      />
    </>
  );
}
