import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogTitle from "@mui/material/DialogTitle";
import { Button, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import classNames from 'classnames';
import { getVersion } from '../../actions/user';
import useInterval from '../../hooks/useInterval';
import config from '../../config';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  headerButton: {
    padding: theme.spacing(2.5),
  },
  hideButton: {
    display: 'none',
  },

  headerIcon: {
    fontSize: 28,
    color: '#51D713 !important',
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function UsersNewRealise() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showButton, setShowButton] = useState(false);

  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState({});

  useEffect(() => {
    if (config.nodeEnv !== 'development') {
      getVersion(setVersion, null);
    }
  }, []);

  useEffect(() => {
    if (version?.old != null && version?.old !== version?.new) {
      setShowButton(true);
      console.log(
        '============= New Version is avalible =============',
      );
      console.log(
        '==========================\nversion: ',
        version.version,
        '\ndate version: ',
        dayjs(version.date).format('DD.MM.YYYY HH:mm'),
        '\n========================== ',
      );
    }
  }, [version?.new]);

  useInterval(
    () => {
      /** RELOAD site if old version */
      getVersion(setVersion, version?.new);
    },
    config.nodeEnv !== 'development',
    60000,
  );
  const handleTuggleDialod = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseOk = () => {
    setOpen(false);
    window.location.reload(true);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Alert severity="success">
            <Typography variant="body2">
              {t('HEADER.ALERT_SITE_WILL_REALOAD')}
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t('COMPONENT.BUT_CLOSE')}
          </Button>
          <Button onClick={handleCloseOk} color="secondary">
            {t('COMPONENT.BUT_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        aria-controls="mail-menu"
        onClick={handleTuggleDialod}
        className={classNames(
          classes.headerButton,
          !showButton ? classes.hideButton : null,
        )}
      >
        <NewReleasesIcon classes={{ root: classes.headerIcon }} />
      </IconButton>
    </>
  );
}
export default UsersNewRealise;
