import React, { useMemo } from 'react';
import { TextField as Input } from '@mui/material';
import { useTranslation } from 'react-i18next';
//import { formatIncompletePhone } from '../../helpers/utils';
import { isPetKey } from '../../helpers';
import { formatPhoneString } from '../../helpers/utils';

function FIOandPhoneForm({
  values,
  errors,
  handleChange,
  setValues,
  setErrors,
  validate,
  appInfo,
  handlePhoneChange,
}) {
  const { t } = useTranslation();
  const forPet = appInfo?.uiType === 'petClinic';
  const phoneNumber = values.phone
    ? formatPhoneString(values.phone, appInfo.countryCode)
    : '';
  return useMemo(
    () => (
      <>
        <Input
          name="lastName"
          variant="outlined"
          value={values?.lastName || ''}
          onChange={handleChange}
          margin="normal"
          label={t(isPetKey('COMPONENT.FORM_LAST_NAME', forPet))}
          type="text"
          fullWidth
          required
          inputProps={{ maxLength: 50 }}
          error={errors?.lastName != null}
          helperText={errors?.lastName != null && errors?.lastName}
        />
        <Input
          name="firstName"
          variant="outlined"
          value={values?.firstName || ''}
          onChange={handleChange}
          margin="normal"
          label={t(isPetKey('COMPONENT.FORM_NAME', forPet))}
          type="text"
          fullWidth
          required
          inputProps={{ maxLength: 50 }}
          error={errors?.firstName != null}
          helperText={errors?.firstName != null && errors?.firstName}
        />
        {!forPet && (
          <Input
            name="middleName"
            variant="outlined"
            value={values?.middleName || ''}
            onChange={handleChange}
            margin="normal"
            label={t('COMPONENT.FORM_MIDLE_NAME')}
            type="text"
            fullWidth
            inputProps={{ maxLength: 50 }}
            error={errors?.middleName != null}
            helperText={
              errors?.middleName != null && errors?.middleName
            }
          />
        )}
        <Input
          name="phone"
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneChange}
          margin="normal"
          label={t('COMPONENT.FORM_PHONE')}
          type="text"
          fullWidth
          required
          error={errors?.phone != null}
          helperText={errors?.phone != null && errors?.phone}
        />
      </>
    ),

    [values, errors],
  );
}

export default FIOandPhoneForm;
