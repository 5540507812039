import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  createUser,
  singUpConfirmationCode,
} from '../../actions/user';

import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from './validationSignUp';
import validateConfirmSignUp from './validationConfirmSignUp';

import { dateToHL7 } from '../../helpers/dateFormat';
import SignUpForm from './SignUpForm';
import { useUserStateDispatch } from '../../context/UserContext';
import ShowResponse from '../FormComponents/ShowResponse';
import SignFormConfirm from './SignFormConfirm';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignUp({ valsTab, setValsTab, hasButtonGoSignIn = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    userState: {
      user: { lang },
      appInfo,
      serverResponse,
    },
    userDispatch,
  } = useUserStateDispatch();

  const signUp = () =>
    createUser(
      userDispatch,
      {
        ...values,
        birthDate: values.birthDate
          ? dateToHL7(values.birthDate.toDate())
          : null,
      },

      appInfo.countryCode,
    );
  useEffect(() => {
    return () => {
      userDispatch({
        type: 'LOADED',
      });
    };
  }, []);

  const confirmSignUp = () => {
    singUpConfirmationCode(
      userDispatch,
      values,
      lang,
      navigate,
      appInfo.isAnonymousChildrenEnabled,
    );
  };

  const isConfirmForm =
    serverResponse &&
    (serverResponse?.action === 'CONFIRM_CODE_SENT' ||
      serverResponse?.action === 'WRONG_CONFIRMATION_CODE');

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
    handleDateChange,
  } = useForm(
    isConfirmForm ? confirmSignUp : signUp,
    isConfirmForm ? validateConfirmSignUp : validateSignUp,
    appInfo,
  );

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const setActiveTabId = (activeTabId) => {
    userDispatch({
      type: 'LOADED',
    });
    setValsTab({
      ...valsTab,
      activeTabId,
    });
  };

  return (
    <>
      <Typography variant="h3" mt={3} mb={4}>
        {t('SIGN.UP_BUTTON')}
      </Typography>
      <ShowResponse
        serverResponse={serverResponse}
        okKey="SIGN.UP_OK"
      />
      {isConfirmForm ? (
        <SignFormConfirm
          values={values}
          handleChange={handleChange}
          errors={errors}
          handleSubmit={handleSubmit}
          submitAgain={signUp}
          lifeTime={serverResponse?.lifeTime}
          takeStepBack={() => {
            setActiveTabId(1);
          }}
        />
      ) : (
        <>
          <SignUpForm
            values={values}
            handleChange={handleChange}
            setValues={setValues}
            errors={errors}
            handleSubmit={handleSubmit}
            handleDateChange={handleDateChange}
            handleChangeGender={handleChangeGender}
            handlePhoneChange={handlePhoneChange}
          />
          {hasButtonGoSignIn && (
            <Button
              color="primary"
              variant="text"
              onClick={() => setActiveTabId(0)}
              style={{ width: '100%', marginTop: 24 }}
            >
              {t('COMPONENT.BACK_ONE_STEP')}
            </Button>
          )}
        </>
      )}
    </>
  );
}

export default SignUp;
