import i18next from 'i18next';

export default function validate(values) {
  let errors = {};

  if (!values?.medDepId) {
    errors.medDepId = i18next.t('SIGN.UP_EMPTY_MEDDEP');
  }

  return errors;
}
