import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserStateDispatch } from '../../context/UserContext';
import { useVisitsState } from '../../context/VisitsContext';
import { getVisitsDoc } from '../../actions/visits';

import Typography from '@mui/material/Typography';
import VisitsItem from './VisitsItem';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import Loading from '../../components/Loading';
import VisitChat from '../../components/Chat';
import Modale from '../../components/Modals/Modale';
import { Alert } from '@mui/material';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function Visits({ limit, futureVisits }) {
  const { t } = useTranslation();

  const {
    state: { visits, visit },
    setState,
  } = useVisitsState();

  const {
    userState: {
      user: { mmkId, clinicId, lang },
    },
  } = useUserStateDispatch();
  const { specificRecordId, paramClinicId } = useParams();
  const [modalChatVisit, setModalChatVisit] = useState(false);
  const toggleModalChatVisit = (visit) => {
    if (!isEmpty(visit) && !modalChatVisit) {
      setState({
        type: 'SET_VISIT',
        payload: visit,
      });
      setModalChatVisit(true);
    }
    if (modalChatVisit) {
      setModalChatVisit(false);
      setState({
        type: 'RESET_VISIT',
      });
    }
  };

  const refreshVisits = () => {
    setState({
      type: 'RESET_VISITS',
    });
    getVisitsDoc({
      setState,
      startIndex: 0,
      count: limit != null ? limit : visits.count,
      futureVisits,
      includeCancelled: !futureVisits,
      clinicId: paramClinicId != null ? paramClinicId : clinicId,
    });
  };
  useEffect(() => {
    refreshVisits();
    return () => {
      setState({
        type: 'RESET_VISITS',
      }); // This worked for me
    };
  }, [
    futureVisits,
    mmkId,
    clinicId,
    specificRecordId,
    paramClinicId,
  ]);

  const items = useMemo(
    () =>
      visits.data.map((visit) => {
        // const chat = chatRooms.find(
        //   (item) => item?.planningId === visit?.planningId,
        // );

        return (
          <VisitsItem
            visit={visit}
            mmkId={mmkId}
            key={visit.id}
            showClinic={limit == null}
            futureVisits={futureVisits}
            toggleModalChatVisit={toggleModalChatVisit}
          />
        );
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visits],
  );

  return (
    <>
      {!isEmpty(visit) && visit?.chatInfo != null && (
        <Modale
          open={modalChatVisit}
          toggleModal={toggleModalChatVisit}
          title={`${t('EVENTS_VISITS.TITLE_ONLINE_CONF')} `}
          mx={0}
          maxWidth="lg"
          height={700}
          backdrop={true}
          isScrollHide={true}
        >
          {visit?.chatInfo?.patientUserId != null ? (
            <VisitChat
              lang={lang}
              activeGroupId={visit?.chatInfo?.patientUserId}
              inModale={true}
            />
          ) : (
            <Alert severity="error">
              <Typography variant="body2">
                visit?.chatInfo?.patientUserId - undefined
              </Typography>
            </Alert>
          )}
        </Modale>
      )}

      {visits.isLoaded && visits.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {visits.responseError}
          </Typography>
        </Alert>
      ) : isEmpty(items) && visits.isLoaded ? (
        <Typography variant="body2" component="p">
          {t('EMPTY_DATA.NO_EVENTS')}
        </Typography>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={() =>
            visits.startIndex > 0 &&
            getVisitsDoc({
              setState,
              startIndex: visits.startIndex,
              count: visits.count,
              futureVisits: futureVisits,
              includeCancelled: !futureVisits,
              clinicId,
            })
          }
          hasMore={visits.hasMore}
          loader={<Loading key={0} />}
        >
          {items}
        </InfiniteScroll>
      )}
    </>
  );
}
