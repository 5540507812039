import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import classnames from 'classnames';

// styles
const useStyles = makeStyles((theme) => ({
  dotBase: {
    position: 'absolute',
    width: 16,
    height: 16,
    right: 15,
    top: 140,

    background: theme.palette.success.main,
    border: '1.5px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '50%',
  },
  dotMedium: {
    width: 20,
    height: 20,
  },
  dotLarge: {
    width: 24,
    height: 24,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function Dot({ size = 'small', color, style }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotMedium]: size === 'medium',
        [classes.dotBase]: size === 'small',
        [classes.dotLarge]: size === 'large',
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
        ...style,
      }}
    />
  );
}
