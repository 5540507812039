import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid2';
import Directions from './Directions';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
import { VisitProvider } from '../../context/VisitContext';

const useStyles = makeStyles((theme) => ({
  filters: {
    // marginTop: `-27px`,
    marginBottom: theme.spacing(1),
    //alignItems: "center"
  },
  backButton: {
    textTransform: 'none',
    fontSize: 22,
  },
}));

export default function DirectionsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { specificRecordId } = useParams();

  return (
    <VisitProvider>
      <Directions />
      <Grid
        container
        spacing={3}
        className={classes.filters}
        justifyContent="center"
        alignItems="center"
      >
        <Grid>
          {specificRecordId != null && (
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/app/directions"
            >
              {t('INVOICES.FILTER_ALL')}
            </Button>
          )}
        </Grid>
      </Grid>
    </VisitProvider>
  );
}
