import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesEn from './en.json';
import resourcesFr from './fr.json';
import resourcesRu from './ru.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getLang } from '../actions/user';

const lang = getLang();

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ru: { translations: resourcesRu },
      en: { translations: resourcesEn },
      fr: { translations: resourcesFr },
    },
    lng: lang,
    load: 'languageOnly',
    fallbackLng: lang,
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    //    keySeparator: false, // we use content as keys

    // interpolation: {
    //   escapeValue: false, // not needed for react!!
    //   formatSeparator: lang === "ru" ? " " : ",",
    // },

    lowerCaseLng: true,
  });

export default i18n;
