import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
    backgroundColor: '#F7F7F7',
  },

  marginBottom: {
    marginBottom: 24,
  },
  marginTop: {
    marginTop: 24,
  },

  conteiner: {
    flexGrow: 1,
    position: 'absolute',
    //width: `calc(100vw - 240px)`,
    //minHeight: "calc(100vh - 200px)", //show scroll
    width: '100%',
    paddingLeft: 18,
    paddingRight: 18,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(18),
      padding: 0,
    },
  },
  content: {
    height: '100%',
    position: 'relative',
    top: theme.spacing(10),
    width: '100%',
  },
  footer: {
    flexGrow: 1,
    position: 'relative',
    top: theme.spacing(15),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    marginRight: theme.spacing(2),
  },
  appLink: {
    paddingRight: theme.spacing(2),
  },
}));
