import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid2';

import { PrescribedDrugsProvider } from '../../context/PrescribedDrugsContext';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import PrescribedDrugs from './PrescribedDrugs';
import PrescribedDrugsFilter from './PrescribedDrugsFilter';

export default function PrescribedDrugsPage() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const HeaderH1 = () => (
    <Typography variant="h1" style={{ marginBottom: 32 }}>
      {t(`MAINMENU.PRESCRIBED_DRUGS`)}
    </Typography>
  );

  return (
    <PrescribedDrugsProvider>
      {!isMobile ? (
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid size={{ xs: 12, sm: 3 }}>
            <PrescribedDrugsFilter />
          </Grid>

          <Grid size={{ xs: 12, sm: 9 }}>
            <HeaderH1 />
            <PrescribedDrugs />
          </Grid>
        </Grid>
      ) : (
        <>
          <HeaderH1 />
          <PrescribedDrugsFilter />
          <PrescribedDrugs />
        </>
      )}
    </PrescribedDrugsProvider>
  );
}
