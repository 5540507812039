import React, { useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteUser, signOut } from '../../actions/user';
// context
import { useUserStateDispatch } from '../../context/UserContext';
//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
// styles
import useStyles from './styles';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function ProfileDelete() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const {
    userState: { isLoaded, serverResponse },
    userDispatch,
  } = useUserStateDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    if (serverResponse?.action === 'ACCOUNT_DELETED')
      signOut(userDispatch, navigate, '/');
  }, [serverResponse?.action]);

  const handleClose = () => {
    setOpen(false);
  };
  const submit = () => {
    deleteUser(userDispatch);
  };
  const handleCloseOk = () => {
    setOpen(false);
    submit();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Alert severity="warning">
            <Typography variant="body2">
              {t('PROFILE.ARE_U_DELETE_ACCOUNT')}
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('COMPONENT.BUT_CLOSE')}
          </Button>
          <Button onClick={handleCloseOk} color="warning">
            {t('COMPONENT.BUT_CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>

      {!isLoaded ? (
        <CircularProgress size={26} className={classes.loginLoader} />
      ) : (
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="warning"
          size="small"
          style={{ marginTop: 24 }}
        >
          <Box mr={1} display={'flex'}>
            <DeleteIcon />
          </Box>

          {t('PROFILE.BUTTON_DELETE')}
        </Button>
      )}
    </>
  );
}

export default ProfileDelete;
