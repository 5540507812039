import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import { esiaAuthCode, loginUserData } from '../../actions/user';
import { useLanguageValue } from '../../context/LanguageContext';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import ShowResponse from '../../components/FormComponents/ShowResponse';

const Esia = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const error_description = searchParams.get('error_description');

  const {
    languageState: { language },
  } = useLanguageValue();
  const navigate = useNavigate();

  const {
    userDispatch,
    userState: { appInfo, serverResponse, isLoaded, isAuthenticated },
  } = useUserStateDispatch();

  useEffect(() => {
    if (code && appInfo?.isAnonymousChildrenEnabled != null) {
      esiaAuthCode({
        dispatch: userDispatch,
        code,
        lang: language,
        navigate,
        isAnonymousChildrenEnabled:
          appInfo?.isAnonymousChildrenEnabled,
      });
    }
  }, [code, appInfo?.isAnonymousChildrenEnabled]);

  console.log(
    'serverResponse',
    serverResponse,
    'error_description',
    error_description,
  );

  return (
    <>
      {isLoaded && (
        <Typography variant="h1" sx={{ mb: 4 }}>
          {!isAuthenticated
            ? 'Попытка авторизации через Госуслуги'
            : 'Вы авторизованы через Госуслуги'}
        </Typography>
      )}

      {!isLoaded && <Loading />}

      {isLoaded && !error_description && (
        <Box sx={{ width: '100%', mb: 3 }}>
          <LinearProgress />
        </Box>
      )}
      {(serverResponse || error_description) && (
        <ShowResponse
          serverResponse={serverResponse || error_description}
        />
      )}
    </>
  );
};
export default Esia;
