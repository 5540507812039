import i18next from 'i18next';
import { isValidEmail, isValidPhone } from '../../helpers/validators';

export function validateBoth(values, appInfo) {
  let errors = {};
  if (
    values.login != null &&
    !isValidEmail(values.login) &&
    !isValidPhone(values.login, appInfo.countryCode)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL_OR_PHONE');
  }
  return errors;
}

export function validateEmail(values) {
  let errors = {};
  if (values.login != null && !isValidEmail(values.login)) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL_OR_PHONE');
  }
  return errors;
}
export function validatePhone(values, appInfo) {
  let errors = {};
  if (
    values.login != null &&
    !isValidPhone(values.login, appInfo.countryCode)
  ) {
    errors.login = i18next.t('SIGN.UP_NOT_VALID_EMAIL_OR_PHONE');
  }
  return errors;
}
