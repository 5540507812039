import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchForm from '../../components/FormComponents/SearchForm';

import { useTranslation } from 'react-i18next';
import { useVisitState } from '../../context/VisitContext';

import { useUserStateDispatch } from '../../context/UserContext';
import useDoctorsParams from './useDoctorsParams';
import config from '../../config';
import { Box } from '@mui/material';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorsFilterFIO() {
  const { specIdParam, clinicId, isOnlineParam, fioParam, favPram } =
    useDoctorsParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    userState: {
      isAuthenticated,
      user: { lang },
    },
  } = useUserStateDispatch();
  const {
    state: {
      doctors: { isLoaded },
    },
  } = useVisitState();

  const handleChangeFio = (value) => {
    value = value === '' ? null : value;
    navigate(
      `${config.accessLink(
        isAuthenticated,
        'visit',
      )}/${specIdParam}/${clinicId}/${isOnlineParam}/${value}/${favPram}`,
    );
  };

  return useMemo(() => {
    return (
      <Box mb={3} style={{ marginTop: 3 }}>
        <SearchForm
          handleChange={handleChangeFio}
          value={fioParam}
          disabled={!isLoaded}
          margin="none"
          label={t('COMPONENT.SEARCH_FIO')}
        />
      </Box>
    );
  }, [
    isOnlineParam,
    clinicId,
    fioParam,
    favPram,
    specIdParam,
    isLoaded,
    lang,
  ]);
}
