import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Input from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import Loading from '../Loading';
import { hhMmSs } from '../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  creatingButtonContainer: {
    marginBottom: theme.spacing(3),

    height: 46,
    display: 'flex',
  },
  submitButton: {
    height: 56,
    textTransform: 'none',

    width: '54%',
    minWidth: 240,
    marginTop: theme.spacing(1),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignFormConfirm({
  values,
  handleChange,
  errors,
  handleSubmit,
  submitAgain,
  lifeTime,
  isLoaded = null,
  name = 'confirmationCode',
  takeStepBack = null,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [counter, setCounter] = useState(lifeTime ? lifeTime : 900);

  // Counter
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return isLoaded != null && !isLoaded ? (
    <Loading />
  ) : (
    <Grid
      container
      mt={0.5}
      mb={3}
      alignItems="center"
      spacing={3}
      width={'100%'}
    >
      {counter > 0 ? (
        <Grid size={{ xs: 12, sm: 6 }}>
          <Input
            name={name}
            variant="outlined"
            value={values[name]}
            onChange={handleChange}
            margin="normal"
            label={t('COMPONENT.CONFIRM_CODE')}
            type="tel"
            fullWidth
            required
            error={errors[name] != null}
            helperText={errors[name] != null && errors[name]}
          />
        </Grid>
      ) : (
        <Grid size={{ sm: 12, md: 6 }}>
          <Button
            color="primary"
            onClick={() => {
              submitAgain();
            }}
          >
            {t('COMPONENT.BUTTON_GET_CONFIRM_CODE')}
          </Button>
        </Grid>
      )}

      {counter > 0 && (
        <>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Button
              disabled={isEmpty(values[name]) || errors[name] != null}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submitButton}
            >
              {t('SIGN.UP_SUBMIT_CONFIRM')}
            </Button>
          </Grid>
          <Grid size={{ xs: 12 }} textAlign="center">
            <Typography variant="body1" mt={1.5}>
              {t('SIGN.IN_BY_QR_LIFETIME', { sec: hhMmSs(counter) })}
            </Typography>
          </Grid>
        </>
      )}
      {takeStepBack != null && (
        <Grid
          size={{ xs: 12, md: counter > 0 ? 12 : 6 }}
          textAlign="center"
        >
          <Button
            onClick={takeStepBack}
            color="primary"
            variant="text"
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default SignFormConfirm;
