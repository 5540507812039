import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  buttonPlace: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(2)}`,
    },
  },
  saveButton: {
    height: 54,
    textTransform: 'none',
  },
  errorMessage: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    justifyContent: 'center',
  },
}));
