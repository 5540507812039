import React from 'react';
import { makeStyles } from '@mui/styles';
import { useUserStateDispatch } from '../../context/UserContext';
import Rgm from '../../components/Rgm';
import YandexMap from '../../components/YandexMap';

const useStyles = makeStyles((theme) => {
  return {
    mapConteiner: {
      position: 'absolute',
      top: 262,
      bottom: 138,
      right: 0,
      left: 2,

      [theme.breakpoints.down('sm')]: {
        top: 200,
      },
    },
    heightSpace: {
      minHeight: 'calc(100vh - 440px)', // height
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 400px)',
      },
    },
  };
});

const Map = () => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  const classes = useStyles();

  return (
    <>
      <div className={classes.heightSpace}></div>
      <div className={classes.mapConteiner}>
        {appInfo.mapsType === 'google' ? <Rgm /> : <YandexMap />}
      </div>
    </>
  );
};

export default Map;
