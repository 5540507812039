import React from 'react';
import { AppBar, Divider, Toolbar } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

// components
import UsersLanguageMenu from './UsersLanguageMenu';
import { useUserStateDispatch } from '../../context/UserContext';
import custom from '../../custom';
const styles = (theme) => ({
  sideBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appContainer: {
    maxWidth: 1280,
    padding: 0,
    margin: '0 auto',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  logo: {
    // width: 148,
    // height: 54,
    marginLeft: 15,
    marginTop: 2,
    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },

  toolbar: {
    padding: 0,
    minHeight: 86,
    [theme.breakpoints.down('sm')]: {
      minHeight: 71,
    },
  },
  divider: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),

    backgroundColor: theme.palette.grey[400],
  },

  grow: {
    flexGrow: 1,
  },
});

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Header = ({ classes }) => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  const LogoHeader = custom.LogoHeader;

  return (
    <AppBar className={classes.sideBar}>
      <div className={classes.appContainer}>
        <Toolbar className={classes.toolbar}>
          <LogoHeader className={classes.logo} />

          {appInfo.showLanguageSwitch && (
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
          )}
          {appInfo.showLanguageSwitch && <UsersLanguageMenu />}
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
