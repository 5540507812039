import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  openOperChat: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme.spacing(3),
    zIndex: 1300,
    borderRadius: 56,
    boxShadow: '0px 4px 17px 8px rgba(18, 25, 29, 0.14)',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },

    '&:hover': {
      cursor: 'pointer',
    },
    '& .MuiTypography-root': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));
