import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMmkState } from '../../context/MmkContext';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import isEmpty from '../../helpers';
import SpecOrDocName from '../../components/FormComponents/AutocompleteSpecOrDocName';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTabs-flexContainerVertical > .MuiTab-root': {
      textTransform: 'none',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    '& .MuiTabs-flexContainerVertical > .MuiTab-root > .MuiTab-wrapper':
      {
        alignItems: 'flex-start',
      },
    '& .MuiTab-textColorPrimary': {
      color: theme.palette.hero,
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: theme.palette.text.primary,
    },
  },
}));

export default function MmkFilter() {
  const {
    state: {
      recordTypeIdFilter,
      mmk: { isLoaded },
      specOrName,
    },
    setState,
  } = useMmkState();
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: { mmkRecordTypes },
  } = useUserStateDispatch();
  const getPrarams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (getPrarams?.specificRecordId != null) {
      setState({
        type: 'SET_FILTER',
        payload: {
          recordTypeIdFilter: 0,
          specOrName: null,
        },
      });
    }
  }, [getPrarams?.specificRecordId]);

  const handleRecordType = (event, recordTypeIdFilter) => {
    setState({
      type: 'SET_FILTER',
      payload: {
        recordTypeIdFilter,
      },
    });
    if (getPrarams?.specificRecordId != null) navigate('/app/mmk');
  };

  const handleSpecOrNameChange = (value) => {
    setState({
      type: 'SET_FILTER',
      payload: {
        specOrName: value,
      },
    });
    if (getPrarams?.specificRecordId != null) navigate('/app/mmk');
  };

  return (
    !isEmpty(mmkRecordTypes) && (
      <Paper className={classes.conteiner}>
        <Tabs
          orientation="vertical"
          value={
            getPrarams?.specificRecordId == null
              ? recordTypeIdFilter
              : false
          }
          onChange={handleRecordType}
          aria-label="filter-tabs"
          className={classes.tabs}
          textColor="primary"
        >
          <Tab
            color="primary"
            label={t('INVOICES.FILTER_ALL')}
            value={0}
          />

          {mmkRecordTypes.map((item) => (
            <Tab
              color="primary"
              label={item.name}
              value={item.id}
              key={item.id}
            />
          ))}
        </Tabs>
        <Box mx={2} pb={2}>
          <SpecOrDocName
            handleSpecOrNameChange={handleSpecOrNameChange}
            specOrName={specOrName}
            disabled={!isLoaded}
          />
        </Box>
      </Paper>
    )
  );
}
