import React from 'react';
import { VisitsProvider } from '../../context/VisitsContext';
import VisitsContainer from './VisitsContainer';
export default function VisitsPage() {
  return (
    <VisitsProvider>
      <VisitsContainer />
    </VisitsProvider>
  );
}
