import i18next from 'i18next';

export function validate(values) {
  let errors = {};

  if (values.fio != null && !values.fio) {
    errors.fio = i18next.t('FEEDBACK.EMPTY_FIO');
  }
  if (values?.email != null && !values.email) {
    errors.email = i18next.t('FEEDBACK.EMPTY_EMAIL');
  } else if (values?.email && !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = i18next.t('SIGN.UP_NOT_VALID_EMAIL');
  }

  if (!values.errorText) {
    errors.errorText = i18next.t('FEEDBACK.EMPTY_TEXTERROR');
  }
  // console.log('values', values);
  // console.log('errors', errors, !values.errorText);
  return errors;
}
