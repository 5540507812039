const codes: { [key: string]: string } = {
  INVALID_SIGN: 'INVALID_SIGN',
  ERR_BAD_REQUEST: '4XX',
  SERVER_ERROR: '5XX',
  SCHEMA_VALIDATION_FAILED: 'SCHEMA_VALIDATION_FAILED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
  OPERATION_NOT_SUPPORTED: 'OPERATION_NOT_SUPPORTED',

  PHOTO_IS_TOO_LARGE: 'PHOTO_IS_TOO_LARGE',
  USER_NOT_FOUND_BY_MMK: 'USER_NOT_FOUND_BY_MMK',
  EMPTY_VALUE: 'EMPTY_VALUE',
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
  AUTHENTICATION_FAILED: 'AUTHENTICATION FAILED',
  INVALID_PHONE_NUMBER_FORMAT: 'INVALID_PHONE_NUMBER_FORMAT',
  EMPTY_PARAM: 'EMPTY_PARAM',
  INCORRECT_PARAM: 'INCORRECT_PARAM',
  EMPTY_SESSION_KEY: 'EMPTY_SESSION_KEY',
  ACCOUNT_IS_NOT_CONFIRMED: 'ACCOUNT_IS_NOT_CONFIRMED',
  EMAIL_CANDIDATE_IS_NOT_CONFIRMED:
    'EMAIL_CANDIDATE_IS_NOT_CONFIRMED',
  PHONE_CANDIDATE_IS_NOT_CONFIRMED:
    'PHONE_CANDIDATE_IS_NOT_CONFIRMED',
  EMPTY_CONFIRMATION_CODE: 'EMPTY_CONFIRMATION_CODE',
  EMPTY_APP_CODE: 'EMPTY_APP_CODE',
  EMPTY_EMAIL: 'EMPTY_EMAIL',
  EMPTY_EMAIL_AND_PHONE_NUMBER: 'EMPTY_EMAIL_AND_PHONE_NUMBER',
  EMPTY_EMAIL_OR_PHONE_NUMBER: 'EMPTY_EMAIL_OR_PHONE_NUMBER',
  EMPTY_USER_ID: 'EMPTY_USER_ID',
  EMAIL_OR_PHONE_ALREADY_REGISTERED:
    'EMAIL_OR_PHONE_ALREADY_REGISTERED',
  EMPTY_PASSWORD: 'EMPTY_PASSWORD',
  EMPTY_SIGN: 'EMPTY_SIGN',
  EMPTY_CLINIC_CODE: 'EMPTY_CLINIC_CODE',
  EMPTY_LAST_NAME: 'EMPTY_LAST_NAME',
  EMPTY_FIRST_NAME: 'EMPTY_FIRST_NAME',
  INVALID_EMAIL_ADDRESS_FORMAT: 'INVALID_EMAIL_ADDRESS_FORMAT',
  OK: 'OK',

  WRONG_CONFIRMATION_CODE: 'WRONG_CONFIRMATION_CODE',
  CONFIRMATION_CODE_EXPIRED: 'CONFIRMATION_CODE_EXPIRED',
  VERIFICATION_CODE_REQUESTED_TOO_EARLY:
    'VERIFICATION_CODE_REQUESTED_TOO_EARLY',
  THE_CONFIRMATION_CODE_WAS_NOT_GENERATED:
    'THE_CONFIRMATION_CODE_WAS_NOT_GENERATED',
  NUMBER_OF_ATTEMPTS_EXCEEDED: 'NUMBER_OF_ATTEMPTS_EXCEEDED',
  CONFIRMATION_CODE_WAS_REQUESTED_WITH_DIFFERENT_PARAMETERS:
    'CONFIRMATION_CODE_WAS_REQUESTED_WITH_DIFFERENT_PARAMETERS',

  WRONG_LOGIN_OR_PASSWORD: 'WRONG_LOGIN_OR_PASSWORD',
  EMPTY_INVOICE_ID: 'EMPTY_INVOICE_ID',
  EMPTY_FILE_ID: 'EMPTY_FILE_ID',
  RECORD_NOT_FOUND: 'RECORD_NOT_FOUND',
  NOT_TRUSTED: 'NOT_TRUSTED',
  MMK_NOT_EXISTS: 'MMK_NOT_EXISTS',
  EMPTY_PASSPORT_DATA: 'EMPTY_PASSPORT_DATA',
  EMPTY_FIO: 'EMPTY_FIO',
  MISSING_DATA: 'MISSING_DATA',
  SESSION_EXPIRED_TOO_LONG: 'SESSION_EXPIRED_TOO_LONG',
  MMK_IDENTIFIER_ALREADY_EXISTS: 'MMK_IDENTIFIER_ALREADY_EXISTS',
  MULTIPART_DATA_PARSING_FAILED: 'MULTIPART_DATA_PARSING_FAILED',
  EMPTY_FILE_NAME: 'EMPTY_FILE_NAME',
  NOT_ALLOWED_MEDDEP_ID: 'NOT_ALLOWED_MEDDEP_ID',
  EMPTY_DOCTOR_AUTHENTICATION_CONFIRMATION_ADDRESS:
    'EMPTY_DOCTOR_AUTHENTICATION_CONFIRMATION_ADDRESS',
  INVALID_DOCTOR_AUTHENTICATION_CONFIRMATION_ADDRESS:
    'INVALID_DOCTOR_AUTHENTICATION_CONFIRMATION_ADDRESS',
  CLINIC_IS_NOT_BOUND_TO_THE_APPLICATION_CODE:
    'CLINIC_IS_NOT_BOUND_TO_THE_APPLICATION_CODE',
  ANONYMOUS_APPOINTMENTS_ARE_PROHIBITED_AT_THE_CLINIC:
    'ANONYMOUS_APPOINTMENTS_ARE_PROHIBITED_AT_THE_CLINIC',
  SPECIALIZATION_NOT_FOUND: 'SPECIALIZATION_NOT_FOUND',
  UNABLE_TO_GET_MMK_OR_WEB_USER: 'UNABLE_TO_GET_MMK_OR_WEB_USER',
  EDITING_NAME_AND_DATE_OF_BIRTH_IS_PROHIBITED:
    'EDITING_NAME_AND_DATE_OF_BIRTH_IS_PROHIBITED',

  IMAGE_NOT_FOUND: 'IMAGE_NOT_FOUND',
  FILE_NOT_FOUND: 'FILE_NOT_FOUND',
  UNSUPPORTED_IMAGE_HEIGHT: 'UNSUPPORTED_IMAGE_HEIGHT',
  UNSUPPORTED_IMAGE_WIDTH: 'UNSUPPORTED_IMAGE_WIDTH',

  DOCTOR_NOT_FOUND_IN_CLINIC: 'DOCTOR_NOT_FOUND_IN_CLINIC',
  METHOD_REQUIRES_DOCTOR_ACCESS: 'METHOD_REQUIRES_DOCTOR_ACCESS',

  DBDATA_TIMEOUT: 'DBDATA_TIMEOUT',
  DBDATA_ERROR: 'DBDATA_ERROR',
  RATE_LIMIT: 'RATE_LIMIT',
};
export default codes;
