import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import isEmpty from '../../helpers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function SelectVisitKinds({
  selectedVisitKindId,
  handleVisitKindChange,
  visitKinds,
  variant,
  margin,
}) {
  const { t } = useTranslation();

  const handleChange = (e) => {
    handleVisitKindChange(e.target.value);
  };
  const value = (visitKinds || []).find(
    (item) => item?.id === selectedVisitKindId,
  )?.id;
  //console.log('selectedVisitKindId', selectedVisitKindId, value);
  return (
    !isEmpty(visitKinds) && (
      <FormControl fullWidth variant={variant} margin={margin}>
        <InputLabel id="demo-simple-select-label">
          {t(`COMPONENT.FORM_VISIT_KIND`)}
        </InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value || null}
          label={t(`COMPONENT.FORM_VISIT_KIND`)}
          onChange={handleChange}
          sx={{ width: 'auto' }}
        >
          {visitKinds.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Typography variant="body" whiteSpace="wrap">
                {item.name}
              </Typography>
              {item.description && (
                <Typography variant="body2" whiteSpace="wrap">
                  {item.description}
                </Typography>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
}
