import React, { useEffect } from 'react';
import { CircularProgress, Collapse as Fade } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
// styles
import useStyles from './styles';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import {
  updateUserInfo,
  changeEmailConfirmationCode,
  changePhoneConfirmationCode,
} from '../../actions/user';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProfileForm from '../../components/Forms/ProfileForm';
import ProfileFormPets from '../../components/Forms/ProfileFormPets';

import ProfileDelete from './ProfileDelete';
//form func
import useForm from '../../hooks/useForm';
import { validate } from './validation';
import validationConfirm from './validationConfirm';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import { dateToHL7 } from '../../helpers/dateFormat';
import { Alert } from '@mui/material';
import {
  cleanPhoneValue,
  formatIncompletePhone,
  formatPhoneString,
} from '../../helpers/utils';
import dayjs from 'dayjs';
import SignFormConfirm from '../../components/Forms/SignFormConfirm';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Profile() {
  const { t } = useTranslation();

  const classes = useStyles();
  const {
    userState: {
      user: {
        id,
        isAnonymous,
        mmkId,
        clinicId,
        email,
        firstName,
        gender,
        phone,
        lastName,
        middleName,
        birthDate,
        dmsName,
        dmsNumber,
        dmsEndDate,
        omsName,
        omsNumber,
      },
      isLoaded,
      serverResponse,
      user,
      alert,
      appInfo,
    },

    userDispatch,
  } = useUserStateDispatch();

  const phoneNumber = phone
    ? formatPhoneString(phone, appInfo.countryCode)
    : '';

  const updateProfile = () => {
    const vals = {
      ...values,
      phone: cleanPhoneValue(values.phone),
      oldPhone: cleanPhoneValue(values.oldPhone),
      birthDate:
        values.birthDate != null
          ? dateToHL7(dayjs(values.birthDate).toDate())
          : null,
      dmsEndDate:
        values.dmsEndDate != null
          ? dateToHL7(dayjs(values.dmsEndDate).toDate())
          : null,
    };

    updateUserInfo(userDispatch, mmkId, clinicId, vals, user);
  };

  const submit = () => {
    if (
      values.confirmationCode !== '' &&
      serverResponse?.requiresEmailConfirmation &&
      values.email !== ''
    ) {
      changeEmailConfirmationCode(
        userDispatch,
        values.confirmationCode,
        user,
      );
      return;
    }
    if (
      values.confirmationCode !== '' &&
      serverResponse?.requiresPhoneConfirmation &&
      cleanPhoneValue(values.phone) !== ''
    ) {
      changePhoneConfirmationCode(
        userDispatch,
        values.confirmationCode,
        user,
      );
      return;
    }
    if (!values.confirmationCode) {
      updateProfile();
    }
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
    handleDateChange,
  } = useForm(
    submit,
    serverResponse?.requiresPhoneConfirmation ||
      serverResponse?.requiresEmailConfirmation
      ? validationConfirm
      : validate,
    appInfo,
  );
  // console.log(
  //   'user',
  //   user,
  //   'serverResponse?.action',

  //   serverResponse?.action,
  //   'values',
  //   values,
  // );
  useEffect(() => {
    if (
      serverResponse?.action == null &&
      user.oldEmail &&
      user.oldEmail !== user.email
    ) {
      setValues({
        ...values,
        confirmationCode: '',
        email: user.oldEmail,
      });
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: '',
          error: t('SIGN.CH_EMAIL_NOT_CONFIRM', {
            email: user.email,
          }),
        },
      });
      //updateProfile();
    }
    if (
      serverResponse?.action == null &&
      user.oldPhone &&
      cleanPhoneValue(user.oldPhone) !== cleanPhoneValue(user.phone)
    ) {
      setValues({
        ...values,
        confirmationCode: '',
        phone: formatPhoneString(user.oldPhone, appInfo.countryCode),
      });
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: '',
          error: t('SIGN.CH_PHONE_NOT_CONFIRM', {
            phone: formatPhoneString(user.phone, appInfo.countryCode),
          }),
        },
      });
      //updateProfile();
    }

    if (serverResponse?.action && values.confirmationCode === '')
      setValues({
        ...values,
        confirmationCode: '',
      });

    if (serverResponse?.action === 'CONFIRM_OK')
      setValues({
        ...values,
        email,
        phone: phoneNumber,
        confirmationCode: '',
      });
  }, [serverResponse?.action]);

  useEffect(() => {
    if (user.emailCandidate) {
      console.log('======== ', user.emailCandidate);
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: '',
          error: t('SIGN.CH_EMAIL_NOT_CONFIRM', {
            email: user.emailCandidate,
          }),
        },
      });
    }
    if (user?.phoneCandidate) {
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: '',
          error: t('SIGN.CH_PHONE_NOT_CONFIRM', {
            phone: formatPhoneString(
              user.phoneCandidate,
              appInfo.countryCode,
            ),
          }),
        },
      });
    }
    setValues({
      id,
      email,
      oldEmail: user?.oldEmail ? user.oldEmail : email,
      oldPhone: user?.oldPhone
        ? formatPhoneString(user.oldPhone, appInfo.countryCode)
        : phoneNumber,
      confirmationCode: '',
      firstName,
      lastName,
      middleName,
      gender,
      phone: phoneNumber,
      birthDate: birthDate ? dayjs(birthDate) : null,
      mmkId,
      dmsName,
      dmsNumber,
      dmsEndDate: dmsEndDate ? dayjs(dmsEndDate) : null,
      omsName,
      omsNumber,
    });
    return () => {
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: null,
      });
    };
  }, [mmkId]);

  const forPet = appInfo?.uiType === 'petClinic';

  const isDisable = (name) => {
    if (
      !isAnonymous &&
      [
        'lastName',
        'firstName',
        'middleName',
        'birthDate',
        'gender',
      ].includes(name)
    )
      return true;
    if (
      !isAnonymous &&
      mmkId !== 'parent' &&
      ['email', 'phone'].includes(name)
    )
      return true;

    if (forPet && ['email', 'phone'].includes(name)) return true;

    return false;
  };
  const isChild = !isAnonymous && mmkId !== 'parent';

  const handleEmailChange = (e) => {
    if (e.persist) e.persist();
    if (
      cleanPhoneValue(values.phone) !==
      cleanPhoneValue(values.oldPhone)
    ) {
      const vals = {
        ...values,
        phone: values.oldPhone,
        email: e.target.value,
      };
      setValues(vals);
      setErrors(validate(vals, appInfo));
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: 'WARNING',
          text: t('SIGN.CH_CAN_CHANGE_PHONE_EMAIL_ONLY'),
        },
      });
      return;
    }
    handleChange(e);
  };

  const handlePhoneOnlyChange = (e) => {
    if (values.email !== values.oldEmail) {
      const vals = {
        ...values,
        email: values.oldEmail,
        phone: formatIncompletePhone(
          e.target.value,
          appInfo.countryCode,
        ),
      };
      setValues(vals);
      setErrors(validate(vals, appInfo));
      userDispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: {
          action: 'WARNING',
          text: t('SIGN.CH_CAN_CHANGE_PHONE_EMAIL_ONLY'),
        },
      });
      return;
    }
    handlePhoneChange(e);
  };

  const lifeTime = () => {
    if (serverResponse?.requiresEmailConfirmation)
      return serverResponse?.emailConfirmationCodeInfo.lifeTime;
    if (serverResponse?.requiresPhoneConfirmation)
      return serverResponse?.phoneConfirmationCodeInfo.lifeTime;
    return 900;
  };

  const infoServerResponse = () => {
    if (serverResponse?.text != null) return serverResponse?.text;
    if (serverResponse?.error != null) return serverResponse?.error;
    if (
      (serverResponse?.action === 'OK' ||
        serverResponse?.action === 'CONFIRM_OK') &&
      !serverResponse?.requiresPhoneConfirmation &&
      !serverResponse?.requiresEmailConfirmation
    )
      return t('COMPONENT.DATA_SAVED');

    if (
      serverResponse?.requiresPhoneConfirmation &&
      serverResponse?.phoneConfirmationCodeInfo?.deliveryMethod ===
        'Email'
    )
      return t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL');

    if (
      serverResponse?.requiresPhoneConfirmation &&
      serverResponse?.phoneConfirmationCodeInfo?.deliveryMethod ===
        'Sms'
    )
      return t('SIGN.CH_CONFIRM_CODE_SENT_SMS');

    if (
      serverResponse?.requiresPhoneConfirmation &&
      serverResponse?.phoneConfirmationCodeInfo?.deliveryMethod ===
        'VoiceCall'
    )
      return t('SIGN.CH_CONFIRM_CODE_SENT_VOICE');

    if (
      serverResponse?.requiresEmailConfirmation &&
      serverResponse?.emailConfirmationCodeInfo?.deliveryMethod ===
        'Email'
    )
      return t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL');

    if (
      serverResponse?.requiresEmailConfirmation &&
      serverResponse?.emailConfirmationCodeInfo?.deliveryMethod ===
        'Sms'
    )
      return t('SIGN.CH_CONFIRM_CODE_SENT_SMS');

    if (
      serverResponse?.requiresEmailConfirmation &&
      serverResponse?.emailConfirmationCodeInfo?.deliveryMethod ===
        'VoiceCall'
    )
      return t('SIGN.CH_CONFIRM_CODE_SENT_VOICE');

    if (serverResponse?.action === 'ACCOUNT_DELETED')
      return t('PROFILE.ACCOUNT_DELETED');

    if (serverResponse?.action === 'PHOTO_UPLOAD_OK')
      return t('PROFILE.PHOTO_UPLOAD_OK');

    if (serverResponse?.action === 'PHOTO_DELETED')
      return t('PROFILE.PHOTO_DELETED');
  };
  return (
    <>
      {alert != null && (
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <Typography variant="body2">
              {t('COMPONENT.NEEDED_PHONE_FOR_APP')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() =>
                userDispatch({
                  type: 'SET_USER',
                  payload: {
                    alert: null,
                  },
                })
              }
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={4}>
        <Grid size={12}>
          <Fade
            in={serverResponse?.action != null}
            timeout={{ enter: 1000, exit: 1500 }}
          >
            <Alert
              severity={
                serverResponse?.action === 'OK' ||
                serverResponse?.action === 'CONFIRM_OK' ||
                serverResponse?.action === 'PHOTO_DELETED' ||
                serverResponse?.action === 'PHOTO_UPLOAD_OK'
                  ? 'success'
                  : 'warning'
              }
            >
              <Typography variant="h6">
                {infoServerResponse()}
              </Typography>

              {!forPet && (
                <Typography variant="body2">
                  {serverResponse?.requiresPhoneConfirmation &&
                    !serverResponse?.error &&
                    t('SIGN.CH_CONFIRM_CODE_SENT_CHANGE_PHONE')}

                  {serverResponse?.requiresEmailConfirmation &&
                    !serverResponse?.error &&
                    t('SIGN.CH_CONFIRM_CODE_SENT_CHANGE_EMAIL')}
                </Typography>
              )}

              {forPet &&
                (serverResponse?.requiresPhoneConfirmation ||
                  serverResponse?.requiresEmailConfirmation) && (
                  <Typography variant="body2">
                    {t('SIGN.UP_NOT_VALID_CONFIRM_CODE')}
                  </Typography>
                )}
            </Alert>
          </Fade>
        </Grid>

        {serverResponse?.requiresPhoneConfirmation ||
        serverResponse?.requiresEmailConfirmation ||
        serverResponse?.action === 'WRONG_CONFIRMATION_CODE' ? (
          <SignFormConfirm
            values={values}
            handleChange={handleChange}
            errors={errors}
            handleSubmit={handleSubmit}
            submitAgain={updateProfile}
            lifeTime={lifeTime()}
            isLoaded={isLoaded}
            takeStepBack={() => {
              userDispatch({
                type: 'SET_SERVER_RESPONSE',
                payload: null,
              });
            }}
          />
        ) : (
          <>
            <Grid size={{ xs: 12, md: 8 }}>
              {isChild && (
                <Alert severity="info" style={{ marginBottom: 24 }}>
                  <Typography variant="body2">
                    {t('PROFILE.ALERT_CHILD_DATA')}
                  </Typography>
                </Alert>
              )}
              {forPet ? (
                <ProfileFormPets
                  isDisable={isDisable}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handlePhoneChange={handlePhoneChange}
                  mmkId={mmkId}
                />
              ) : (
                <ProfileForm
                  isDisable={isDisable}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleDateChange={handleDateChange}
                  handlePhoneChange={handlePhoneOnlyChange}
                  handleEmailChange={handleEmailChange}
                  mmkId={mmkId}
                />
              )}
            </Grid>
            <Grid
              size={{
                xs: 12,
                md:
                  appInfo.showInsuranceDMS || appInfo.showInsuranceOMS
                    ? 4
                    : 8,
              }}
              className={classes.buttonPlace}
            >
              {!isLoaded ? (
                <CircularProgress
                  size={26}
                  className={classes.loginLoader}
                />
              ) : (
                <Button
                  disabled={isChild || !isEmpty(errors)}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.saveButton}
                >
                  {t('COMPONENT.BUTTON_SAVE')}
                </Button>
              )}
            </Grid>
          </>
        )}
      </Grid>
      {serverResponse == null && mmkId === 'parent' && !forPet && (
        <ProfileDelete />
      )}
    </>
  );
}

export default Profile;
