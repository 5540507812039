import { useCallback, useState } from 'react';
import { useVisitState } from '../context/VisitContext';
import { useUserStateDispatch } from '../context/UserContext';
import { dateToHL7 } from '../helpers/dateFormat';
import isEmpty from '../helpers';

export default function useDoctorModale() {
  const [modalCreateVisit, setModalCreateVisit] = useState(false);
  const [modalDoc, setModalDoc] = useState(false);
  const [modalRating, setModalRaring] = useState(false);
  const {
    state: { selectedDate, visitData },
    setState,
  } = useVisitState();
  const {
    userState: { clinics },
  } = useUserStateDispatch();

  const toggleModalCreateVisit = useCallback(
    (payload) => {
      if (!modalCreateVisit) {
        setState({
          type: 'SET_VISIT_DATA',
          payload,
        });
      } else {
        setState({
          type: 'SET_VISIT_DATA',
          payload: {},
        });
      }

      setModalCreateVisit(!modalCreateVisit);
    },

    [modalCreateVisit, visitData],
  );

  const toggleModalDoc = ({
    doctor,
    visitKind,
    visitKinds = null,
    isOnline,
    visitDate = dateToHL7(selectedDate.toDate()),
    patientDirectionId = null,
    titleSpecializationName,
  }) => {
    if (!modalDoc && !isEmpty(doctor)) {
      const clinic = (clinics || []).find(
        (it) => it.id === doctor.clinicId,
      );
      setState({
        type: 'SET_VISIT_DATA',
        payload: {
          doctor,
          visitDate,
          visitKind,
          visitKinds,
          clinic,
          isOnline,
          //review: item.review,
          patientDirectionId,
          titleSpecializationName,
        },
      });
    }
    setModalDoc(!modalDoc);
  };

  const toggleModalRating = ({
    doctor,
    review,
    titleSpecializationName,
  }) => {
    if (!modalRating) {
      setState({
        type: 'SET_RATING_DATA',
        payload: {
          doctor,
          review,
          favOnly: false,
          titleSpecializationName,
        },
      });
    }
    setModalRaring(!modalRating);
  };

  return {
    modalCreateVisit,
    modalDoc,
    modalRating,
    setModalCreateVisit,
    setModalDoc,
    setModalRaring,
    toggleModalCreateVisit,
    toggleModalDoc,
    toggleModalRating,
  };
}
