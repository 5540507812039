import React, { useMemo } from 'react';
import {
  Action,
  ActionType,
  ContextState,
  State,
} from '../types/faq.d';

const FaqContext = React.createContext<ContextState>(
  {} as ContextState,
);

const rootReducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.fetch:
      return {
        ...state,
        faq: {
          isLoaded: true,
          data: action.payload,
          responseError: null,
        },
      };

    case ActionType.reset:
      return {
        faq: {
          isLoaded: false,
          data: [],
          responseError: null,
        },
      };
    case ActionType.error:
      return {
        ...state,
        faq: {
          ...state.faq,
          isLoaded: true,
          responseError: action.payload,
        },
      };
    default:
      return { ...state };
  }
};

function FaqProvider({
  children,
}: {
  children: React.ReactNode;
}): React.JSX.Element {
  const [state, setState] = React.useReducer(rootReducer, {
    faq: {
      isLoaded: false,
      data: [],
      responseError: null,
    },
  });
  const value = useMemo(() => ({ state, setState }), [state]);
  return (
    <FaqContext.Provider value={value}>
      {children}
    </FaqContext.Provider>
  );
}

const useFaqState = () => {
  const context = React.useContext(FaqContext);
  return context;
};

export { FaqProvider, useFaqState };
