import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },

  marginBottom: {
    marginBottom: 24,
  },
  marginTop: {
    marginTop: 24,
  },
  conteiner: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 1280,
    paddingLeft: 18,
    paddingRight: 18,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(18),
    },
  },
  conteinerTopWhithoutSideBar: {
    marginTop: theme.spacing(16),
  },
  conteinerTop: {
    marginTop: theme.spacing(24),
  },
  conteinerTopInfo: {
    marginTop: theme.spacing(32),
  },

  conteinerDoctor: {
    flexGrow: 1,
    //width: `calc(100vw - 240px)`,
    //minHeight: "calc(100vh - 200px)", //show scroll

    marginTop: theme.spacing(14),
    paddingLeft: 18,
    paddingRight: 18,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(18),
    },
  },
  content: {
    minHeight: '62vh',
  },

  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    marginRight: theme.spacing(2),
  },
  appLink: {
    paddingRight: theme.spacing(2),
  },
  marginGoogleButtons: {
    margin: `${theme.spacing(2.5)} ${theme.spacing(2)} 0 0`,
  },
  copyright: {
    color: '#B2B6C2',
    fontSize: 14,
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  openOperChat: {
    position: 'fixed',
    bottom: theme.spacing(24),
    right: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme.spacing(3),
    zIndex: 1300,
    borderRadius: 56,
    boxShadow: '0px 4px 17px 8px rgba(18, 25, 29, 0.14)',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2),
    },

    '&:hover': {
      cursor: 'pointer',
    },
    '& .MuiTypography-root': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));
