// import axios from 'axios';
// import config from '../config';
import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
export const DEFAULT_COUNT = 5;

export async function getChatInfo({
  setState,
  visitId,
  mmkId = 'parent',
  clinicId = 0,
}) {
  const body = { visitId, mmkId, clinicId };

  await httpClient
    .get(api.url('GetChatInfo'), body)
    .then(({ data }) => {
      setState({
        type: 'SET_CHAT_INFO',
        payload: {
          visitId,
          chatInfo: data,
        },
      });
    })
    .catch((err) => {
      console.log('= axios ERROR GetChatInfo =', getError(err));
    });
}
export async function getVisits({
  setState,
  setExistVisits,
  startIndex = 0,
  count = DEFAULT_COUNT,
  futureVisits = true,
  includeCancelled = false,
  mmkId = 'parent',
  clinicId = 0,
  type = 'All',
  filterString = null,
  specificRecordId = null,
}) {
  const body = {
    mmkId,
    clinicId,
    type,
    futureVisits,
    includeCancelled,
    specificRecordId,
    filterString,
    startIndex,
    count,
  };
  try {
    setState({
      type: 'VISITS_LOADING',
    });
    const { data } = await httpClient.get(api.url('GetVisits'), body);
    setState({
      type: specificRecordId != null ? 'FETCH_VISIT' : 'FETCH_VISITS',
      payload: ifEmptyArr(data),
    });

    if (setExistVisits != null) {
      setExistVisits(!isEmpty(data));
    }
  } catch (error) {
    console.log('= axios ERROR GetVisits =', getError(error));
    setState({
      type: 'FETCH_VISITS_ERROR',
      payload: getError(error),
    });
  }
}

export async function cancelVisit({
  setState,
  planningId,
  mmkId,
  clinicId,
  id,
}) {
  setState({
    type: 'CANCEL_VISIT_SUBMITED',
  });

  const body = {
    mmkId,
    clinicId,
    planningId,
  };

  await httpClient
    .post(api.url('CancelVisit'), body)
    .then(async ({ data }) => {
      setState({
        type: 'CANCEL_VISIT',
        payload: {
          isLoaded: true,
          data,
          id,
        },
      });
    })
    .catch(async (serverError) => {
      setState({
        type: 'CANCEL_VISIT_SERVER_ERR',
        payload: {
          isLoaded: true,
          serverError: getError(serverError),
        },
      });
    });
}

export async function sendICameVisit({
  setState,
  clinicId,
  visitId,
  comment = '',
}) {
  setState({
    type: 'ICAME_VISIT_SUBMITED',
  });

  const body = {
    flagValue: true,
    clinicId,
    visitId,
    comment,
  };

  await httpClient
    .post(api.url('SendICameNotification'), body)
    .then(({ data }) => {
      setState({
        type: 'ICAME_VISIT',
        payload: {
          isLoaded: true,
          data,
          id: visitId,
        },
      });
    })
    .catch((serverError) => {
      setState({
        type: 'ICAME_VISIT_SERVER_ERR',
        payload: {
          isLoaded: true,
          serverError: getError(serverError),
        },
      });
    });
}

export async function createIvoiceByVisit({
  setState,
  planningId,
  mmkId,
  clinicId,
  id,
  paymentMode = 'СайтКрКарта',
  futureVisits,
  includeCancelled,
  type,
  filterString,
}) {
  setState({
    type: 'CREATE_INVOICE_SUBMITED',
  });

  const body = {
    planningId,
    mmkId,
    clinicId,
    paymentMode,
  };

  await httpClient
    .post(api.url('CreateInvoiceByVisit'), body)
    .then(({ data }) => {
      setState({
        type: 'CREATE_INVOICE',
        payload: {
          isLoaded: true,
          serverError:
            data?.status !== 3 ? data?.failureReason : null,
          data,
          id,
          planningId,
          mmkId,
          clinicId,
          paymentMode,
          isConfirm: false,
        },
      });
      if (data?.status === 3) {
        getVisits({
          setState,
          startIndex: 0,
          count: 1,
          futureVisits,
          includeCancelled,
          mmkId,
          clinicId,
          type,
          filterString,
          specificRecordId: id,
          typeSate: 'FETCH_VISIT',
        });
      }
    })
    .catch((serverError) => {
      setState({
        type: 'CREATE_INVOICE_SERVER_ERR',
        payload: {
          isLoaded: true,
          serverError: getError(serverError),
        },
      });
    });
}

export async function getVisitsDoc({
  dispatch,
  startIndex = 0,
  count = DEFAULT_COUNT,
  futureVisits = true,
  includeCancelled = false,
  clinicId = 0,
  type = 'All', // Offline, Online, All
  filterString = '', // fio,
}) {
  const body = {
    startIndex,
    count,
    futureVisits,
    includeCancelled,
    clinicId,
    type,
    filterString,
  };
  try {
    dispatch({
      type: 'VISITS_LOADING',
    });
    const { data } = await httpClient.get(
      api.url('GetVisitsDoc'),
      body,
    );

    dispatch({
      type: 'FETCH_VISITS',
      payload: {
        isLoaded: true,
        data: ifEmptyArr(data),
      },
    });
  } catch (err) {
    console.log('= axios ERROR GetVisits =', getError(err));
    dispatch({
      type: 'FETCH_VISITS_ERROR',
      payload: getError(err),
    });
  }
}

export async function getVisitKinds({
  setState,
  id,
  plExamId,
  clinicId = 0,
  page = 0,
}) {
  const body = {
    plExamId,
    clinicId,
    page,
  };
  try {
    const { data } = await httpClient.get(api.url('GetPlExam'), body);
    setState({
      type: 'FETCH_VISIT_KINDS',
      payload: {
        id,
        data: ifEmptyArr(data),
      },
    });
  } catch (err) {
    console.log('= axios ERROR GetPlExam =', getError(err));
    setState({
      type: 'FETCH_VISIT_KINDS_ERROR',
      payload: {
        id,
        responseRecordError: getError(err),
      },
    });
  }
}
