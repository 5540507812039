import React from 'react';
import { useMmkState } from '../../context/MmkContext';

import SortIcon from '@mui/icons-material/Sort';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';

export default function MmkOrder() {
  const { t } = useTranslation();
  const {
    state: { sortOrder, mmk },
    setState,
  } = useMmkState();

  const handleChange = () => {
    setState({
      type: 'SET_ORDER',
    });
  };

  return (
    !isEmpty(mmk.data) && (
      <Button
        color="info"
        variant="outlined"
        onClick={handleChange}
        disabled={!mmk.isLoaded}
        startIcon={
          <SortIcon
            sx={
              sortOrder === 'asc'
                ? {
                    transform: 'rotate(180deg)',
                  }
                : null
            }
          />
        }
      >
        {t('COMPONENT.SORT_DATE')}
      </Button>
    )
  );
}
