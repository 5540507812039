import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import SpecCard from './SpecCard';
import config from '../../../config';
import { useUserStateDispatch } from '../../../context/UserContext';
import { useVisitState } from '../../../context/VisitContext';
import Carousel from 'react-material-ui-carousel';
import { chunksArray } from '../../../helpers';
import { Box, useMediaQuery } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {
    //width: `calc(100% + ${theme.spacing(6)})`,
    //marginLeft: `-${theme.spacing(3)}`,
    width: '100%',
    marginBottom: theme.spacing(8),
    boxSizing: 'border-box',
    boxShadow: theme.palette.shadows.card,
    transition: 'none',
    borderRadius: 8,
  },

  сorousel: {
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main,
      background: theme.palette.bgLight.two,
      boxShadow: theme.palette.shadows.card,
      '&:hover': {
        color: '#fff',
        background: `${theme.palette.primary.dark} !important`,
      },
      '&$selected': {
        color: '#fff',
        background: `${theme.palette.primary.main} !important`,
      },
    },
  },

  containerSpecs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(269px, 1fr))',
    gridGap: theme.spacing(2),
    height: '100%',
    padding: theme.spacing(2),
  },
  selected: {},
  nonUnderline: {
    textDecoration: 'none',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function SpecCards({ strFilter }) {
  const classes = useStyles();
  const isMobileLg = useMediaQuery((theme) =>
    theme.breakpoints.down('lg'),
  );

  const isMobileMd = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const isMobileSm = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );
  const {
    userState: { specs, isAuthenticated, appInfo },
  } = useUserStateDispatch();
  const {
    state: { isOnline },
  } = useVisitState();
  if (specs == null) return null;

  const filteredSpecs = specs
    //.filter((item, inx) => [0, 1, 2, 3].includes(inx)) for dev
    .filter((item) => (isOnline && item.hasOnlineSlots) || !isOnline)
    .filter((c) =>
      c.name.toLowerCase().includes(strFilter.toLowerCase()),
    );

  const Item = ({ item }) => {
    const to = `${config.accessLink(
      appInfo.requireStartPageAuth || isAuthenticated,
      'visit',
    )}/${item.id}${isOnline ? '/0/online' : ''}`;
    return (
      <Link to={to} className={classes.nonUnderline}>
        <SpecCard
          imageUrl={
            item.largeImageUrl
              ? `${config.baseURLApi}${item.largeImageUrl}`
              : null
          }
          specName={item.name}
          specNameDetales={item.description}
          onLine={isOnline}
        />
      </Link>
    );
  };

  const countIn = !isMobileLg
    ? 4
    : isMobileLg && !isMobileMd
    ? 3
    : isMobileMd && !isMobileSm
    ? 2
    : 1;

  const chunks = chunksArray(filteredSpecs, countIn);

  return chunks.length > 1 ? (
    <Carousel
      autoPlay={false}
      indicators={false}
      className={classNames(classes.container, classes.сorousel)}
      navButtonsAlwaysVisible={true}
      animation="slide"
      cycleNavigation
      sx={{ height: 448 }}
    >
      {chunks.map((chunk, inx) => (
        <Box key={inx} className={classes.containerSpecs}>
          {chunk.map((item, index) => (
            <Item item={item} key={index} />
          ))}
        </Box>
      ))}
    </Carousel>
  ) : (
    <Box className={classes.container} textAlign="center">
      {chunks.map((chunk, inx) => (
        <Box key={inx} className={classes.containerSpecs}>
          {chunk.map((item, index) => (
            <Item item={item} key={index} />
          ))}
        </Box>
      ))}
    </Box>
  );
}
