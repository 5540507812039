import React from 'react';

import VisitsContainer from './VisitsContainer';
import { VisitsProvider } from '../../../context/VisitsContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function HeroVisits({ setExistVisits, isExistPromo }) {
  return (
    <VisitsProvider>
      <VisitsContainer
        setExistVisits={setExistVisits}
        isExistPromo={isExistPromo}
      />
    </VisitsProvider>
  );
}
