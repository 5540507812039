import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Dot from './Dot';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    minWidth: 268,
    padding: 0,

    alignItems: 'flex-start',
    height: '100%',
    borderRadius: 8,
    boxShadow: 'none',
    backgroundColor: theme.palette.bgLight.one,

    '& .MuiCardActionArea-root': {
      height: '100%',
      //borderRadius: 8,
    },
    '& .MuiCardActionArea-root:hover': {
      //boxShadow: theme.palette.baseButton.shadow,
      //backgroundColor: '#fff',
    },
    '& .MuiCardActionArea-focusHighlight': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  cardContent: {
    textAlign: 'center',
    height: '100%',
  },
  actionArea: { height: '100%' },
  cover: {
    width: '100%',
    height: 148,
    borderRadius: 8,
  },
  textDetailes: {
    textOverflow: 'ellipsis',
    height: 209,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function SpecCard({
  imageUrl,
  specName,
  specNameDetales,
  onLine,
}) {
  const classes = useStyles();
  return (
    <Card className={classes.paper}>
      <CardActionArea>
        <CardMedia className={classes.cover} image={imageUrl} />
        {onLine && <Dot />}
        <CardContent className={classes.cardContent}>
          <Typography variant="h6">{specName}</Typography>
          <Typography
            variant="body2"
            component="div"
            className={classes.textDetailes}
          >
            {specNameDetales}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
