import React from 'react';
import Modale from '../Modals/Modale';
import Sign from './Sign';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleSign({ modalSign, toggleModalSign }) {
  return (
    <Modale
      open={modalSign}
      toggleModal={toggleModalSign}
      maxWidth="sm"
      height={690}
    >
      <Sign toggleModal={toggleModalSign} />
    </Modale>
  );
}
