import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMmkState } from '../../context/MmkContext';
import { getMmkHeaders } from '../../actions/mmk';
//import { getClinics } from "../../context/VisitContext";

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MmkItem from './MmkItem';
import isEmpty, { isPetKey } from '../../helpers';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { Alert, AlertTitle, Box, Button, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { useUserStateDispatch } from '../../context/UserContext';
import PdfView from '../../components/PdfViewer';
import Modale from '../../components/Modals/Modale';
import config from '../../config';
import Grid from '@mui/material/Grid2';

const useStyles = makeStyles((theme) => ({
  montHeader: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  left: {
    marginLeft: theme.spacing(4),
    '&  ol': {
      counterReset: 'li' /* Initiate a counter */,
      marginLeft: 0 /* Remove the default left margin */,
      paddingLeft: 0 /* Remove the default left padding */,
    },
    '&  ol > li': {
      position: 'relative' /* Create a positioning context */,
      margin: 0 /* Give each list item a left margin to make room for the numbers */,
      padding: `${theme.spacing(0.6)} ${theme.spacing(
        2,
      )}` /* Add some spacing around the content */,
      listStyle: 'none' /* Disable the normal item numbering */,
    },
    '&  ol > li:before': {
      content: 'counter(li)' /* Use the counter as content */,
      counterIncrement: 'li' /* Increment the counter by 1 */,
      /* Position and style the number */
      position: 'absolute',
      top: theme.spacing(1),
      left: '-2em',
      boxSizing: 'border-box',
      width: '2.3em',
      /* Some space between the number and the content in browsers that support
     generated content but not positioning it (Camino 2 is one example) */
      marginRight: theme.spacing(2),
      padding: theme.spacing(0.5),
      border: `${theme.spacing(0.25)} solid ${
        theme.palette.info.main
      }`,
      borderRadius: '50%',
      textAlign: 'center',
    },
  },
  button: {
    height: 46,
    textTransform: 'none',
    padding: `${theme.spacing(0)} ${theme.spacing(4)}`,
  },
}));

export default function Mmk() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { mmk, recordTypeIdFilter, specOrName, sortOrder },
    setState,
  } = useMmkState();

  const getPrarams = useParams();

  const {
    userState: {
      user: { clinicId, lang, isAnonymous },
      isAuthenticatedDoctor,
      isAuthenticated,
      clinics,
      user,
      authToken,
      appInfo,
    },
  } = useUserStateDispatch();

  const mmkId = user?.mmkId;
  const fioPatient = `${user?.lastName} ${user?.firstName} ${
    user?.middleName ?? ''
  }`;
  //console.log('mmkId', mmkId);

  const clinicIdNew =
    getPrarams?.paramClinicId != null
      ? getPrarams?.paramClinicId
      : clinicId;

  const specificRecordId =
    getPrarams?.specificRecordId != null
      ? getPrarams?.specificRecordId
      : 0;

  useEffect(() => {
    setState({
      type: 'RESET_MMK',
    });

    getMmkHeaders({
      setState,
      mmkId,
      clinicId: clinicIdNew,
      recordTypeIdFilter,
      specificRecordId,
      filterString: specOrName,
      sortOrder,
    });
  }, [
    mmkId,
    clinicId,
    specificRecordId || recordTypeIdFilter,
    clinicIdNew,
    specOrName,
    sortOrder,
  ]);

  /* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

  const MonthYear = ({ item, inx }) => {
    if (item?.dateTime == null) return;
    const monthYearCurr = HL7ToDateToSrtDate(
      item.dateTime,
      lang,
      'MMMM YYYY',
      true,
    );
    const monthYearPrev = !isEmpty(mmk.data[inx - 1])
      ? HL7ToDateToSrtDate(
          mmk.data[inx - 1].dateTime,
          lang,
          'MMMM YYYY',
          true,
        )
      : '';
    return (
      monthYearCurr !== monthYearPrev && (
        <Typography variant="h5" className={classes.montHeader}>
          {monthYearCurr}
        </Typography>
      )
    );
  };
  const [modal, setModal] = useState({
    open: false,
    url: null,
    title: null,
    type: null,
    error: null,
  });

  const toggleModal = (params) => {
    const { url, title, type, error } = { ...(params || {}) };
    setModal({
      open: !modal.open,
      url,
      title,
      type: type != null ? type.toLowerCase() : '',
      error,
    });
  };

  useEffect(() => {
    if (mmk.responseFileError != null) {
      setModal({
        open: !modal.open,
        url: '',
        title: mmk.responseFileError.filename,
        type: 'error',
        error: mmk.responseFileError.error,
      });
    }
  }, [mmk.responseFileError]);

  const forPet = appInfo?.uiType === 'petClinic';

  const items = useMemo(
    () =>
      mmk.data.map((item, inx) => {
        return (
          <React.Fragment key={`${item.id}-${item.clinicId}`}>
            <MonthYear item={item} inx={inx} />
            <MmkItem
              item={item}
              clinics={clinics}
              lang={lang}
              authToken={authToken}
              mmkId={mmkId}
              toggleModal={toggleModal}
            />
          </React.Fragment>
        );
      }),

    [mmk, lang],
  );

  return (
    <>
      <Modale
        open={modal.open}
        toggleModal={toggleModal}
        title={modal.title}
        height={720}
      >
        {['.png', '.jpeg', '.jpg', '.gif', '.tif', '.bmp'].includes(
          modal.type != null && modal.type,
        ) && <img src={modal.url} alt={modal.title} loading="lazy" />}
        {modal.type != null && modal.type === '.pdf' && (
          <PdfView url={modal.url} />
        )}
        {modal.type != null && modal.type === 'error' && (
          <Stack sx={{ width: '100%', mt: 6 }} spacing={2}>
            <Alert severity="error">
              <AlertTitle>{t('ERROR_CODES.ERROR')}</AlertTitle>
              {modal.error}
            </Alert>
          </Stack>
        )}
      </Modale>

      {isAuthenticatedDoctor && (
        <Typography variant="h6" style={{ marginBottom: 32 }}>
          {fioPatient}
        </Typography>
      )}
      {!mmk.isLoaded ? (
        <Loading />
      ) : mmk.isLoaded && mmk.responseError != null ? (
        <Alert severity="error">
          <Typography variant="h6" component="p">
            {mmk.responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(mmk.data) && specificRecordId === 0 ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            //console.log(' loadMore loadMore loadMore');
            mmk.startIndex > 0 &&
              getMmkHeaders({
                setState,
                mmkId,
                clinicId: clinicIdNew,
                recordTypeIdFilter,
                filterString: specOrName,
                startIndex: mmk.startIndex,
                sortOrder,
              });
          }}
          hasMore={mmk.hasMore}
          loader={<Loading key={0} />}
        >
          {items}
        </InfiniteScroll>
      ) : !isEmpty(mmk.data) ? (
        <>
          {items}
          <Box mt={3} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/app/mmk"
            >
              {t('INVOICES.FILTER_ALL')}
            </Button>
          </Box>
        </>
      ) : isAnonymous ? (
        <Grid container spacing={3} rowSpacing={5}>
          <Grid size={{ xs: 12, md: 6 }}>
            <div className={classes.left}>
              <ol>
                <li>{t('MMK.NO_MMK_RECORDS_1')}</li>
                <li>{t('MMK.NO_MMK_RECORDS_2')}</li>
                <li>{t('MMK.NO_MMK_RECORDS_3')}</li>
              </ol>
            </div>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <img src={appInfo.mmkNoRecords} />
          </Grid>
          <Grid size={12}>
            <Button
              onClick={() => {
                navigate(config.accessLink(isAuthenticated, 'map'));
              }}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('MMK.CLOSE_CLINIC')}
            </Button>
          </Grid>
        </Grid>
      ) : (recordTypeIdFilter === 0 && specOrName == null) ||
        isEmpty(mmk.data) ? (
        <Grid
          container
          spacing={4}
          direction="column"
          size={{ xs: 12, md: 12 }}
        >
          <Grid align="center" my={3}>
            <img src={appInfo.checkMark} />
          </Grid>
          <Grid>
            <Typography variant="h6" component="p" mb={2}>
              {t(isPetKey('MMK.NO_MMK_RECORDS_COMMENT', forPet))}
            </Typography>
            <Typography variant="body1" component="p">
              {t('MMK.NO_MMK_RECORDS_COMMENT1')}
            </Typography>
          </Grid>
          <Grid>
            <Button
              onClick={() => {
                navigate(config.accessLink(isAuthenticated, 'visit'));
              }}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('COMPONENT.BUT_MAKE_APPONTMENT')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('MMK.NO_MMK_RECORDS')}
          </Typography>
        </Alert>
      )}
    </>
  );
}
