import i18next from 'i18next';
import isEmpty from '../../helpers';

export default function validate(values) {
  let errors = {};

  if (isEmpty(values.confirmationCode)) {
    errors.confirmationCode = i18next.t(
      'SIGN.UP_NOT_VALID_CONFIRM_CODE',
    );
  }

  return errors;
}
