import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  container: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  topbg: {
    width: '100%',
    height: 112,
    background:
      'radial-gradient(50% 50% at 50% 50%, #D0DBED 0%, rgba(208, 219, 237, 0) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  topbghr: {
    width: '100%',
    height: 1,
    background:
      'radial-gradient(50% 50% at 50% 50%, #D0DBED 0%, rgba(208, 219, 237, 0) 100%)',
    position: 'absolute',
    top: 84,
    left: 0,
    zIndex: 99,
  },
  top: {
    width: '100%',
    height: 84,
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    zIndex: 100,
  },
  topContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '5%',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '3%',
    },
    display: 'flex',
    overflow: 'hide',
    alignItems: 'center',
    zIndex: 101,
  },
  divide: {
    width: 2,
    height: 24,
    backgroundColor: '#B2B6C2',
    margin: '0 16px !important',
  },
  leftContainer: {
    width: '55%',
    height: '100%',
    paddingLeft: '5%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      width: '90%',
      overflow: 'visible',
      //textAlign: "center"
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 850,
    },
  },
  bottom: {
    width: '100%',
    backgroundColor: 'white',
  },
  bottomContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '5%',
    paddingBottom: '2%',
    paddingRight: '10%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '3%',
      paddingRight: '2%',
      paddingBottom: '3%',
    },
  },

  formContainer: {
    width: '100%',
    height: '89%',
    display: 'flex',
    flexDirection: 'column',
    //overflow: "auto",
  },

  heroImgContainer: {
    width: '45%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    overflow: 'none',
  },

  logotypeImage: {
    //    width: 154,
  },

  heroImage: {
    width: '84%',
    height: '84%',
    marginTop: '16%',
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: 'white',
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
  form: {
    width: 620,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tab: {
    fontWeight: 500,
    fontSize: 18,
    textTransform: 'none',
  },
  greeting: {
    //fontWeight: 600,
    //textAlign: "center",
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(1),
  },
  greeting1: {
    //fontWeight: 600,
    //textAlign: "center",
    //marginTop: theme.spacing(16),
    marginBottom: theme.spacing(4),
    color: theme.palette.text.hint,
  },

  logoAppStore: {
    marginLeft: theme.spacing(8),
  },
  googlePlay: {
    marginRight: theme.spacing(2),
  },
  toRight: {
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
    },
  },
  signBtn: {
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  creatingButtonContainer: {
    marginBottom: theme.spacing(3),
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    height: 46,
    textTransform: 'none',
  },
  errorMessage: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summitButton: {
    minWidth: 260,
    width: 'auto',
    height: 46,
  },
  agreementButton: {
    textAlign: 'left',
    textTransform: 'none',
    fontWeight: 500,
  },

  forgetButton: {
    textTransform: 'none',
    fontWeight: 500,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    color: '#B2B6C2',
    fontSize: 14,
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
}));
