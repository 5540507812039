/* eslint-disable no-undef */
const AUTH_ROUTE_PREFIX = 'app';
const theme = process.env.REACT_APP_THEME;
//const theme = 'mobimed';
//const theme = 'mediadoc';
//const theme = 'medswiss';
//const theme = 'drAnna';
//const theme = 'medincenter';
//const theme = 'gms';
//const theme = 'pimu';
//const theme = 'ncn';
//const theme = 'minfin';
//const theme = 'sibgmu';
//const theme = 'mositalmed';
//const theme = 'skyfert';
//const theme = 'white_fang';

const defLang = process.env.REACT_APP_DEF_LANG;

const baseUrl = `https://${process.env.REACT_APP_BASEHOST}/${process.env.REACT_APP_PATH_SERVICE}`;
const baseURLApi = `${baseUrl}/`;

const APP_CODE = process.env.REACT_APP_APP_CODE;

const defLoginPassword = {
  login: process.env.REACT_APP_LOGIN,
  password: process.env.REACT_APP_PASS,
  login_doc: process.env.REACT_APP_LOGIN_DOC,
  password_doc: process.env.REACT_APP_PASS_DOC,
};

const nodeEnv = process.env.NODE_ENV;

console.log(
  ' ===== vars ===== \n',
  '\n process.env.NODE_ENV',
  nodeEnv,
  '\n baseUrl',
  baseUrl,
  '\n defLang',
  defLang,
  '\n APP_CODE',
  APP_CODE,
);

const secretKey =
  'tOMcNH4Syn6dxprhk4o6qxJB5hHoKIUdxwEUEfOi3X3mIue1qcFkxowyQa6t1QaGTYMopgzskALEMixLmTE5Ti';

const transLang = (lang) =>
  lang === 'ru'
    ? 'rus'
    : lang === 'fr'
    ? 'fra'
    : lang === 'en'
    ? 'eng'
    : null;

const langCode = (_lang) => {
  if (_lang != null && ['ru', 'en', 'fr'].includes(_lang))
    return transLang(_lang);
  return transLang(defLang);
};

const accessLink = (isPrefix, to) =>
  isPrefix ? `/${AUTH_ROUTE_PREFIX}/${to}` : `/${to}`;

const config = {
  nodeEnv,
  theme,
  defLang,
  baseUrl,
  baseURLApi,

  APP_CODE,
  secretKey,
  langCode,
  defLoginPassword,

  accessLink,
  AUTH_ROUTE_PREFIX,
};
export default config;
