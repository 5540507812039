import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import { ifEmptyArr } from '../helpers';
import axios from 'axios';
import fileDownload from 'js-file-download';

export const DEFAULT_COUNT_SIZE = 12;

export async function getMmkHeaders({
  setState,
  mmkId,
  clinicId,
  recordTypeIdFilter = 0,
  specificRecordId = 0,
  filterString = '',
  sortOrder = 'desc',
  startIndex = 0,
  count = DEFAULT_COUNT_SIZE,
}) {
  const body = {
    mmkId,
    clinicId: specificRecordId ? clinicId : undefined,
    startIndex,
    count,
    recordTypeIdFilter,
    specificRecordId,
    filterString,
    sortOrder,
  };

  try {
    const { data } = await httpClient.get(
      api.url('GetMmkHeaders'),
      body,
    );
    setState({
      type: 'FETCH_MMK',
      payload: {
        data: ifEmptyArr(data),
      },
    });
  } catch (err) {
    setState({
      type: 'FETCH_MMK_ERROR',
      payload: getError(err),
    });
  }
}
export async function downloadFile(setState, url, filename) {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
    })
    .catch((err) => {
      setState({
        type: 'FETCH_MMK_ERROR_FILE',
        payload: { error: getError(err), filename },
      });
    });
}

export async function getMmkRecords({
  setState,
  id,
  clinicId,
  doctorId,
  mmkId,
}) {
  const body = {
    clinicId,
    doctorId,
    id,
    mmkId,
    letterFilter: 1,
  };

  await httpClient
    .get(api.url('GetMmkRecordData'), body)
    .then(({ data }) => {
      setState({
        type: 'FETCH_MMK_RECORDS',
        payload: {
          id,
          data: ifEmptyArr(data),
        },
      });
    })
    .catch((err) => {
      setState({
        type: 'FETCH_MMK_RECORDS_ERROR',
        payload: {
          id,
          responseRecordsError: getError(err),
        },
      });
    });
}
