import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

import useMediaQuery from '@mui/material/useMediaQuery';
import Mmk from './Mmk';
import { MmkProvider } from '../../context/MmkContext';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import MmkFilter from './MmkFilter';
import { makeStyles } from '@mui/styles';
import MmkOrder from './MmkOrder';

const useStyles = makeStyles(() => ({
  filtersFixed: {
    position: 'fixed',
    width: 290,
    zIndex: 100,
  },
}));

export default function MmkFull() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const HeaderH1 = () =>
    !isMobile ? (
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ mb: 4 }}
      >
        <Grid size={{ xs: 12, sm: 10 }}>
          <Typography variant="h1">
            {t('MAINMENU.MED_CARD_H1')}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 2 }} textAlign="right">
          <MmkOrder />
        </Grid>
      </Grid>
    ) : (
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('MAINMENU.MED_CARD_H1')}
      </Typography>
    );

  return (
    <MmkProvider>
      {!isMobile ? (
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid size={{ xs: 12, sm: 12, md: 4, lg: 3 }}>
            <div className={classes.filtersFixed}>
              <MmkFilter />
            </div>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 8, lg: 9 }}>
            <HeaderH1 />

            <Mmk />
          </Grid>
        </Grid>
      ) : (
        <>
          <HeaderH1 />
          <MmkFilter />
          <Box textAlign="right">
            <MmkOrder />
          </Box>
          <Mmk />
        </>
      )}
    </MmkProvider>
  );
}
