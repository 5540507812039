import React from 'react';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import WatchLaterIcon from '@mui/icons-material/WatchLater';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import classnames from 'classnames';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
} from '../../helpers/dateFormat';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { Button, Badge } from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './visitsItemStyle';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function VisitsItem({
  visit,
  showClinic,
  toggleModalChatVisit,
}) {
  const { t } = useTranslation();
  const {
    userState: {
      clinics,
      user: { lang },
    },
  } = useUserStateDispatch();
  const classes = useStyles();

  const isOnLine = visit?.chatInfo != null;

  const fio = `${visit.patient?.lastName} ${
    visit.patient?.firstName
  } ${visit.patient?.middleName ?? ''}`;

  return (
    <Grid container justifyContent="flex-start" alignItems="stretch">
      <Grid xs={12} sm={2}>
        <Grid
          container
          classes={{
            root: classes.borderRadiusLeft,
            container: classnames(
              classes.item,
              classes.itemHeight100,
              isOnLine ? classes.itemConf : classes.itemVisit,
            ),
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid xs>
            <Typography variant="h4" align="center">
              {visit.startDateTime &&
                HL7ToDateToSrtDate(
                  visit.startDateTime,
                  lang,
                  'DD',
                )}{' '}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {visit.startDateTime &&
                HL7ToDateToSrtDate(visit.startDateTime, lang, 'MMM')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.ifMobileThenHide}>
        <Grid
          container
          className={classes.itemHeight100}
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.itemGep}></Grid>
        </Grid>
      </Grid>

      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            isOnLine ? classes.itemConf : classes.itemVisit,
          ),
        }}
        xs={12}
        sm
      >
        {isOnLine ? (
          <Button
            color="secondary"
            style={{ marginLeft: -5 }}
            onClick={() => toggleModalChatVisit(visit)}
          >
            <HeadsetMicIcon className={classes.HeadsetMicIcon} />
            {t('EVENTS_VISITS.TITLE_ONLINE_CONF')}
            <Badge
              badgeContent={visit?.chatInfo?.unreadCount}
              color="warning"
              className={classes.unreadMessagesCount}
            />
          </Button>
        ) : (
          <Typography variant="h6" style={{ marginBottom: 8 }}>
            {visit.isCancelled
              ? t('EVENTS_VISITS.TITLE_VISIT_CANCELED')
              : t('EVENTS_VISITS.TITLE_VISIT')}
          </Typography>
        )}

        <Typography variant="body2">{fio}</Typography>
        <Typography variant="body2" className={classes.wrapIcon}>
          <WatchLaterIcon
            color="primary"
            className={classes.iconWatch}
          />{' '}
          {visit.startDateTime &&
            HL7ToDateToTime(visit.startDateTime)}{' '}
          — {visit.endDateTime && HL7ToDateToTime(visit.endDateTime)}
        </Typography>
      </Grid>
      {showClinic && (
        <Grid
          item
          classes={{
            root: classnames(
              classes.item,
              isOnLine ? classes.itemConf : classes.itemVisit,
            ),
          }}
          xs={12}
          sm
        >
          <Typography variant="h6">
            {
              clinics.find((it) => it.clinicId === visit.clinicId)
                ?.name
            }
          </Typography>
          <Typography variant="subtitle2" component="p">
            <LocationOnIcon className={classes.LocationOnIcon} />
            {clinics.find((it) => it.id === visit.clinicId)?.address}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            isOnLine ? classes.itemConf : classes.itemVisit,
            classes.borderRadiusRight,
          ),
        }}
        xs={12}
        sm={showClinic ? 2 : 'auto'}
      >
        <Grid
          container
          spacing={3}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid className={classes.itemButtons}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
