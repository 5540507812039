import React from 'react';
import { useNotificationsState } from '../../context/NotificationsContext';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import SearchFormAndGroups from '../FormComponents/SearchFormAndGroups';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function UsersNotificationFilter() {
  const {
    setState,
    state: { typeFilter, searchValue },
  } = useNotificationsState();
  const { t } = useTranslation();
  const {
    userState: { notificationsTypes },
  } = useUserStateDispatch();

  const handleChangeMenu = (value) => {
    //console.log('value', value);
    const payload = typeFilter.includes(value)
      ? typeFilter.filter((it) => it !== value)
      : [...typeFilter, value];

    setState({
      type: 'SET_TYPE',
      payload,
    });
  };
  const handleChange = (payload) => {
    setState({
      type: 'SET_SEARCH_VALUE',
      payload,
    });
  };

  return (
    <SearchFormAndGroups
      handleChange={handleChange}
      value={searchValue}
      disabled={false}
      label={t('COMPONENT.SEARCH')}
      options={notificationsTypes}
      handleChangeOptions={handleChangeMenu}
      checkedOptions={typeFilter}
    />
  );
}
