import React from 'react';
import { Navigate } from 'react-router-dom';
import PrivateRoutesPatient from './PrivateRoutesPatinet';
import PrivateRoutesOper from './PrivateRoutesOper';
import PrivateRoutesDoctor from './PrivateRoutesDoctor';
import { accountRole } from '../actions/user';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function PrivateRoutes({
  isAuthenticated,
  isAuthenticatedDoctor,
  role,
  unAuthInitRoute,
}) {
  //console.log('role', role);
  return isAuthenticated && role === accountRole.operator ? (
    <PrivateRoutesOper />
  ) : isAuthenticated ? (
    <PrivateRoutesPatient />
  ) : isAuthenticatedDoctor ? (
    <PrivateRoutesDoctor />
  ) : (
    <Navigate to={unAuthInitRoute} replace />
  );
}
