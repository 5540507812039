import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  iframeContent: {
    width: 620,
    height: '98%',
    minHeight: 600,
    border: 0,
    borderRadius: 4,
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function Paid() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <iframe
      id="iframe_id"
      src={'/payment/redirect.html'}
      className={classes.iframeContent}
      title="Paid"
      height="100%"
      width="100%"
    >
      {t('INVOICES.BILL_PAYMENT')}
    </iframe>
  );
}
