import React, { useEffect, useState } from 'react';
import { useParams, Link as LinkRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Marked from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import { useUserStateDispatch } from '../../context/UserContext';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import config from '../../config';
import { getPromoActions } from '../../actions/user';
import Loading from '../../components/Loading';
import isEmpty from '../../helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1),
    height: '100%',
  },
  marginMid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  marginBottom: { marginBottom: theme.spacing(2) },
  title: { color: '#000', width: '50%' },
  dateRage: {
    marginLeft: 'auto',
    color: '#fff',
    backgroundColor: theme.palette.warning.light,
    padding: `${theme.spacing(0.4)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(3.6),
    fontSize: 14,
  },
  startText: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const PromoItem = ({ item }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();

  const bgImg = `${config.baseURLApi}${item.imageUrl}&appCode=${config.APP_CODE}`;

  return (
    <Card className={classes.paper}>
      <CardMedia
        component="img"
        height="222"
        image={bgImg}
        alt="green iguana"
      />
      <CardContent>
        <>
          {item.text.trim() !== '' && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Typography
                variant="h6"
                component="div"
                className={classes.title}
              >
                {item.description}
              </Typography>
              {(item.startDate != null || item.endDate != null) && (
                <Typography
                  component="div"
                  className={classes.dateRage}
                >
                  {item.startDate != null &&
                    HL7ToDateToSrtDate(item.startDate, lang)}{' '}
                  &mdash;{' '}
                  {item.endDate != null &&
                    HL7ToDateToSrtDate(item.endDate, lang)}
                </Typography>
              )}
              <Typography
                component="div"
                variant="body2"
                className={classes.startText}
              >
                <Marked
                  remarkPlugins={[remarkBreaks]}
                  rehypePlugins={[rehypeRaw]}
                >
                  {item.text}
                </Marked>
              </Typography>
            </Box>
          )}

          {item.url && (
            <Link href={item.url} target={'_blank'}>
              <Button
                size="small"
                color="primary"
                startIcon={<LinkIcon />}
              >
                {t('COMPONENT.LINK_TO_ACTION')}
              </Button>
            </Link>
          )}
        </>
      </CardContent>
    </Card>
  );
};
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function PromoIndex() {
  // console.log("timeSlots", timeSlots);
  const { promoId } = useParams();
  const [promo, setPromo] = useState({ isLoaded: true, date: [] });

  useEffect(() => {
    getPromoActions(setPromo);
  }, []);

  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography variant="h3">
          {t('EVENTS_VISITS.PERSONAL_NOTIFICATIONS')}
        </Typography>
      </Grid>
      {!promo.isLoaded && (
        <Grid size={12} alignContent={'center'}>
          <Loading />
        </Grid>
      )}
      {!isEmpty(promo.data) &&
        promoId &&
        promo.data
          .filter((item) => item.id === parseInt(promoId, 10))
          .map((item) => (
            <>
              <Grid size={12} key={item.id}>
                <PromoItem item={item} />
              </Grid>
              <Grid size={12} key={item.id} textAlign="center">
                <Button
                  variant="contained"
                  color="secondary"
                  component={LinkRouter}
                  to="/app/promo"
                >
                  {t('EVENTS_VISITS.FILTER_ROWS_ALL')}
                </Button>
              </Grid>
            </>
          ))}
      {!isEmpty(promo.data) &&
        promoId == null &&
        promo.data.map((item) => (
          <Grid size={12} key={item.id}>
            <PromoItem item={item} />
          </Grid>
        ))}
    </Grid>
  );
}
