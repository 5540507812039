import React from 'react';
import {
  Receipt as ReceiptIcon,
  AlarmOff as AlarmOffIcon,
  AlarmOn as AlarmOnIcon,
  AccessTimeSharp as AccessTimeSharpIcon,
  NotificationsNone as NotificationsIcon,
  ThumbUp as ThumbUpIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalOffer as TicketIcon,
  BusinessCenter as DeliveredIcon,
  SmsFailed as FeedbackIcon,
  DiscFull as DiscIcon,
  Email as MessageIcon,
  Report as ReportIcon,
  Error as DefenceIcon,
  AccountBox as CustomerIcon,
  Done as ShippedIcon,
  Publish as UploadIcon,
  HeadsetMic as HeadsetMicIcon,
  AssignmentInd,
  AssignmentTurnedIn,
  AssignmentLate,
  InsertComment,
  NewReleases,
  ListAlt,
  Info,
} from '@mui/icons-material';

// new types
// Other - Не относится ни к одной другой категории
// MmkRegistration - Регистрация записи ММК
// NewVisit - Новая запись на прием
// VisitCancelled - Запись на прием отменена
// VisitUpdates - Запись на прием изменена
// NewMmkRecord - Новая запись ММК
// MmkRecordCancelled - Запись ММК отменена
// OnlineConsultationHasBegun - Начало онлайн-консультации
// NewAssignments - Новые назначения
// NewDirections - Новые направления
// NewRecommendations - Новые рекомендации
// InvoicePaid - Счет оплачен
// NewInvoice - Новый счет
// Questionnaire - Предложение заполнить анкету

// ####################################################################
export default function IconByType(type = 'offer') {
  const typesIcons = {
    MmkRegistration: <AssignmentInd />,
    Newsletter: <Info />,
    NewVisit: <AccessTimeSharpIcon />,
    VisitCancelled: <AlarmOffIcon />,
    VisitUpdates: <AlarmOnIcon />,
    NewMmkRecord: <AssignmentTurnedIn />,
    MmkRecordCancelled: <AssignmentLate />,
    OnlineConsultationHasBegun: <HeadsetMicIcon />,
    NewAssignments: <NewReleases />,
    NewRecommendations: <InsertComment />,
    NewDirections: <TicketIcon />,
    InvoicePaid: <ReceiptIcon />,
    NewInvoice: <ReceiptIcon />,
    Questionnaire: <ListAlt />,
    'e-commerce': <ShoppingCartIcon />,
    notification: <NotificationsIcon />,
    offer: <TicketIcon />,
    info: <ThumbUpIcon />,
    message: <MessageIcon />,
    feedback: <FeedbackIcon />,
    customer: <CustomerIcon />,
    shipped: <ShippedIcon />,
    delivered: <DeliveredIcon />,
    defence: <DefenceIcon />,
    report: <ReportIcon />,
    upload: <UploadIcon />,
    disc: <DiscIcon />,
  };

  return typesIcons[type] ?? <NotificationsIcon />;
}
