import { Dispatch } from 'react';

export type Faq = {
  medicalNetFaqId: number;
  questionGroup: string;
  question: string;
  answer: string;
};

export type State = {
  faq: {
    isLoaded: boolean;
    data: Faq[];
    responseError: string | null;
  };
};

export enum ActionType {
  fetch = 'FETCH_FAQ',
  reset = 'REST_FAQ',
  error = 'FETCH_FAQ_ERROR',
}

export type ActionStringPayload = {
  type: ActionType.error;
  payload: string;
};

export type ActionObjectPayload = {
  type: ActionType.fetch | ActionType.reset;
  payload: Faq[];
};

export type Action = ActionStringPayload | ActionObjectPayload;

export type ContextState = {
  state: State;
  setState: Dispatch<Action>;
};
