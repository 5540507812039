import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import VisitKinds from '../../components/FormComponents/SelectVisitKinds';
import isEmpty from '../../helpers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const SelectVisitKind = ({
  itemId,
  visitKind,
  visitKinds,
  handleVisitKindChange,
  lang,
}) => {
  return useMemo(
    () =>
      !isEmpty(visitKind) &&
      visitKinds.length > 1 && (
        <Box mt={1} mb={3} sx={{ width: 'auto' }}>
          <VisitKinds
            selectedVisitKindId={visitKind?.id}
            handleVisitKindChange={handleVisitKindChange}
            visitKinds={visitKinds}
            variant="outlined"
            margin="none"
          />
        </Box>
      ),
    [itemId, visitKind, lang],
  );
};

export default SelectVisitKind;
