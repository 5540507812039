import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function AutocompleteSpec({
  specIdParam,
  isOnline,
  handleSelectedSpecChange,
  disabled = false,
  disableClearable = false,
}) {
  const { t } = useTranslation();

  const {
    userState: { specs },
  } = useUserStateDispatch();
  const handleChange = (e, v) => {
    handleSelectedSpecChange(v?.id);
  };

  const options = (specs || [])
    .filter((item) => (isOnline && item.hasOnlineSlots) || !isOnline)
    .map((it) => it);

  const value = (specs || []).find((item) => item.id === specIdParam);

  //console.log(specIdParam, options, value);
  return (
    !isEmpty(options) &&
    specs.length > 1 && (
      <Autocomplete
        //id="checkboxes-tags-demo"
        disableClearable={disableClearable}
        autoSelect={true}
        disabled={disabled}
        value={value || null}
        options={options}
        onChange={handleChange}
        getOptionLabel={(option) =>
          option?.name != null ? option.name : ''
        }
        isOptionEqualToValue={(option) => option.id === specIdParam}
        renderOption={(props, option) => (
          <Box {...props}>
            <Typography variant="body1">{option.name}</Typography>
          </Box>
        )}
        noOptionsText={t('EMPTY_DATA.NO_OPTIONS')}
        renderInput={(params) => (
          <TextField
            {...params}
            name="filter-specs"
            margin="normal"
            label={t('COMPONENT.SPECS_SEL')}
            fullWidth
            variant="outlined"
          />
        )}
      />
    )
  );
}
